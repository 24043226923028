import AddIcon from "@mui/icons-material/Add";
import { Button, Grid, Pagination, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TagsFilters from "../../components/blog/Tags/TagsFilters";
import TagsTable from "../../components/blog/Tags/TagsTable";
import Header from "../../components/Header";
import { Loading } from "../../components/shared/Loading";
import SelectPerPage from "../../components/shared/SelectPerPage";
import { ROUTES } from "../../constants/routes";
import { PAGINATION_STYLES } from "../../constants/styles";
import { useQueryTags } from "../../hooks/queries/tags/useQueryTags";
import useDebounce from "../../hooks/useDebouncing";
import usePagination from "../../hooks/usePagination";

const Tags = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const breadcrumbs = [{ name: t("TAGS"), link: ROUTES.TAGS }];

  const { page, perPage, changePage, changePerPage } = usePagination();
  const [filters, setFilters] = useState({
    search: "",
    domain: "",
  });

  const { data = { data: [], total: 0 }, isLoading } = useQueryTags(page, perPage, filters);

  const { data: tags = [], total: totalTags = 0 } = data;

  const numberPages = Math.ceil(parseFloat(totalTags) / parseFloat(perPage));

  const updateFilter = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    changePage(null, 1);
  };

  const handleSearchChange = (event) => {
    updateFilter("search", event.target.value);
  };

  const debounceSearch = useDebounce(handleSearchChange, 300);

  const handleCreate = () => {
    navigate(ROUTES.CREATE_TAG);
  };

  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t("TAGS_DESCRIPTION")} />
      <Grid
        item
        xs={11}
        sm={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "initial",
        }}
      >
        <TagsFilters
          onSearchChange={debounceSearch}
          filters={filters}
          updateFilter={updateFilter}
        />
      </Grid>
      <Grid container justifyContent="flex-end" alignItems="center" mb={2} mt={2}>
        <Button
          variant="contained"
          className="oniria-btn"
          startIcon={<AddIcon />}
          onClick={handleCreate}
        >
          {t("CREATE_TAG")}
        </Button>
      </Grid>
      {isLoading ? (
        <Loading />
      ) : totalTags > 0 ? (
        <div style={{ marginBottom: "20px" }}>
          <Grid
            item
            xs={11}
            sm={12}
            container
            justifyContent={{ xs: "center", sm: "space-between" }}
            alignItems={"center"}
            pb={2}
            gap={{ xs: 2, sm: 0 }}
          >
            <Typography>
              {t("TOTAL_TAGS")}: {totalTags}
            </Typography>
            {numberPages >= 1 && (
              <Grid item xs={12} md={8} container justifyContent="flex-end">
                <Grid item>
                  <SelectPerPage text={t("PUBLICATIONS")} change={changePerPage} value={perPage} />
                </Grid>
                <Pagination
                  count={numberPages}
                  mr={3}
                  sx={PAGINATION_STYLES}
                  page={page}
                  onChange={changePage}
                />
              </Grid>
            )}
          </Grid>
          <TagsTable data={tags} />
          {numberPages >= 1 && (
            <Grid container justifyContent="center">
              <Pagination
                count={numberPages}
                mr={3}
                sx={PAGINATION_STYLES}
                page={page}
                onChange={changePage}
              />
            </Grid>
          )}
        </div>
      ) : (
        <div>{t("NO_TAGS_FOUND")}</div>
      )}
    </>
  );
};

export default Tags;
