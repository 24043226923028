import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { getRegions } from "../../../services/utilsServices";

export function useQueryRegions(countryCode) {
  return useQuery([RQ_KEY.REGIONS, countryCode], () => getRegions(countryCode), {
    refetchOnWindowFocus: false,
    enabled: !!countryCode,
  });
}
