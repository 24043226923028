import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getPremiseName } from "../../constants/utils";

const TourLeadersGrid = ({ data, handleEdit, handleSee, handleOpenDelete, premises }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3} mb={2}>
        {data?.map((tourLeader, index) => (
          <Grid item xs={12} sm={6} md={4} key={`${tourLeader.id}-${index}`}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                borderRadius: "20px",
                backgroundColor: tourLeader.is_active === 0 ? "var(--grey-cancelled)" : "white",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "row", flexGrow: 1, padding: 1 }}>
                <Avatar
                  alt={tourLeader.name}
                  src={tourLeader.avatar_url}
                  sx={{
                    width: 90,
                    height: 90,
                  }}
                />
                <CardContent sx={{ flex: 1, paddingLeft: 3 }}>
                  <Typography variant="h6" component="div">
                    {tourLeader.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {getPremiseName(tourLeader.owner_premise_id, premises)}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {t(tourLeader.genre?.toUpperCase())}
                  </Typography>
                </CardContent>
              </Box>
              <Typography variant="body2" color="textSecondary" sx={{ paddingInline: 3 }}>
                {tourLeader.languages?.split(";").join(", ")}
              </Typography>
              <CardActions disableSpacing sx={{ justifyContent: "center", gap: 2 }}>
                {/* <Tooltip title={t("SEE_EVENTS")}>
                  <IconButton onClick={() => handleSee(tourLeader.user_id)}>
                    <RemoveRedEyeOutlined sx={{ color: "var(--oniria-gold)" }} />
                  </IconButton>
                </Tooltip> */}
                <Tooltip title={t("EDIT_TOUR_LEADER")}>
                  <IconButton onClick={() => handleEdit(tourLeader.user_id)}>
                    <EditOutlined sx={{ color: "var(--oniria-gold)" }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("DELETE_TOUR_LEADER")}>
                  <IconButton onClick={() => handleOpenDelete(tourLeader.user_id)}>
                    <DeleteOutline sx={{ color: "var(--oniria-gold)" }} />
                  </IconButton>
                </Tooltip>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TourLeadersGrid;
