import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { getTourPointsList } from "../../../services/tourPoints";

const useQueryTourPointsList = () => {
  return useQuery([RQ_KEY.TOUR_POINTS_LIST], () => getTourPointsList(), {
    refetchOnWindowFocus: false,
  });
};

export default useQueryTourPointsList;
