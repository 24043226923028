import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { getBlogDetail } from "../../../services/blogServices";

export function useQueryBlogDetail(id, onSuccess) {
  return useQuery([RQ_KEY.BLOG_DETAIL, id], () => getBlogDetail(id), {
    refetchOnWindowFocus: false,
    enabled: !!id,
    onSuccess: onSuccess,
  });
}
