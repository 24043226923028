import { Avatar, Box, Card, CardActions, CardContent, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getRoutePointName } from "../../constants/utils";
import HotelActions from "./HotelActions";

const HotelsGrid = ({
  data,
  handleEdit,
  handleSee,
  handleEvent,
  handleOpenDelete,
  routePoints,
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3} mb={2}>
        {data?.map((hotel) => (
          <Grid item xs={12} sm={6} md={4} key={hotel.id}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                borderRadius: "20px",
                backgroundColor: hotel.is_active === 0 ? "var(--grey-cancelled)" : "white",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "row", flexGrow: 1, padding: 1 }}>
                <Avatar
                  alt={hotel.name}
                  src={hotel.img_url}
                  variant="square"
                  sx={{
                    width: 90,
                    height: "auto",
                    borderRadius: "8px",
                  }}
                />
                <CardContent sx={{ flex: 1, paddingLeft: 2 }}>
                  <Typography variant="h6" component="div">
                    {hotel.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" mb={1}>
                    {hotel.sku}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {getRoutePointName(hotel.route_point_id, routePoints)}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {t("ASSIGNED_TRIPS")}: {hotel.itineraries_using_it || 0}
                  </Typography>
                </CardContent>
              </Box>
              <CardActions
                disableSpacing
                sx={{
                  justifyContent: "center",
                  gap: 2,
                  paddingTop: 1,
                }}
              >
                <HotelActions
                  handleEdit={handleEdit}
                  handleSee={handleSee}
                  handleEvent={handleEvent}
                  handleOpenDelete={handleOpenDelete}
                  hotelId={hotel.id}
                />
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default HotelsGrid;
