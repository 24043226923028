import { SERVICE } from "../constants/api";
import http from "../constants/axios";

export const getTourLeaders = async (page, perPage, filters) => {
  try {
    const { search, premiseId, asc, desc, genre } = filters;
    const params = new URLSearchParams();
    if (page) params.append("page", page);
    if (perPage) params.append("perpage", perPage);
    if (search) params.append("search", search);
    if (premiseId && premiseId !== "all") params.append("owner_premise_id", premiseId);
    if (genre && genre !== "all") params.append("genre", genre);

    if (asc.length > 0) {
      params.append("asc", asc.join(","));
    }

    if (desc.length > 0) {
      params.append("desc", desc.join(","));
    }

    const response = await http.get(`${SERVICE.TOUR_LEADERS}?${params.toString()}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching tour leaders:", error);
    throw error;
  }
};

export const getTourLeaderDetails = async (id) => {
  try {
    const response = await http.get(`${SERVICE.TOUR_LEADERS}/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching tour leader details:", error);
    throw error;
  }
};

export const createTourLeader = async (body) => {
  try {
    const response = await http.post(`${SERVICE.TOUR_LEADERS}`, body);
    return response.data;
  } catch (error) {
    console.error("Error creating tour leader:", error);
    throw error;
  }
};

export const editTourLeader = async (id, body) => {
  try {
    const response = await http.put(`${SERVICE.TOUR_LEADERS}/${id}`, body);
    return response.data;
  } catch (error) {
    console.error("Error editing tour leader:", error);
    throw error;
  }
};

export const deleteTourLeader = async (id) => {
  try {
    const response = await http.delete(`${SERVICE.TOUR_LEADERS}/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting tour leader:", error);
    throw error;
  }
};

export const assignDomainsToTourLeader = async (id, domains) => {
  const body = {
    domains_ids: domains,
  };

  try {
    const response = await http.put(`${SERVICE.TOUR_LEADERS}/domains/${id}`, body);
    return response.data;
  } catch (error) {
    console.error("Error assign domains:", error);
    throw error;
  }
};
