import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../../constants/utils";

const EventStatisticsDetail = ({ row }) => {
  const { t } = useTranslation();

  const groupByDomain = (data) => {
    return data.reduce((acc, payment) => {
      if (
        payment.payment_type?.includes("timeout") ||
        payment.payment_type?.includes("failed") ||
        payment.payment_type?.includes("free")
      ) {
        return acc;
      }
      const domain = payment.bought_in_domain || t("UNKNOWN_DOMAIN");
      if (!acc[domain]) {
        acc[domain] = [];
      }
      acc[domain].push(payment);
      return acc;
    }, {});
  };

  const getDomainTotalAmount = (payment) => {
    const {
      first_payment_net_income,
      first_payment_premise_commissions,
      first_payment_total_sales,
      net_income,
      premise_commissions,
      total_sales,
    } = payment;

    const safeValue = (value) => (value == null ? "0" : value);

    const total =
      parseFloat(safeValue(first_payment_net_income)) +
      parseFloat(safeValue(first_payment_premise_commissions)) +
      parseFloat(safeValue(first_payment_total_sales)) +
      parseFloat(safeValue(net_income)) +
      parseFloat(safeValue(premise_commissions)) +
      parseFloat(safeValue(total_sales));

    return total;
  };

  const groupedPayments = groupByDomain(row.event_payment_type_sales);

  const ticketConsumptions =
    row.ticket_consumitions?.filter((ticket) => !!ticket.ticket_name) || [];
  const extrasConsumptions = row.extra_consumitions?.filter((extra) => !!extra.extra_name) || [];

  return (
    <Box
      sx={{
        margin: 2,
        padding: 3,
        border: "1px solid #ddd",
        borderRadius: "8px",
        backgroundColor: "#f9f9f9",
      }}
    >
      <Typography
        variant="h6"
        gutterBottom
        sx={{ color: "#333", fontWeight: "bold", paddingBottom: 1 }}
      >
        {t("DOMAIN_SALES")}:
      </Typography>

      {Object.keys(groupedPayments).length > 0 ? (
        Object.keys(groupedPayments).map((domain, index) => (
          <div key={index}>
            <Typography sx={{ paddingLeft: "16px", paddingBottom: "8px", color: "#333" }}>
              <strong>{domain}:</strong>
            </Typography>
            {groupedPayments[domain].map((payment, idx) => (
              <Typography
                key={idx}
                sx={{ paddingLeft: "32px", paddingBottom: "8px", color: "#555" }}
              >
                <strong style={{ color: "#333" }}>
                  {t((payment?.payment_type || payment.first_payment_type)?.toUpperCase())}{" "}
                  {!!payment.first_payment_type && `(${t("FIRST_PAYMENT")})`}:{" "}
                </strong>
                {formatCurrency(getDomainTotalAmount(payment))}
              </Typography>
            ))}
          </div>
        ))
      ) : (
        <Typography sx={{ paddingLeft: "16px", paddingBottom: "8px", color: "#555" }}>
          {t("NO_SALES")}
        </Typography>
      )}

      <Typography
        variant="h6"
        gutterBottom
        sx={{ color: "#333", fontWeight: "bold", paddingTop: 2, paddingBottom: 1 }}
      >
        {t("TICKETS_CONSUMPTIONS")}:
      </Typography>
      {ticketConsumptions && ticketConsumptions.length > 0 ? (
        ticketConsumptions.map((ticket, index) => (
          <Typography key={index} sx={{ paddingLeft: "16px", paddingBottom: "8px", color: "#555" }}>
            <strong style={{ color: "#333" }}>{ticket.ticket_name}: </strong>
            {ticket.total_purchased_tickets} {t("PURCHASED")},{ticket.total_consumed_tickets}{" "}
            {t("CONSUMED")}, {t("AMOUNT")}: {formatCurrency(ticket.total_amount)}
          </Typography>
        ))
      ) : (
        <Typography sx={{ paddingLeft: "16px", paddingBottom: "8px", color: "#555" }}>
          {t("NO_SALES")}
        </Typography>
      )}

      <Typography
        variant="h6"
        gutterBottom
        sx={{ color: "#333", fontWeight: "bold", paddingTop: 2, paddingBottom: 1 }}
      >
        {t("EXTRA_CONSUMPTIONS")}:
      </Typography>
      {extrasConsumptions && extrasConsumptions.length > 0 ? (
        extrasConsumptions.map((extra, index) => (
          <Typography key={index} sx={{ paddingLeft: "16px", paddingBottom: "8px", color: "#555" }}>
            <strong style={{ color: "#333" }}>{extra.extra_name}: </strong>
            {extra.total_purchased_extras} {t("PURCHASED")}, {extra.total_consumed_extras}{" "}
            {t("CONSUMED")}, {t("AMOUNT")}: {formatCurrency(extra.total_amount)}
          </Typography>
        ))
      ) : (
        <Typography sx={{ paddingLeft: "16px", paddingBottom: "8px", color: "#555" }}>
          {t("NO_SALES")}
        </Typography>
      )}
    </Box>
  );
};

export default EventStatisticsDetail;
