import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQueryDomains } from "../../hooks/queries/useQueryDomains";
import { useQueryPremisesList } from "../../hooks/queries/useQueryPremisesList";
import { useQueryCountries } from "../../hooks/queries/utils/useQueryCountries";
import { useQueryRegions } from "../../hooks/queries/utils/useQueryRegions";
import SearchFilter from "../events/filters/filter/SearchFilter";
import SelectFilter from "../events/filters/filter/SelectFilter";

const TourPointFilters = ({ onSearchChange, filters, updateFilter }) => {
  const { t } = useTranslation();

  const { premiseId, domainId, countryCode, regionCode } = filters;

  const { data: premises = [] } = useQueryPremisesList();
  const { data: domains = [] } = useQueryDomains();
  const { data: countries = [] } = useQueryCountries();
  const { data: regions = [] } = useQueryRegions(countryCode);

  const domainOptions = domains.map((domain) => ({
    id: domain.domain_id,
    name: domain.domain_name,
  }));

  const countryOptions = countries.map((country) => ({
    id: country.country_code,
    name: country.official_name_en,
  }));

  const regionOptions = regions.map((region) => ({
    id: region.code,
    name: region.name,
  }));

  return (
    <Grid
      container
      spacing={1}
      mt={2}
      sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
    >
      <Grid item xs={12} md={3}>
        <SearchFilter onSearchChange={onSearchChange} />
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={11}
        md={9}
        columnGap={1}
        rowGap={1}
        justifyContent="flex-end"
      >
        <SelectFilter
          value={domainId}
          onChange={(value) => updateFilter("domainId", value)}
          options={[{ id: "all", name: t("DOMAINS") }, ...domainOptions]}
        />
        <SelectFilter
          value={premiseId}
          onChange={(value) => updateFilter("premiseId", value)}
          options={[{ id: "all", name: t("PREMISES") }, ...premises]}
        />
        <SelectFilter
          value={countryCode}
          onChange={(value) => updateFilter("countryCode", value)}
          options={[{ id: "all", name: t("COUNTRY") }, ...countryOptions]}
        />
        <SelectFilter
          value={regionCode}
          onChange={(value) => updateFilter("regionCode", value)}
          options={[{ id: "all", name: t("REGION") }, ...regionOptions]}
        />
      </Grid>
    </Grid>
  );
};

export default TourPointFilters;
