import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { getTourLeaders } from "../../../services/tourLeaders";

const useQueryTourLeaders = (page, perPage, filters) => {
  return useQuery(
    [RQ_KEY.TOUR_LEADERS, page, perPage, filters],
    () => getTourLeaders(page, perPage, filters),
    {
      refetchOnWindowFocus: false,
    }
  );
};

export default useQueryTourLeaders;
