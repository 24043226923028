import {
  DeleteOutline,
  EditCalendarOutlined,
  EditOutlined,
  RemoveRedEyeOutlined,
} from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

const HotelActions = ({ handleEdit, handleSee, handleEvent, handleOpenDelete, hotelId }) => {
  const { t } = useTranslation();

  return (
    <>
       <Tooltip title={t("SEE_EVENTS")}>
        <IconButton onClick={() => handleSee(hotelId)}>
          <RemoveRedEyeOutlined sx={{ color: "var(--oniria-gold)" }} />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("EDIT_HOTEL")}>
        <IconButton onClick={() => handleEdit(hotelId)}>
          <EditOutlined sx={{ color: "var(--oniria-gold)" }} />
        </IconButton>
      </Tooltip>
      {/* <Tooltip title={t("ASSIGN_EVENT")}>
        <IconButton onClick={() => handleEvent(hotelId)}>
          <EditCalendarOutlined sx={{ color: "var(--oniria-gold)" }} />
        </IconButton>
      </Tooltip> */}
      <Tooltip title={t("DELETE_HOTEL")}>
        <IconButton onClick={() => handleOpenDelete(hotelId)}>
          <DeleteOutline sx={{ color: "var(--oniria-gold)" }} />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default HotelActions;
