import { z } from "zod";

export const transportLanguageInfoSchema = z.object({
  id: z.string().nullable().optional(),
  transport_id: z.string().nullable().optional(),
  language: z.string().nullable().optional(),
  name: z.string().nullable().optional(),
  description: z
    .union([z.string(), z.record(z.any())])
    .nullable()
    .optional(),
});

export const transportSchema = z.object({
  id: z.string().nullable().optional(),
  sku: z.string(),
  name: z.string(),
  type: z.string(),
  places: z.union([z.string(), z.number()]),
  domains_ids: z.array(z.string().optional()).nullable().optional(),
  owner_premise_id: z.string(),
  description: z
    .union([z.string(), z.record(z.any())])
    .nullable()
    .optional(),
  default_language: z.string().default("ES"),
  seats_drawing: z.string().nullable().optional(),
  transport_language_infos: z.array(transportLanguageInfoSchema).optional().nullable(),

  // TRIPS
  template_id: z.string().nullable().optional(),
  start_point_id: z.string().nullable().optional(),
  end_point_id: z.string().nullable().optional(),
  start_date: z.string().nullable().optional(),
  end_date: z.string().nullable().optional(),
  start_date_time: z.string().nullable().optional(),
  end_date_time: z.string().nullable().optional(),
  plate: z.string().nullable().optional(),
  assignation_limit: z.union([z.string(), z.number()]).nullable().optional(),
});

export const defaultTransportLanguageInfo = {
  id: "",
  transport_id: "",
  language: "",
  name: "",
  description: "",
};
