import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { getTourLeaderDetails } from "../../../services/tourLeaders";

const useQueryTourLeaderDetails = (id, onSuccess) => {
  return useQuery([RQ_KEY.TOUR_LEADERS_DETAILS, id], () => getTourLeaderDetails(id), {
    refetchOnWindowFocus: false,
    enabled: !!id,
    onSuccess,
  });
};

export default useQueryTourLeaderDetails;
