import { Grid } from "@mui/material";
import SearchFilter from "../events/filters/filter/SearchFilter";

const HotelsFilters = ({ onSearchChange }) => {
  return (
    <Grid
      container
      spacing={1}
      mt={2}
      sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
    >
      <Grid item xs={12} md={3}>
        <SearchFilter onSearchChange={onSearchChange} />
      </Grid>
    </Grid>
  );
};

export default HotelsFilters;
