export const prepareBlogToSend = (formData, tags, isEditMode) => {
  const newData = JSON?.parse(JSON?.stringify(formData));
  // if (isEditMode) {
  //   newData.tags = newData.tag_ids?.map((id) => tags?.find((tag) => tag.id === id));
  //   delete newData.tag_ids;
  // }

  newData.design =
    typeof newData.design !== "string" ? JSON?.stringify(newData.design) : newData.design;

  newData?.translations?.forEach((translation) => {
    translation.design =
      typeof translation.design !== "string"
        ? JSON?.stringify(translation.design)
        : translation.design;
  });

  // If is a draft, set is_active to false
  if (newData.status === "draft") {
    newData.visible = false;
  }

  //Boolean values
  newData.visible = newData.visible === true || newData.visible === 1;
  newData.featured = newData.featured === true || newData.featured === 1;
  newData.is_sponsored = newData.is_sponsored === true || newData.is_sponsored === 1;
  newData.is_active = newData.is_active === true || newData.is_active === 1;

  //Dates
  delete newData.created_at;
  delete newData.modified_at;
  delete newData.last_modified_at;
  delete newData.domains;

  return newData;
};

export const prepareBlogToReceive = (formData) => {
  const newData = JSON?.parse(JSON?.stringify(formData));
  //Domains
  newData.domain_ids =
    newData.domains?.filter((domain) => domain.id)?.map((domain) => domain.id || domain) || [];
  //Tags
  newData.tag_ids = newData.tags?.filter((tag) => tag.id)?.map((tag) => tag.id || tag) || [];
  newData.design =
    newData.design && typeof newData.design === "string"
      ? JSON?.parse(newData.design)
      : newData.design;

  newData.translations =
    newData.translations && newData.translations.length > 0
      ? newData.translations.map((translation) => {
          translation.design =
            translation.design && typeof translation.design === "string"
              ? JSON?.parse(translation.design)
              : translation.design;
          return translation;
        })
      : newData.translations;

  delete newData.created_at;
  delete newData.modified_at;

  return newData;
};
