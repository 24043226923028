import { transformObjectWithUrls } from "../components/shared/FormsValidator";
import { SERVICE } from "../constants/api";
import http from "../constants/axios";

export async function getAllBlogPostsFiltered(filters, perPage, page) {
  let params = new URLSearchParams();

  if (filters.tag) params.append("tags", filters.tag);
  if (filters.author) params.append("author_id", filters.author);
  if (filters.domain) params.append("domain_id", filters.domain);
  if (filters.visible !== "") params.append("visible", filters.visible);
  if (filters.featured !== "") params.append("featured", filters.featured);
  if (filters.status) params.append("status", filters.status);
  if (filters.search) params.append("search", filters.search);
  if (page) params.append("page", page);
  if (perPage) params.append("perPage", perPage);

  const { data } = await http.get(`${SERVICE.BLOG}?${params}`);
  return data;
}

export async function getBlogDetail(id) {
  try {
    const { data } = await http.get(`${SERVICE.BLOG}${id}`);
    return data;
  } catch (error) {
    console.error("Error fetching blog details:", error);
    throw error;
  }
}

export const updateBlog = async (id, formData) => {
  try {
    const formDataToSend = await transformObjectWithUrls(formData);

    formDataToSend.tags = formData.tags || [];

    const response = await http.put(`${SERVICE.BLOG}${id}`, formDataToSend);
    return response.data || "success";
  } catch (error) {
    console.error("Error fetching tags:", error);
    throw error;
  }
};

export const createBlog = async (formData) => {
  try {
    const formDataToSend = await transformObjectWithUrls(formData);
    delete formDataToSend.id;

    const response = await http.post(`${SERVICE.BLOG}`, formDataToSend);
    return response.data;
  } catch (error) {
    console.error("Error fetching tags:", error);
    throw error;
  }
};

export const checkBlogSlug = async (slug) => {
  try {
    const response = await http.get(`${SERVICE.BLOG}slug-available/${slug}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching tags:", error);
    throw error;
  }
};
