import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { getTransports } from "../../../services/transportServices";
import { TEMPLATES_TAB } from "../../../utils/hotels";

const useQueryTransports = (page, perPage, filters, selectedTab, handleSetTransports) => {
  return useQuery(
    [RQ_KEY.TRANSPORTS, page, perPage, filters, selectedTab],
    () => getTransports(page, perPage, filters, selectedTab),
    {
      refetchOnWindowFocus: false,
      enabled: selectedTab === TEMPLATES_TAB,
      onSuccess: handleSetTransports,
    }
  );
};

export default useQueryTransports;
