import { Grid, MenuItem } from "@mui/material";
import SelectHookForm from "../shared/SelectHookForm";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQueryCountries } from "../../hooks/queries/utils/useQueryCountries";
import { useQueryRegions } from "../../hooks/queries/utils/useQueryRegions";
import { useQueryCities } from "../../hooks/queries/utils/useQueryCities";

const TourPointAddress = () => {
  const { t } = useTranslation();
  const { control, formState, watch } = useFormContext();
  const { errors } = formState;

  const selectedCountry = watch("country_code");
  const selectedRegion = watch("region_code");

  const { data: countries = [] } = useQueryCountries();
  const { data: regions = [] } = useQueryRegions(selectedCountry);
  const { data: cities = [] } = useQueryCities(selectedRegion);

  return (
    <>
      <Grid item xs={12} sm={6}>
        <SelectHookForm
          name={"country_code"}
          control={control}
          label={t("COUNTRY")}
          error={errors.country_code}
          options={countries.map((country) => (
            <MenuItem key={country.country_code} value={country.country_code}>
              {country.official_name_en}
            </MenuItem>
          ))}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectHookForm
          name={"region_code"}
          control={control}
          label={t("REGION")}
          error={errors.region_code}
          options={regions.map((region) => (
            <MenuItem key={region.code} value={region.code}>
              {region.name}
            </MenuItem>
          ))}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectHookForm
          name={"city_id"}
          control={control}
          label={t("CITY")}
          error={errors.city_id}
          options={cities.map((city, index) => (
            <MenuItem key={`${city.name}-${index}`} value={city.name}>
              {city.name}
            </MenuItem>
          ))}
        />
      </Grid>
    </>
  );
};
export default TourPointAddress;
