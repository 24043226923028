import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import InfoToolTip from "../../shared/InfoToolTip";
import { focusColor } from "../../shared/textFieldStyle";

const EventDates = ({
  checkDateField,
  formParams,
  setFormErrors,
  trip,
  detailsErrors,
  buttonClicked,
  isDisabled,
  checkField,
  checkHourField,
}) => {
  const { t } = useTranslation();

  const handleSetMultipleDates = (e) => {
    const isChecked = e.target.checked;
    const fieldsToCheck = ["start_date", "start_date_time", "end_date", "end_date_time"];
    const regexDate = /^(\d{4})-(\d{2})-(\d{2})$/;

    checkField(
      "details",
      "multi_date",
      { target: { value: isChecked } },
      /.*/,
      t("CHOOSE_OPTION"),
      false,
      formParams,
      setFormErrors
    );

    if (isChecked) {
      fieldsToCheck.forEach((field) => {
        checkDateField(
          "details",
          field,
          { target: { value: null } },
          regexDate,
          t("CHOOSE_OPTION"),
          false,
          formParams,
          setFormErrors
        );
      });
    }
  };

  return (
    <>
      <Grid container columnSpacing={2}>
        <Grid item xs={12}>
          <FormControlLabel
            disabled={isDisabled}
            control={
              <Checkbox
                name="roles"
                checked={trip.multi_date || false}
                onChange={handleSetMultipleDates}
                value={trip.multi_date || false}
                className="checkbox-oniria"
              />
            }
            label={t("MULTIPLE_DATES")}
          />
          <InfoToolTip text={t("MULTIPLE_DATES_TOOLTIP")} />
        </Grid>
        {!trip.multi_date && (
          <>
            <Grid item xs={12} md={6}>
              <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                <div
                  style={{
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                    display: "flex",
                  }}
                >
                  {t("START_DATE")}
                  <InfoToolTip text={t("INFO_START_DATE")} />
                </div>
              </InputLabel>
              <TextField
                onChange={(e) =>
                  checkDateField(
                    "details",
                    "start_date",
                    e,
                    /^(\d{4})-(\d{2})-(\d{2})$/,
                    t("CHOOSE_OPTION"),
                    false,
                    formParams,
                    setFormErrors
                  )
                }
                type="date"
                margin="normal"
                required
                fullWidth
                id="start_date"
                name="start_date"
                value={trip.start_date || ""}
                autoComplete="start_date"
                error={
                  (detailsErrors.start_date !== "" && detailsErrors.start_date !== "empty") ||
                  (detailsErrors.start_date === "empty" && buttonClicked)
                }
                helperText={detailsErrors.start_date !== "empty" ? detailsErrors.start_date : ""}
                disabled={isDisabled}
                sx={focusColor}
                inputProps={{
                  min: new Date().toISOString().split("T")[0],
                  max: "2100-12-31",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel sx={{ fontSize: "18px", color: "black" }}>{t("START_TIME")}</InputLabel>
              <TextField
                onChange={(e) =>
                  checkField(
                    "details",
                    "start_date_time",
                    e,
                    /^([01]\d|2[0-3]):([0-5]\d)$/,
                    t("CHOOSE_OPTION"),
                    false,
                    formParams,
                    setFormErrors
                  )
                }
                type="time"
                margin="normal"
                required
                fullWidth
                id="start_date_time"
                name="start_date_time"
                value={trip.start_date_time || ""}
                autoComplete="start_date_time"
                error={
                  (detailsErrors.start_date_time !== "" &&
                    detailsErrors.start_date_time !== "empty") ||
                  (detailsErrors.start_date_time === "empty" && buttonClicked)
                }
                helperText={
                  detailsErrors.start_date_time !== "empty" ? detailsErrors.start_date_time : ""
                }
                disabled={isDisabled}
                sx={focusColor}
              />
            </Grid>
          </>
        )}
      </Grid>
      {!trip.multi_date && (
        <Grid container columnSpacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} md={6}>
            <InputLabel sx={{ fontSize: "18px", color: "black" }}>
              <div
                style={{
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                  display: "flex",
                }}
              >
                {t("END_DATE")}
                <InfoToolTip text={t("INFO_END_DATE")} />
              </div>
            </InputLabel>
            <TextField
              onChange={(e) =>
                checkDateField(
                  "details",
                  "end_date",
                  e,
                  /^(\d{4})-(\d{2})-(\d{2})$/,
                  t("CHOOSE_OPTION"),
                  false,
                  formParams,
                  setFormErrors
                )
              }
              type="date"
              margin="normal"
              required
              fullWidth
              id="end_date"
              name="end_date"
              value={trip.end_date || ""}
              autoComplete="end_date"
              error={
                (detailsErrors.end_date !== "" && detailsErrors.end_date !== "empty") ||
                (detailsErrors.end_date === "empty" && buttonClicked)
              }
              helperText={detailsErrors.end_date !== "empty" ? detailsErrors.end_date : ""}
              disabled={isDisabled}
              sx={focusColor}
              inputProps={{
                min: new Date().toISOString().split("T")[0],
                max: "2100-12-31",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel sx={{ fontSize: "18px", color: "black" }}>{t("END_TIME")}</InputLabel>
            <TextField
              onChange={(e) =>
                checkHourField(
                  "details",
                  "end_date_time",
                  e,
                  /^([01]\d|2[0-3]):([0-5]\d)$/,
                  t("CHOOSE_OPTION"),
                  false,
                  formParams,
                  setFormErrors
                )
              }
              type="time"
              margin="normal"
              required
              fullWidth
              id="end_date_time"
              name="end_date_time"
              value={trip.end_date_time || ""}
              autoComplete="end_date_time"
              error={
                (detailsErrors.end_date_time !== "" && detailsErrors.end_date_time !== "empty") ||
                (detailsErrors.end_date_time === "empty" && buttonClicked)
              }
              helperText={
                detailsErrors.end_date_time !== "empty" ? detailsErrors.end_date_time : ""
              }
              disabled={isDisabled}
              sx={focusColor}
            />
          </Grid>
        </Grid>
      )}
      <Accordion sx={{ mt: 2, borderRadius: 2, "::before": { position: "unset" } }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ fontSize: "18px", color: "black" }}>
            {t("MORE_DATES_OPTIONAL")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                <div
                  style={{
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                    display: "flex",
                  }}
                >
                  {t("START_SALE_DATE")}
                  <InfoToolTip text={t("INFO_START_SALE_DATE")} />
                </div>
              </InputLabel>
              <TextField
                onChange={(e) =>
                  checkDateField(
                    "details",
                    "start_sale_date",
                    e,
                    /^(\d{4})-(\d{2})-(\d{2})$/,
                    t("CHOOSE_OPTION"),
                    true,
                    formParams,
                    setFormErrors
                  )
                }
                type="date"
                margin="normal"
                fullWidth
                id="start_sale_date"
                name="start_sale_date"
                value={trip.start_sale_date}
                autoComplete="start_sale_date"
                error={
                  (detailsErrors.start_sale_date !== "" &&
                    detailsErrors.start_sale_date !== "empty") ||
                  (detailsErrors.start_sale_date === "empty" && buttonClicked)
                }
                helperText={
                  detailsErrors.start_sale_date !== "empty" ? detailsErrors.start_sale_date : ""
                }
                disabled={isDisabled}
                sx={focusColor}
                inputProps={{
                  min: new Date().toISOString().split("T")[0],
                  max: "2100-12-31",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                {t("START_SALE_TIME")}
              </InputLabel>
              <TextField
                onChange={(e) =>
                  checkField(
                    "details",
                    "start_sale_date_time",
                    e,
                    /^$|^([01]\d|2[0-3]):([0-5]\d)$/,
                    t("CHOOSE_OPTION"),
                    true,
                    formParams,
                    setFormErrors
                  )
                }
                type="time"
                margin="normal"
                fullWidth
                id="start_sale_date_time"
                name="start_sale_date_time"
                value={trip.start_sale_date_time}
                autoComplete="start_sale_date_time"
                error={
                  (detailsErrors.start_sale_date_time !== "" &&
                    detailsErrors.start_sale_date_time !== "empty") ||
                  (detailsErrors.start_sale_date_time === "empty" && buttonClicked)
                }
                helperText={
                  detailsErrors.start_sale_date_time !== "empty"
                    ? detailsErrors.start_sale_date_time
                    : ""
                }
                disabled={isDisabled}
                sx={focusColor}
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={6}>
              <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                <div
                  style={{
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                    display: "flex",
                  }}
                >
                  {t("END_SALE_DATE")}
                  <InfoToolTip text={t("INFO_END_SALE_DATE")} />
                </div>
              </InputLabel>
              <TextField
                onChange={(e) =>
                  checkDateField(
                    "details",
                    "end_sale_date",
                    e,
                    /^(\d{4})-(\d{2})-(\d{2})$/,
                    t("CHOOSE_OPTION"),
                    true,
                    formParams,
                    setFormErrors
                  )
                }
                type="date"
                margin="normal"
                fullWidth
                id="end_sale_date"
                name="end_sale_date"
                value={trip.end_sale_date}
                autoComplete="end_sale_date"
                error={
                  (detailsErrors.end_sale_date !== "" && detailsErrors.end_sale_date !== "empty") ||
                  (detailsErrors.end_sale_date === "empty" && buttonClicked)
                }
                helperText={
                  detailsErrors.end_sale_date !== "empty" ? detailsErrors.end_sale_date : ""
                }
                disabled={isDisabled}
                sx={focusColor}
                inputProps={{
                  min: new Date().toISOString().split("T")[0],
                  max: "2100-12-31",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                {t("END_SALE_TIME")}
              </InputLabel>
              <TextField
                onChange={(e) =>
                  checkField(
                    "details",
                    "end_sale_date_time",
                    e,
                    /^$|^([01]\d|2[0-3]):([0-5]\d)$/,
                    t("CHOOSE_OPTION"),
                    true,
                    formParams,
                    setFormErrors
                  )
                }
                type="time"
                margin="normal"
                fullWidth
                id="end_sale_date_time"
                name="end_sale_date_time"
                value={trip.end_sale_date_time}
                autoComplete="end_sale_date_time"
                error={
                  (detailsErrors.end_sale_date_time !== "" &&
                    detailsErrors.end_sale_date_time !== "empty") ||
                  (detailsErrors.end_sale_date_time === "empty" && buttonClicked)
                }
                helperText={
                  detailsErrors.end_sale_date_time !== "empty"
                    ? detailsErrors.end_sale_date_time
                    : ""
                }
                disabled={isDisabled}
                sx={focusColor}
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={6}>
              <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                <div
                  style={{
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                    display: "flex",
                  }}
                >
                  {t("PUBLICATION_DATE")}
                  <InfoToolTip text={t("INFO_PUBLICATION_DATE")} />
                </div>
              </InputLabel>
              <TextField
                onChange={(e) =>
                  checkDateField(
                    "details",
                    "publication_date",
                    e,
                    /^(\d{4})-(\d{2})-(\d{2})$/,
                    t("CHOOSE_OPTION"),
                    true,
                    formParams,
                    setFormErrors
                  )
                }
                type="date"
                margin="normal"
                fullWidth
                id="publication_date"
                name="publication_date"
                value={trip.publication_date}
                autoComplete="publication_date"
                error={
                  (detailsErrors.publication_date !== "" &&
                    detailsErrors.publication_date !== "empty") ||
                  (detailsErrors.publication_date === "empty" && buttonClicked)
                }
                helperText={
                  detailsErrors.publication_date !== "empty" ? detailsErrors.publication_date : ""
                }
                disabled={isDisabled}
                sx={focusColor}
                inputProps={{
                  min: new Date().toISOString().split("T")[0],
                  max: "2100-12-31",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                {t("PUBLICATION_TIME")}
              </InputLabel>
              <TextField
                onChange={(e) =>
                  checkField(
                    "details",
                    "publication_date_time",
                    e,
                    /^$|^([01]\d|2[0-3]):([0-5]\d)$/,
                    t("CHOOSE_OPTION"),
                    true,
                    formParams,
                    setFormErrors
                  )
                }
                type="time"
                margin="normal"
                fullWidth
                id="publication_date_time"
                name="publication_date_time"
                value={trip.publication_date_time}
                autoComplete="publication_date_time"
                error={
                  (detailsErrors.publication_date_time !== "" &&
                    detailsErrors.publication_date_time !== "empty") ||
                  (detailsErrors.publication_date_time === "empty" && buttonClicked)
                }
                helperText={
                  detailsErrors.publication_date_time !== "empty"
                    ? detailsErrors.publication_date_time
                    : ""
                }
                disabled={isDisabled}
                sx={focusColor}
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={6}>
              <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                <div
                  style={{
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                    display: "flex",
                  }}
                >
                  {t("END_OF_PUBLICATION_DATE")}
                  <InfoToolTip text={t("END_OF_INFO_PUBLICATION_DATE")} />
                </div>
              </InputLabel>
              <TextField
                onChange={(e) =>
                  checkDateField(
                    "details",
                    "end_of_publication_date",
                    e,
                    /^(\d{4})-(\d{2})-(\d{2})$/,
                    t("CHOOSE_OPTION"),
                    true,
                    formParams,
                    setFormErrors
                  )
                }
                type="date"
                margin="normal"
                fullWidth
                id="end_of_publication_date"
                name="end_of_publication_date"
                value={trip.end_of_publication_date}
                autoComplete="end_of_publication_date"
                error={
                  (detailsErrors.end_of_publication_date !== "" &&
                    detailsErrors.end_of_publication_date !== "empty") ||
                  (detailsErrors.end_of_publication_date === "empty" && buttonClicked)
                }
                helperText={
                  detailsErrors.end_of_publication_date !== "empty"
                    ? detailsErrors.end_of_publication_date
                    : ""
                }
                disabled={isDisabled}
                sx={focusColor}
                inputProps={{
                  min: new Date().toISOString().split("T")[0],
                  max: "2100-12-31",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                {t("END_OF_PUBLICATION_TIME")}
              </InputLabel>
              <TextField
                onChange={(e) =>
                  checkField(
                    "details",
                    "end_of_publication_date_time",
                    e,
                    /^$|^([01]\d|2[0-3]):([0-5]\d)$/,
                    t("CHOOSE_OPTION"),
                    true,
                    formParams,
                    setFormErrors
                  )
                }
                type="time"
                margin="normal"
                fullWidth
                id="end_of_publication_date_time"
                name="end_of_publication_date_time"
                value={trip.end_of_publication_date_time}
                autoComplete="end_of_publication_date_time"
                error={
                  (detailsErrors.end_of_publication_date_time !== "" &&
                    detailsErrors.end_of_publication_date_time !== "empty") ||
                  (detailsErrors.end_of_publication_date_time === "empty" && buttonClicked)
                }
                helperText={
                  detailsErrors.end_of_publication_date_time !== "empty"
                    ? detailsErrors.end_of_publication_date_time
                    : ""
                }
                disabled={isDisabled}
                sx={focusColor}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default EventDates;
