import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { getExtras } from "../../../services/extrasServices";

const useQueryExtras = (page, perPage, filters, enabled = true) => {
  return useQuery(
    [RQ_KEY.EXTRAS_TEMPLATES, page, perPage, filters],
    () => getExtras(page, perPage, filters),
    {
      refetchOnWindowFocus: false,
      enabled: enabled,
    }
  );
};

export default useQueryExtras;
