import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQueryPremisesList } from "../../hooks/queries/useQueryPremisesList";
import SearchFilter from "../events/filters/filter/SearchFilter";
import SelectFilter from "../events/filters/filter/SelectFilter";
import { focusColor } from "../shared/textFieldStyle";

const EventFilters = ({ onSearchChange, updateFilter, filters }) => {
  const { t } = useTranslation();

  const handleChangeMonth = (e) => {
    const { name, value } = e.target;
    updateFilter(name, value);
  };

  const { data: premises = [] } = useQueryPremisesList();

  const premisesFormat = premises?.map((premise) => ({
    id: premise.id,
    name: premise.name,
  }));

  const handleChangePremise = (value) => {
    updateFilter("premiseId", value);
  };

  return (
    <Grid
      container
      spacing={1}
      sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", mb: 2 }}
    >
      <Grid item xs={12} md={3}>
        <SearchFilter onSearchChange={onSearchChange} />
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={11}
        md={9}
        columnGap={1}
        rowGap={1}
        justifyContent={{ xs: "flex-start", md: "flex-end" }}
      >
        <SelectFilter
          value={filters.premiseId}
          onChange={handleChangePremise}
          options={[{ id: "all", name: t("ALL_SITES") }, ...premisesFormat]}
        />
        <TextField
          label={t("DATE")}
          type="month"
          onChange={handleChangeMonth}
          name="month"
          value={filters.month}
          sx={[focusColor, { width: 200 }]}
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
        />
      </Grid>
    </Grid>
  );
};

export default EventFilters;
