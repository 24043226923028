import { GridView, ViewList } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Button, Grid, IconButton, Pagination, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import CustomDialog from "../../components/shared/CustomDialog";
import { Loading } from "../../components/shared/Loading";
import SelectPerPage from "../../components/shared/SelectPerPage";
import { toastMessageError, toastMessageSuccess } from "../../components/shared/toastMessage";
import TourLeadersFilters from "../../components/tourLeaders/TourLeadersFilters";
import TourLeadersGrid from "../../components/tourLeaders/TourLeadersGrid";
import TourLeadersTable from "../../components/tourLeaders/TourLeadersTable";
import { ROUTES } from "../../constants/routes";
import { PAGINATION_STYLES } from "../../constants/styles";
import useQueryTourLeaders from "../../hooks/queries/tourLeaders/useQueryTourLeaders";
import { useQueryPremisesList } from "../../hooks/queries/useQueryPremisesList";
import useDebounce from "../../hooks/useDebouncing";
import usePagination from "../../hooks/usePagination";
import { deleteTourLeader } from "../../services/tourLeaders";

const TourLeadersPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const breadcrumbs = [{ name: t("TOUR_LEADERS"), link: ROUTES.TOUR_LEADERS }];
  const [showTable, setShowTable] = useState(true);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [filters, setFilters] = useState({
    search: "",
    premiseId: "all",
    asc: [],
    desc: ["created_at"],
    genre: "all",
  });
  const { page, perPage, changePage, changePerPage } = usePagination();

  const { data: premises = [] } = useQueryPremisesList();
  const { data, isLoading, error, refetch } = useQueryTourLeaders(page, perPage, filters);

  const { data: tourLeaders = [], total: totalTourLeaders } = data || {};

  const updateFilter = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    changePage(null, 1);
  };

  const handleSearchChange = (event) => {
    updateFilter("search", event.target.value);
    changePage(null, 1);
  };

  const debounceSearch = useDebounce(handleSearchChange, 300);

  const handleOrder = (id, type) => {
    const oppositeType = type === "asc" ? "desc" : "asc";
    const newFilters = { ...filters };
    if (newFilters[type]?.includes(id)) {
      newFilters[type] = newFilters[type].filter((item) => item !== id);
    } else {
      newFilters[type].push(id);
      newFilters[oppositeType] = newFilters[oppositeType]?.filter((item) => item !== id);
    }
    setFilters(newFilters);
  };

  const handleCreate = () => {
    navigate(ROUTES.CREATE_TOUR_LEADER);
  };

  const handleOpenDelete = (id) => {
    setSelectedId(id);
    setOpenConfirm(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteTourLeader(selectedId);
      toastMessageSuccess(t("DELETED_SUCCESSFULLY"));
    } catch (error) {
      toastMessageError(error?.response?.data?.message || t("ERROR"));
    } finally {
      setSelectedId("");
      refetch();
      setOpenConfirm(false);
    }
  };

  const handleEdit = (id) => {
    navigate(`/tour-leaders/edit/${id}`);
  };

  const handleSee = (id) => {
    navigate(`/tour-leaders/edit/${id}`);
  };

  const numberPages = Math.ceil(parseFloat(totalTourLeaders) / parseFloat(perPage));

  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t("TOUR_LEADERS_PAGE_DESCRIPTION")} />
      <TourLeadersFilters
        onSearchChange={debounceSearch}
        filters={filters}
        updateFilter={updateFilter}
      />
      <Grid container justifyContent="flex-end" alignItems="center" mb={2} mt={2}>
        <Button
          variant="contained"
          className="oniria-btn"
          startIcon={<AddIcon />}
          onClick={handleCreate}
        >
          {t("CREATE_TOUR_LEADER")}
        </Button>
      </Grid>
      {isLoading ? (
        <Loading />
      ) : error ? (
        <p>{t("ERROR_LOADING_DATA")}</p>
      ) : totalTourLeaders > 0 ? (
        <div style={{ marginBottom: "20px" }}>
          <Grid
            item
            xs={11}
            sm={12}
            container
            justifyContent={{ xs: "center", sm: "space-between" }}
            alignItems={"center"}
            pb={2}
            gap={{ xs: 2, sm: 0 }}
          >
            <Typography>
              {t("TOTAL_TOUR_LEADERS")}: {totalTourLeaders}
            </Typography>
            <Grid container item xs={12} md={8} justifyContent="flex-end">
              {numberPages >= 1 && (
                <Grid item xs={12} md={8} container justifyContent="flex-end">
                  <Grid item>
                    <SelectPerPage
                      text={t("TOUR_LEADERS")}
                      change={changePerPage}
                      value={perPage}
                      total={totalTourLeaders}
                    />
                  </Grid>
                  <Pagination
                    count={numberPages}
                    mr={3}
                    sx={PAGINATION_STYLES}
                    page={page}
                    onChange={changePage}
                  />
                </Grid>
              )}
              <Grid>
                <IconButton onClick={() => setShowTable(true)}>
                  <ViewList />
                </IconButton>
                <IconButton onClick={() => setShowTable(false)}>
                  <GridView />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          {showTable ? (
            <TourLeadersTable
              data={tourLeaders}
              filters={filters}
              handleOrder={handleOrder}
              handleEdit={handleEdit}
              handleSee={handleSee}
              handleOpenDelete={handleOpenDelete}
              premises={premises}
            />
          ) : (
            <TourLeadersGrid
              data={tourLeaders}
              handleEdit={handleEdit}
              handleSee={handleSee}
              handleOpenDelete={handleOpenDelete}
              premises={premises}
            />
          )}
          {numberPages >= 1 && (
            <Grid container justifyContent="center">
              <Pagination
                count={numberPages}
                mr={3}
                sx={PAGINATION_STYLES}
                page={page}
                onChange={changePage}
              />
            </Grid>
          )}
        </div>
      ) : (
        <p>{t("NO_TOUR_LEADERS_FOUND")}</p>
      )}
      <CustomDialog
        isOpen={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title={t("DELETE_TOUR_LEADER")}
        content={t("CONFIRM_DELETE_TOUR_LEADER")}
        onAccept={handleConfirmDelete}
      />
    </>
  );
};

export default TourLeadersPage;
