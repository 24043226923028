import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { getTourPointDetails } from "../../../services/tourPoints";

const useQueryTourPointDetails = (id, onSuccess) => {
  return useQuery([RQ_KEY.TOUR_POINTS_DETAILS, id], () => getTourPointDetails(id), {
    refetchOnWindowFocus: false,
    enabled: !!id,
    onSuccess,
  });
};

export default useQueryTourPointDetails;
