import { Box, Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { createAdminErrorsOK } from "../../classes/userClass";
import Header from "../../components/Header";
import LegalPerson from "../../components/account/LegalPerson";
import { transformObjectWithUrls } from "../../components/shared/FormsValidator";
import { Loading } from "../../components/shared/Loading";
import { toastMessageError, toastMessageSuccess } from "../../components/shared/toastMessage";
import { formatDateYMD } from "../../constants/utils";
import { ADMIN_ROLE, ROLES } from "../../constants/variables";
import { getUserById } from "../../services/authenticationServices";
import { putUser } from "../../services/usersServices";

const AdminDetail = () => {
  const { t } = useTranslation();
  const { adminId } = useParams();
  const [formData, setFormData] = useState();
  const [formParamsErrors, setFormParamsErrors] = useState(createAdminErrorsOK);
  const [adminName, setAdminName] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  const [searchParam] = useSearchParams();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.userInfo);
  const userSessionId = userInfo.id;
  const roles = userInfo.roles;
  const isSuperAdmin = roles.find((element) => element === ROLES.SUPER_ADMIN);
  const isManager = roles.find((element) => element === ROLES.MANAGER_ADMIN);

  const breadcrumbs = [
    {
      name: t("ADMINISTRATOR_MANAGER"),
      link: "/admins",
    },
    {
      name: adminName ? adminName : "",
      link: `/admins/${adminId}`,
    },
  ];

  const handleQuerySuccess = (data) => {
    const adminData = data;

    adminData.roles = adminData.rolesResult.map((role) => role.name); // Obtenemos solo los valores de name'
    delete adminData.rolesResult;

    // Iterar a través de las propiedades del objeto adminData
    for (const key in adminData) {
      if (Object.hasOwnProperty.call(adminData, key)) {
        // Verificar si el valor es una fecha en formato de cadena y formatearla si es necesario
        if (typeof adminData[key] === "string" && !isNaN(new Date(adminData[key]))) {
          adminData[key] = formatDateYMD(adminData[key]);
        }
      }
    }
    adminData.premises_id = adminData?.premisesResult?.map((premise) => premise.id);

    delete adminData.created_at;

    setFormData({ user: adminData });
    setAdminName(adminData.name);
  };

  const { isLoading } = useQuery("user", () => getUserById(adminId), {
    onSuccess: handleQuerySuccess,
    refetchOnWindowFocus: false,
  });
  useEffect(() => {
    const isEdit = searchParam.get("edit");
    if (isEdit) {
      setIsDisabled(false);
    }
  }, []);

  const setFormAdminRoles = (objectName, field, e, atribute = "value") => {
    let object = formData[objectName];
    const valueToAdd = e.target[atribute];
    const correspondingValue =
      valueToAdd === "admin"
        ? "delegate"
        : valueToAdd === "delegate"
        ? "admin"
        : valueToAdd === "affiliated"
        ? ""
        : "affiliated";

    if (object[field].includes(valueToAdd)) return;
    object[field] = object[field].filter((role) => role !== correspondingValue);
    object[field] = [...object[field], valueToAdd];

    setFormData((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const setFormParams = (objectName, field, e, atribute = "value") => {
    let object = formData[objectName];
    object[field] = e.target[atribute];
    setFormData((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const setFormParamsNotEvent = (objectName, field, value) => {
    let object = formData[objectName];
    object[field] = value;
    setFormData((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const setFormErrors = (objectName, field, value) => {
    let object = formParamsErrors[objectName];
    object[field] = value;
    setFormParamsErrors((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const handleSubmit = async () => {
    const adminToSend = await transformObjectWithUrls(formData);

    delete adminToSend.user.email;
    adminToSend.roles =
      typeof adminToSend.roles === "string"
        ? [ADMIN_ROLE[adminToSend.roles] || adminToSend.roles]
        : adminToSend.roles?.map((role) => ADMIN_ROLE[role] || role)?.filter((role) => role !== "");

    adminToSend.user.roles =
      typeof adminToSend?.user?.roles === "string"
        ? [ADMIN_ROLE[adminToSend?.user?.roles] || adminToSend?.user?.roles]
        : adminToSend?.user?.roles
            ?.map((role) => ADMIN_ROLE[role] || role)
            ?.filter((role) => role !== "");

    // Es premise_ids como lo gestiona
    adminToSend.user.premises_ids = adminToSend.user.premises_id;

    adminToSend.is_legal_admin =
      adminToSend.is_legal_admin === true || adminToSend.is_legal_admin === 1;

    delete adminToSend.user.premises_id;
    delete adminToSend.user.premisesResult;
    try {
      const response = await putUser(adminId, adminToSend.user);
      if (response !== undefined && response.result === "success") {
        toastMessageSuccess(t("EDIT_SUCCESS"));
        setTimeout(() => {
          navigate("/admins");
        }, 1500);
      }
    } catch (error) {
      toastMessageError(error?.response?.data?.error || t("EDIT_ERROR"));
    }
  };

  const isFirstStep = () => {
    return true;
  };

  const isLastStep = () => {
    return true;
  };

  const formController = {
    formData,
    formParams: setFormParams,
    formAddRolesAdminParams: setFormAdminRoles,
    validation: formParamsErrors,
    setFormErrors,
    isFirstStep,
    isLastStep,
    finish: handleSubmit,
    setFormParamsNotEvent,
  };

  const formActivate = () => {
    setIsDisabled(false);
  };

  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t("HEADER_TEXT_ADMIN_DETAIL") + adminName} />
      <Box sx={{ position: "relative" }}>
        {isDisabled && (isSuperAdmin || isManager || userSessionId === adminId) && (
          <Grid item xs={11} sx={{ display: "flex", flexDirection: "row-reverse" }}>
            <Button
              onClick={() => formActivate()}
              className="oniria-btn"
              variant="contained"
              sx={{
                fontSize: "12px",
                borderRadius: 3,
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              {t("EDIT")}
            </Button>
          </Grid>
        )}
        {formData && (
          <LegalPerson formController={formController} isDisabled={isDisabled} editPremises />
        )}
      </Box>
    </>
  );
};

export default AdminDetail;
