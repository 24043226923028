import { DeleteOutline, EditOutlined, InsertChartOutlined } from "@mui/icons-material";
import EditCalendarOutlinedIcon from "@mui/icons-material/EditCalendarOutlined";
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { formatDate, getPremiseName } from "../../constants/utils";
import { useQueryPremisesList } from "../../hooks/queries/useQueryPremisesList";
import { deleteExtraTemplate } from "../../services/extrasServices";
import CustomDialog from "../shared/CustomDialog";
import OrderButtons from "../shared/OrderButtons";
import { toastMessageError, toastMessageSuccess } from "../shared/toastMessage";
import AddEventDialog from "./AddEventDialog";

const ExtrasTable = ({ data, filters, handleOrder, refetch }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedExtra, setSelectedExtra] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAddToEvent, setOpenAddToEvent] = useState(false);

  const { data: premises } = useQueryPremisesList();

  const handleEdit = (id) => {
    navigate(`/extras/edit/${id}`);
  };

  const handleSee = (id) => {
    navigate(`/extra/events/${id}`);
  };

  const handleDelete = (id) => {
    setSelectedExtra(id);
    setOpenConfirm(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteExtraTemplate(selectedExtra);
      toastMessageSuccess(t("EXTRA_DELETED_SUCCESSFULLY"));
    } catch (error) {
      toastMessageError(error?.response?.data?.message || t("ERROR"));
    } finally {
      setSelectedExtra("");
      setOpenConfirm(false);
      refetch();
    }
    setOpenConfirm(false);
  };

  const handleAddToEvent = (id) => {
    setSelectedExtra(id);
    setOpenAddToEvent(true);
  };

  return (
    <TableContainer component={Paper} sx={{ borderRadius: "20px", mb: 2 }}>
      <Table sx={{ whiteSpace: "nowrap" }}>
        <TableHead>
          <TableRow sx={{ borderBottom: "2px solid var(--secondary-color)" }}>
            <TableCell>
              <OrderButtons
                columnName={t("NAME")}
                id="name"
                order={filters}
                handleClickAsc={handleOrder}
                handleClickDesc={handleOrder}
              />
            </TableCell>
            <TableCell>
              <OrderButtons
                columnName={t("SKU")}
                id="sku"
                order={filters}
                handleClickAsc={handleOrder}
                handleClickDesc={handleOrder}
              />
            </TableCell>
            <TableCell>
              <OrderButtons
                columnName={t("PREMISE")}
                id="owner_premise_id"
                order={filters}
                handleClickAsc={handleOrder}
                handleClickDesc={handleOrder}
              />
            </TableCell>
            <TableCell>
              <OrderButtons
                columnName={t("CREATION_DATE")}
                id="created_at"
                order={filters}
                handleClickAsc={handleOrder}
                handleClickDesc={handleOrder}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row) => (
            <TableRow
              key={row.id}
              sx={{
                backgroundColor: row.is_active === 0 ? "var(--grey-cancelled)" : "",
                whiteSpace: "nowrap",
              }}
            >
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.sku}</TableCell>
              <TableCell>{getPremiseName(row.owner_premise_id, premises)}</TableCell>
              <TableCell>{formatDate(row.created_at)}</TableCell>
              <TableCell></TableCell>
              <TableCell>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Tooltip title={t("EDIT_EXTRA")}>
                    <IconButton onClick={() => handleEdit(row.id)}>
                      <EditOutlined sx={{ color: "var(--oniria-gold)" }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("EXTRA_EVENTS")}>
                    <IconButton onClick={() => handleSee(row.id)}>
                      <InsertChartOutlined sx={{ color: "var(--oniria-gold)" }} />
                    </IconButton>
                  </Tooltip>
                  {row.is_active === 0 ? (
                    <Typography
                      variant="body2"
                      color="error"
                      sx={{ display: "block", width: "fit-content" }}
                    >
                      {t("DELETED")}
                    </Typography>
                  ) : (
                    <>
                      <Tooltip title={t("ADD_TO_EVENT")}>
                        <IconButton onClick={() => handleAddToEvent(row.id)}>
                          <EditCalendarOutlinedIcon sx={{ color: "var(--oniria-gold)" }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t("DELETE_EXTRA")}>
                        <IconButton onClick={() => handleDelete(row.id)}>
                          <DeleteOutline sx={{ color: "var(--secondary-color)" }} />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <CustomDialog
        isOpen={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title={t("DELETE_EXTRA")}
        content={t("CONFIRM_DELETE_EXTRA")}
        onAccept={handleConfirmDelete}
      />
      <AddEventDialog
        isOpen={openAddToEvent}
        onClose={() => setOpenAddToEvent(false)}
        extraId={selectedExtra}
      />
    </TableContainer>
  );
};

export default ExtrasTable;
