export const calculateSum = (sales, key) => {
  return sales.reduce((total, payment) => {
    return total + parseFloat(payment[key] || 0) + parseFloat(payment[`first_payment_${key}`] || 0);
  }, 0);
};

export const amount = (sales) => calculateSum(sales, "total_sales");
export const commission = (sales) => calculateSum(sales, "net_income");
export const premiseCommission = (sales) => calculateSum(sales, "premise_commissions");

export const refundedOrders = (sales) => {
  return sales.reduce((total, payment) => total + parseFloat(payment.total_refunds || 0), 0);
};

export const total = (sales) => {
  return amount(sales) + commission(sales) + premiseCommission(sales);
};
