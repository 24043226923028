import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { getAllOrders } from "../../../services/eventsServices";

const useQueryOrdersEvent = (
  eventId,
  selectedDomain,
  page,
  perPage,
  search,
  order,
  selectedTicket,
  selectedStatus,
  enabled,
  onSuccess
) => {
  return useQuery(
    [
      RQ_KEY.ORDERS_EVENT_ID,
      eventId,
      selectedDomain,
      search,
      order,
      selectedTicket,
      page,
      perPage,
      selectedStatus,
    ],
    () =>
      getAllOrders(
        eventId,
        selectedDomain,
        page,
        perPage,
        search,
        order,
        selectedTicket,
        selectedStatus
      ),
    {
      refetchOnWindowFocus: false,
      enabled: enabled,
      onSuccess: onSuccess,
    }
  );
};

export default useQueryOrdersEvent;
