import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryAssignDomains } from "../../../hooks/queries/useQueryAssignDomains";

const SELECT_OPTIONS = {
  ALL: "all",
  SELECT: "select",
};

const ExtraDomainSelect = ({ event_categories, extraDomains, setFormData, extraIndex }) => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(() => {
    return extraDomains?.length > 0 ? SELECT_OPTIONS.SELECT : SELECT_OPTIONS.ALL;
  });

  const { data: domainCategories } = useQueryAssignDomains();

  const isDomainChecked = (domain) => {
    return domain.categories.some(
      (category) =>
        event_categories?.some(
          (selectedCategory) => selectedCategory.category_id === category.id
        ) ||
        category?.subcategories.some((subcategory) =>
          event_categories?.some(
            (selectedCategory) => selectedCategory.subcategory_id === subcategory.id
          )
        )
    );
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    if (value === SELECT_OPTIONS.ALL) {
      setFormData((prev) => {
        const newExtras = [...prev.extras];
        if (extraIndex >= 0 && extraIndex < newExtras.length) {
          newExtras[extraIndex] = {
            ...newExtras[extraIndex],
            domain_names: [],
          };
        }
        return {
          ...prev,
          extras: newExtras,
        };
      });
    }
  };

  const handleDomainChange = (domain, isChecked) => {
    const prevDomains = [...extraDomains];
    if (isChecked) {
      prevDomains.push(domain.name);
      setFormData((prev) => {
        const newExtras = [...prev.extras];
        if (extraIndex >= 0 && extraIndex < newExtras.length) {
          newExtras[extraIndex] = {
            ...newExtras[extraIndex],
            domain_names: prevDomains,
          };
        }
        return {
          ...prev,
          extras: newExtras,
        };
      });
    } else {
      const index = prevDomains.indexOf(domain.name);
      if (index > -1) {
        prevDomains.splice(index, 1);
      }
      setFormData((prev) => {
        const newExtras = [...prev.extras];
        if (extraIndex >= 0 && extraIndex < newExtras.length) {
          newExtras[extraIndex] = {
            ...newExtras[extraIndex],
            domain_names: prevDomains,
          };
        }
        return {
          ...prev,
          extras: newExtras,
        };
      });
    }
  };

  return (
    <Grid item xs={12} mt={1} container>
      <Grid item container>
        <FormControl>
          <Typography>{t("SALES_ON")}:</Typography>
          <RadioGroup value={selectedValue} onChange={handleRadioChange} name="radio-buttons-group">
            <FormControlLabel
              value={SELECT_OPTIONS.ALL}
              control={<Radio className="radio-oniria" />}
              label={t("OPTION_ALL_DOMAINS")}
            />
            <FormControlLabel
              value={SELECT_OPTIONS.SELECT}
              control={<Radio className="radio-oniria" />}
              label={t("CHOSE_DOMAINS")}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        {selectedValue === SELECT_OPTIONS.SELECT && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t("DOMAINS")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {domainCategories
                ?.filter((domain) => isDomainChecked(domain))
                ?.map((domain) => (
                  <Fragment key={domain.id}>
                    <Grid>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={extraDomains?.includes(domain.name) || false}
                            onChange={(e) => handleDomainChange(domain, e.target.checked)}
                            className="checkbox-oniria"
                          />
                        }
                        label={<Typography>{domain.name}</Typography>}
                      />
                    </Grid>
                  </Fragment>
                ))}
            </AccordionDetails>
          </Accordion>
        )}
      </Grid>
    </Grid>
  );
};

export default ExtraDomainSelect;
