import { AddOutlined, CloseOutlined } from "@mui/icons-material";
import { Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { focusColor } from "../shared/textFieldStyle";
import InfoToolTip from "../../components/shared/InfoToolTip";

const HotelRss = () => {
  const { t } = useTranslation();
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const socialMedia = watch("social_media_urls") || [{ name: "", url: "" }];

  const handleAddLink = () => {
    setValue("social_media_urls", [...socialMedia, { name: "", url: "" }]);
  };

  const handleDeleteLink = (index) => {
    setValue(
      "social_media_urls",
      socialMedia.filter((_, i) => i !== index)
    );
  };

  return (
    <Grid item xs={12}>
      <Typography sx={{mb: 2, mt: 2}}>{t("LINK_TO_RSS")}<InfoToolTip text={t("HOTEL_RRSS")} /></Typography>
      <Grid container sx={{ m: 1 }}>
        {socialMedia?.map((_, index) => (
          <Grid item xs={12} key={index}>
            <Grid container spacing={2} alignItems="center" justifyContent="space-between">
              <Grid item xs={12} sm={5.5}>
                <Typography>{t("NAME")}</Typography>
                <TextField
                  placeholder={t("NAME")}
                  {...register(`social_media_urls[${index}].name`)}
                  error={!!errors.social_media_urls?.[index]?.name}
                  helperText={errors.social_media_urls?.[index]?.name?.message}
                  fullWidth
                  sx={focusColor}
                />
              </Grid>
              <Grid item xs={10} sm={5.5}>
                <Typography>{t("URL")}</Typography>
                <TextField
                  placeholder={t("URL")}
                  {...register(`social_media_urls[${index}].url`)}
                  error={!!errors.social_media_urls?.[index]?.url}
                  helperText={errors.social_media_urls?.[index]?.url?.message}
                  fullWidth
                  sx={focusColor}
                />
              </Grid>
              <Grid item xs={11} sm={1}>
                <IconButton onClick={() => handleDeleteLink(index)}>
                  <CloseOutlined />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Button onClick={handleAddLink} variant="contained" className="oniria-btn" sx={{ mt: 2 }}>
        <AddOutlined /> <Typography>{t("ADD_LINK")}</Typography>
      </Button>
    </Grid>
  );
};

export default HotelRss;
