export const defaultExtra = {
  name: "",
  sku: "",
  short_description: "",
  images: "",
  description_design: "",
  description: "",
  owner_premise_id: "",
  extra_template_language_infos: [],
  domains_ids: [],
};

export const extraLanguageInfos = {
  language: "",
  name: "",
  description_design: "",
  description: "",
  short_description: "",
  extra_id: "",
};

export const defaultExtraError = {
  name: "",
  sku: "",
  description_design: "",
  description: "",
  images: "",
  short_description: "",
  owner_premise_id: "",
};
