import { ExpandLess, ExpandMore, FileDownload, Paid, Visibility } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import {
  ButtonGroup,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { compareDates, formatDate, getPremiseName } from "../../constants/utils";
import { useQueryPremisesList } from "../../hooks/queries/useQueryPremisesList";
import CustomDialog from "../shared/CustomDialog";
import { Loading } from "../shared/Loading";

const ListBills = (props) => {
  const {
    handleDownload,
    handleManualOk,
    handleSortBills,
    order,
    isRefetching,
    isSuperAdmin,
    handleShowOrder,
    bills,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  const { data: premises } = useQueryPremisesList();

  //POP UP confirm download pdf
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };
  const handleOpenConfirm = (id) => {
    setOpenConfirm(true);
    setSelectedId(id);
  };

  const handleConfirmDownload = () => {
    setOpenConfirm(false);
    handleDownload(selectedId);
    setSelectedId("");
  };
  //BUTTONS TO SORT THE BILLS
  const renderArrowButtons = (id) => {
    return (
      <ButtonGroup sx={{ fontSize: "5px" }}>
        <IconButton
          aria-label="sort"
          size="small"
          name={"asc"}
          onClick={() => handleSortBills(id, "asc")}
          className={order["asc"].includes(id) ? "oniria-btn-check-validated" : "oniria-btn-check"}
          sx={{ height: "20px", width: "20px" }}
        >
          <ExpandLess />
        </IconButton>
        <IconButton
          aria-label="sort"
          size="small"
          name={"desc"}
          onClick={() => handleSortBills(id, "desc")}
          className={order["desc"].includes(id) ? "oniria-btn-check-validated" : "oniria-btn-check"}
          sx={{ height: "20px", width: "20px" }}
        >
          <ExpandMore />
        </IconButton>
      </ButtonGroup>
    );
  };

  return (
    <Grid sx={{ marginBottom: 10 }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ borderBottom: "2px solid var(--secondary-color)" }}>
              <TableCell>
                <Grid container flexDirection="row" wrap="nowrap" alignItems="center" columnGap={1}>
                  {t("EMAIL")}
                  {renderArrowButtons("email")}
                </Grid>
              </TableCell>
              <TableCell>
                <Grid container flexDirection="row" wrap="nowrap" alignItems="center" columnGap={1}>
                  {t("EVENT")}
                  {renderArrowButtons("event_name")}
                </Grid>
              </TableCell>
              <TableCell>
                <Grid
                  container
                  flexDirection="row"
                  wrap="nowrap"
                  alignItems="center"
                  columnGap={1}
                  whiteSpace="nowrap"
                >
                  {t("DOMAIN")}
                </Grid>
              </TableCell>
              <TableCell>
                <Grid
                  container
                  flexDirection="row"
                  wrap="nowrap"
                  alignItems="center"
                  columnGap={1}
                  whiteSpace={"nowrap"}
                >
                  {t("PREMISE")}
                </Grid>
              </TableCell>
              <TableCell>
                <Grid
                  container
                  flexDirection="row"
                  wrap="nowrap"
                  alignItems="center"
                  columnGap={1}
                  whiteSpace={"nowrap"}
                >
                  Transaction id
                </Grid>
              </TableCell>
              <TableCell>
                <Grid
                  container
                  flexDirection="row"
                  wrap="nowrap"
                  alignItems="center"
                  columnGap={1}
                  whiteSpace={"nowrap"}
                >
                  {t("PAYMENT_METHOD")}
                </Grid>
              </TableCell>
              <TableCell>
                <Grid
                  container
                  flexDirection="row"
                  wrap="nowrap"
                  alignItems="center"
                  columnGap={1}
                  whiteSpace="nowrap"
                >
                  {t("PRICE")}
                  {renderArrowButtons("totalFactura")}
                </Grid>
              </TableCell>
              <TableCell>
                <Grid
                  container
                  flexDirection="row"
                  wrap="nowrap"
                  alignItems="center"
                  columnGap={1}
                  whiteSpace="nowrap"
                >
                  {t("EMISSION_DATE")}
                  {renderArrowButtons("fechaEmision")}
                </Grid>
              </TableCell>
            </TableRow>
          </TableHead>
          {isRefetching ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8} sx={{ border: "none" }}>
                  <Loading />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {bills?.map((bill, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography
                      className="oniria-colorText"
                      variant="subtitle2"
                      sx={{
                        fontWeight: bill.user_id && "bolder",
                        cursor: bill.user_id && "pointer",
                      }}
                      onClick={() => navigate(`${ROUTES.USERS}/${bill.user_id}`)}
                    >
                      {bill.email}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {" "}
                    <Typography
                      className="oniria-colorText"
                      variant="subtitle2"
                      sx={{
                        fontWeight: bill.event_id && "bolder",
                        cursor: bill.event_id && "pointer",
                      }}
                      onClick={() => navigate(`/event/${bill.event_id}`)}
                    >
                      {bill.event_name}
                    </Typography>
                  </TableCell>
                  <TableCell>{bill.bought_in_domain}</TableCell>
                  <TableCell>{getPremiseName(bill.premise_id, premises)}</TableCell>
                  <TableCell>
                    {bill.first_payment_transaction_id === null
                      ? bill.transaction_id
                      : compareDates(bill.first_payment_payed_at, bill.fechaEmision)
                      ? bill.first_payment_transaction_id
                      : bill.transaction_id}
                  </TableCell>
                  <TableCell>
                    {bill.first_payment_transaction_id === null
                      ? t(bill.payment_type?.toUpperCase())
                      : compareDates(bill.first_payment_payed_at, bill.fechaEmision)
                      ? t(bill.first_payment_type?.toUpperCase())
                      : t(bill.payment_type?.toUpperCase())}
                  </TableCell>
                  <TableCell>
                    {bill.first_payment_transaction_id === null
                      ? bill.amount
                      : compareDates(bill.first_payment_payed_at, bill.fechaEmision)
                      ? bill.first_payment_amount
                      : bill.amount}
                  </TableCell>
                  <TableCell>{bill.fechaEmision ? formatDate(bill.fechaEmision) : ""}</TableCell>
                  <TableCell>
                    <Grid container alignItems="center" flexWrap="nowrap" columnGap={1}>
                      <Tooltip title={t("SEE")}>
                        <IconButton
                          sx={{ borderRadius: "10px", color: "var(--secondary-color)" }}
                          onClick={() => handleShowOrder(bill)}
                        >
                          <Visibility />
                        </IconButton>
                      </Tooltip>
                      {/* DESCARGA FACTURAS */}
                      {bill.payment_type?.includes("timeout") ? (
                        <Tooltip title={t("MANUAL_OK")}>
                          <IconButton
                            onClick={() => handleManualOk(bill.order_id)}
                            sx={{ whiteSpace: "nowrap" }}
                          >
                            <Paid sx={{ color: "var(--secondary-color)" }} />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <>
                          {bill.normal && bill.normal.has_pdf === 1 && (
                            <Tooltip title={t("DOWNLOAD_BILL_PDF")}>
                              <IconButton
                                sx={{ color: "var(--secondary-color)" }}
                                onClick={() => {
                                  bill.normal.pdf_download
                                    ? handleOpenConfirm(bill.normal.invoice_id)
                                    : handleDownload(bill.normal.invoice_id);
                                }}
                              >
                                <FileDownload />
                                {bill.normal.pdf_download && <CheckIcon color="success" />}
                              </IconButton>
                            </Tooltip>
                          )}
                          {bill.commission && bill.commission.has_pdf === 1 && isSuperAdmin && (
                            <Tooltip title={t("DOWNLOAD_COMMISSION")}>
                              <IconButton
                                sx={{ color: "orange" }}
                                onClick={() => {
                                  bill.commission.pdf_download
                                    ? handleOpenConfirm(bill.commission.invoice_id)
                                    : handleDownload(bill.commission.invoice_id);
                                }}
                              >
                                <FileDownload style={{ color: "orange" }} />
                                {bill.commission.pdf_download && <CheckIcon color="success" />}
                              </IconButton>
                            </Tooltip>
                          )}
                          {!bill.normal && !bill.commission && <>{t("NO_BILLS")}</>}
                        </>
                      )}
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <CustomDialog
        isOpen={openConfirm}
        onClose={handleCloseConfirm}
        title={t("CONFIRM_BILL_DOWNLOAD")}
        content={t("CONFIRM_BILL_CONTENT")}
        onAccept={handleConfirmDownload}
      />
    </Grid>
  );
};

export default ListBills;
