import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { getHotelsTemplates } from "../../../services/hotelServices";

const useQueryHotelTemplates = (page, perPage, filters, selectedTab, handleSetHotels) => {
  return useQuery(
    [RQ_KEY.HOTELS_TEMPLATES, page, perPage, filters],
    () => getHotelsTemplates(page, perPage, filters),
    {
      refetchOnWindowFocus: false,
      enabled: selectedTab === 1,
      onSuccess: (data) => {
        handleSetHotels(data.data);
      },
    }
  );
};

export default useQueryHotelTemplates;
