import React from "react";
import { TextField } from "@mui/material";

export const FormField = ({
  label,
  value,
  onChange,
  error,
  helperText,
  type = "text",
  ...props
}) => (
  <TextField
    fullWidth
    label={label}
    value={value}
    onChange={onChange}
    error={!!error}
    helperText={error ? helperText : ""}
    type={type}
    {...props}
  />
);
