import { DeleteOutline, EditOutlined, RemoveRedEyeOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getPremiseName } from "../../constants/utils";
import OrderButtons from "../shared/OrderButtons";

const TourLeadersTable = ({
  data,
  filters,
  handleOrder,
  handleEdit,
  handleSee,
  handleOpenDelete,
  premises,
}) => {
  const { t } = useTranslation();

  return (
    <TableContainer component={Paper} sx={{ borderRadius: "20px", mb: 2 }}>
      <Table sx={{ whiteSpace: "nowrap" }}>
        <TableHead>
          <TableRow sx={{ borderBottom: "2px solid var(--secondary-color)" }}>
            <TableCell></TableCell>
            <TableCell>
              <OrderButtons
                columnName={t("NAME")}
                id="name"
                order={filters}
                handleClickAsc={handleOrder}
                handleClickDesc={handleOrder}
              />
            </TableCell>
            <TableCell>
              <OrderButtons
                columnName={t("PREMISE")}
                id="owner_premise_id"
                order={filters}
                handleClickAsc={handleOrder}
                handleClickDesc={handleOrder}
              />
            </TableCell>
            <TableCell>{t("GENRE")}</TableCell>
            <TableCell>{t("LANGUAGES")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, index) => (
            <TableRow
              key={`${row.user_id}-${index}`}
              sx={{
                backgroundColor: row.is_active === 0 ? "var(--grey-cancelled)" : "",
                whiteSpace: "nowrap",
              }}
            >
              <TableCell>
                <Avatar alt={row.name} src={row.avatar_url} />
              </TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{getPremiseName(row.owner_premise_id, premises)}</TableCell>
              <TableCell>{t(row.genre?.toUpperCase())}</TableCell>
              <TableCell>{row.languages?.split(";").join(", ")}</TableCell>
              <TableCell>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  {/* <Tooltip title={t("SEE_TOUR_LEADER")}>
                    <IconButton onClick={() => handleSee(row.user_id)}>
                      <RemoveRedEyeOutlined sx={{ color: "var(--oniria-gold)" }} />
                    </IconButton>
                  </Tooltip> */}
                  <Tooltip title={t("EDIT_TOUR_LEADER")}>
                    <IconButton onClick={() => handleEdit(row.user_id)}>
                      <EditOutlined sx={{ color: "var(--oniria-gold)" }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("DELETE_TOUR_LEADER")}>
                    <IconButton onClick={() => handleOpenDelete(row.user_id)}>
                      <DeleteOutline sx={{ color: "var(--oniria-gold)" }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TourLeadersTable;
