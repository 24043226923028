import { Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../../constants/utils";
import DataCard from "../events/statistics/DataCard";
import AgeGraph from "../events/statistics/graphs/AgeGraph";
import GenderGraph from "../events/statistics/graphs/GenderGraph";
import HourlyPurchasesGraph from "../events/statistics/graphs/HourlyPurchasesGraph";
import NationalityGraph from "../events/statistics/graphs/NationalityGraph";
import PaymentMethodGraph from "../events/statistics/graphs/PaymentMethodGraph";
import WeeklyPurchasesGraph from "../events/statistics/graphs/WeeklyPurchasesGraph";

const CardGraphs = ({ data }) => {
  const { t } = useTranslation();

  const {
    totalSales,
    totalPremiseCommissions,
    netIncome,
    numberOrders,
    numberRefundedOrders,
    salesByGender,
    ticketsByAge,
    assistantsByNationality,
    salesByPaymentMethod,
    purchasesByHour,
    purchasesByWeekDay,
  } = data;

  //CHECK ALL NULL NATIONALITIES
  const checkNullNationalities = () => {
    return assistantsByNationality?.some((element) => {
      return element.nationality !== null;
    });
  };

  //CHECK ALL GENDER NULL
  const checkNullGender = () => {
    return salesByGender.women != null || salesByGender.men != null || salesByGender.others != null;
  };

  const total = () => {
    let total =
      parseFloat(totalSales || "0") +
      parseFloat(totalPremiseCommissions || "0") +
      parseFloat(netIncome || "0");
    return formatCurrency(total);
  };

  return (
    <Grid container component={Paper} elevation={4} mb={20} borderRadius="15px" padding={2}>
      <Grid container justifyContent="space-between">
        <Typography
          className="oniria-colorText"
          sx={{ fontWeight: "bold", mb: 2, ml: 2 }}
          variant="h5"
        >
          {t("STATISTICS")}
        </Typography>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid item container>
          <DataCard
            value={total()}
            label={t("TOTAL_CARD_SALES")}
            icon_url="/media/homeIcons/sells.svg"
          />
          <DataCard
            value={`${formatCurrency(totalSales)}`}
            label={t("NET_INCOME")}
            icon_url="/media/homeIcons/dollar.svg"
          />
          {totalPremiseCommissions && totalPremiseCommissions > 0 ? (
            <DataCard
              value={`${formatCurrency(totalPremiseCommissions)}`}
              label={t("PREMISE_COMMISSIONS")}
              icon_url="/media/homeIcons/dollar.svg"
            />
          ) : null}
          <DataCard
            value={`${formatCurrency(netIncome)}`}
            label={t("COMMISSION_SERVICE")}
            icon_url="/media/homeIcons/sells.svg"
          />

          <DataCard
            value={`${numberOrders}`}
            label={t("ORDERS")}
            icon_url="/media/homeIcons/order.svg"
          />
          <DataCard
            value={`${numberRefundedOrders}`}
            label={t("REFUNDED_ORDERS")}
            icon_url="/media/homeIcons/refunded.svg"
          />
        </Grid>
        <Grid item container spacing={2}>
          {/* GENDER */}
          {checkNullGender() && (
            <Grid item container xs={12} lg={11.8} xl={6} rowGap={4} justifyContent="space-between">
              {salesByGender && <GenderGraph salesByGender={salesByGender} />}
              {/* NATIONAL */}
              {checkNullNationalities() && (
                <NationalityGraph assistantsByNationality={assistantsByNationality} />
              )}
            </Grid>
          )}
          {/* AGE */}
          {ticketsByAge && ticketsByAge.length > 0 ? (
            <Grid item container xs={12} lg={11.8} xl={6} rowGap={4} justifyContent="space-between">
              <AgeGraph ticketsByAge={ticketsByAge} />
            </Grid>
          ) : null}
          {/* NATIONAL */}
          {/* {checkNullNationalities() && (
            <Grid item container xs={12} lg={11.8} xl={6} rowGap={4} justifyContent="space-between">
              <NationalityGraph assistantsByNationality={assistantsByNationality} />
            </Grid>
          )} */}
          {/* PAYMENT METHOD */}
          {salesByPaymentMethod && salesByPaymentMethod.length > 0 ? (
            <Grid item container xs={12} lg={11.8} xl={6} rowGap={4} justifyContent="space-between">
              <PaymentMethodGraph salesByPaymentMethod={salesByPaymentMethod} />
            </Grid>
          ) : null}
          {/* PURCHASES BY HOUR */}
          {purchasesByHour && purchasesByHour.length > 0 ? (
            <Grid item container xs={12} lg={11.8} xl={6} rowGap={4} justifyContent="space-between">
              <HourlyPurchasesGraph salesByHour={purchasesByHour} />
            </Grid>
          ) : null}
          {/* PURCHASES BY WEEK DAY */}
          {purchasesByWeekDay && purchasesByWeekDay.length > 0 ? (
            <Grid item container xs={12} lg={11.8} xl={6} rowGap={4} justifyContent="space-between">
              <WeeklyPurchasesGraph salesByDay={purchasesByWeekDay} />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CardGraphs;
