import { CheckCircleOutline } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import {
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../constants/utils";
import { BLOG_STATUS } from "../../constants/variables";

const BlogTable = ({ data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleEdit = (e, id) => {
    e.stopPropagation();
    navigate(`/edit-post/${id}`);
  };

  console.log("DATA en blog table=>", data);

  return (
    <TableContainer component={Paper} sx={{ borderRadius: "20px", mb: 2 }}>
      <Table sx={{ whiteSpace: "nowrap" }}>
        <TableHead>
          <TableRow sx={{ borderBottom: "2px solid var(--secondary-color)" }}>
            <TableCell>{t("TITLE")}</TableCell>
            <TableCell>{t("SLUG")}</TableCell>
            <TableCell>{t("DATE")}</TableCell>
            <TableCell>{t("VIEWS")}</TableCell>
            <TableCell>{t("STATUS")}</TableCell>
            <TableCell>{t("ACTIONS")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                <Typography
                  variant="subtitle2"
                  // sx={{ fontWeight: "bolder", cursor: "pointer" }}
                  // onClick={() => navigate(`/blog/${row.id}`)}
                >
                  {row.title}
                </Typography>
              </TableCell>
              <TableCell>{row.slug ? `/${row.slug}` : ""}</TableCell>
              <TableCell>{formatDate(row.created_at)}</TableCell>
              <TableCell>{row.view_count}</TableCell>
              <TableCell>
                <Chip
                  label={t(row.status?.toUpperCase())}
                  color={row.status === BLOG_STATUS.PUBLISHED ? "success" : "warning"}
                  icon={<CheckCircleOutline sx={{ fontSize: "1.5rem" }} />}
                />
              </TableCell>
              <TableCell>
                <Tooltip title={t("EDIT_POST")}>
                  <IconButton onClick={(e) => handleEdit(e, row.id)}>
                    <EditIcon sx={{ color: "var(--secondary-color)" }} />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BlogTable;
