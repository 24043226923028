import React, { useCallback, useEffect, useRef, useState } from "react";
import { createReactEditorJS } from "react-editor-js";
import "./textEditor.css";
import { EDITOR_JS_TOOLS } from "./tools";

export default function TextEditor({ data, setData, id, disabled = false }) {
  const editorCore = useRef(null);
  const ReactEditorJS = createReactEditorJS();

  const checkedData = useCallback((editorData) => {
    if (typeof editorData === "string" && editorData !== "") {
      try {
        return JSON.parse(editorData);
      } catch (err) {
        console.error("Error parsing editor data", err);
        return null;
      }
    } else if (typeof editorData === "object" && editorData !== null) {
      return editorData;
    } else {
      return {
        blocks: [
          {
            type: "paragraph",
            data: {
              text: "",
            },
          },
        ],
      };
    }
  }, []);

  const [editorData, setEditorData] = useState(checkedData(data));

  const handleInitialize = useCallback(
    (instance) => {
      instance._editorJS.isReady
        .then(() => {
          editorCore.current = instance;
          if (editorData) {
            instance._editorJS.render(checkedData(editorData));
          }
        })
        .catch((err) => console.log("Error initializing editor-js", err));
    },
    [editorData, checkedData]
  );

  const handleSave = async () => {
    if (editorCore.current) {
      try {
        const savedData = await editorCore.current.save();
        setEditorData(savedData);
      } catch (err) {
        console.error("Error saving editor data", err);
      }
    } else {
      console.error("Editor is not initialized");
    }
  };

  useEffect(() => {
    setData(editorData);
  }, [editorData]);

  return (
    <div className="text-editor-container" id={`editorjs-${id}`}>
      <ReactEditorJS
        onInitialize={handleInitialize}
        tools={EDITOR_JS_TOOLS}
        onChange={handleSave}
        defaultValue={checkedData(editorData)}
        holder={`editorjs-${id}`}
        readOnly={disabled}
      />
    </div>
  );
}
