import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { getCitiesByRegion } from "../../../services/utilsServices";

export function useQueryCities(regionCode) {
  return useQuery([RQ_KEY.CITIES, regionCode], () => getCitiesByRegion(regionCode), {
    refetchOnWindowFocus: false,
    enabled: !!regionCode,
  });
}
