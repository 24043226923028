import jsPDF from "jspdf";
import { useTranslation } from "react-i18next";
import {
  getOrderAmount,
  getOrderCommission,
  getOrderPremiseCommission,
  getOrderTotalAmount,
} from "../constants/orders";
import { formatCurrency, formatDate } from "../constants/utils";
import useQueryOrdersEvent from "./queries/events/useQueryOrdersEvent";

const useDownloadOrders = (
  eventId,
  selectedDomain,
  search,
  order,
  selectedTicket,
  selectedStatus,
  enabledDownload
) => {
  const { t } = useTranslation();

  const { data: orders = [], isSuccess } = useQueryOrdersEvent(
    eventId,
    selectedDomain,
    "",
    "",
    search,
    order,
    selectedTicket,
    selectedStatus,
    enabledDownload
  );

  //CSV download
  const tableHeadersCsv = [
    t("NAME"),
    t("SURNAME"),
    t("TRANSACTION_ID"),
    t("DOMAIN"),
    t("TICKETS"),
    t("PAYMENT_DATE"),
    t("PAYMENT_TYPE"),
    t("AMOUNT"),
    t("COMMISSION"),
    t("PREMISE_COMMISSION"),
    t("TOTAL"),
  ];

  const uniqOrders = orders?.reduce(
    (acc, order) => {
      if (!acc.seen[order.order_id]) {
        acc.seen[order.order_id] = true;
        acc.result.push(order);
      }
      return acc;
    },
    { seen: {}, result: [] }
  ).result;

  const tableDataCsv = uniqOrders?.map((item) => [
    item.client_name ?? item.buyer_first_name ?? "",
    item.client_surname ?? item.buyer_last_name ?? "",
    item.transaction_id,
    item.bought_in_domain ?? "",
    item.tickets
      .map((ticket) => {
        return ticket.ticket_name + "\n";
      })
      .join("") ?? "",
    item.payed_at ? formatDate(item.payed_at) : t("PENDING_PAYMENT"),
    item.payment_type ? t(item?.payment_type?.toUpperCase()) : "",
    formatCurrency(getOrderAmount(item)),
    formatCurrency(getOrderCommission(item)),
    formatCurrency(getOrderPremiseCommission(item)),
    formatCurrency(getOrderTotalAmount(item)),
  ]);

  //PDF download
  const headersPdf = [
    t("NAME"),
    t("SURNAME"),
    t("TRANSACTION_ID"),
    t("DOMAIN"),
    t("TICKETS"),
    t("PAYMENT_DATE"),
    t("PAYMENT_TYPE"),
    t("TOTAL"),
  ];

  const tableDataPdf = orders?.map((item) => [
    item.client_name ?? item.buyer_first_name ?? "",
    item.client_surname ?? item.buyer_last_name ?? "",
    item.transaction_id,
    item.bought_in_domain ?? "",
    item.tickets
      .map((ticket) => {
        return ticket.ticket_name + "\n";
      })
      .join("") ?? "",
    item.payed_at ? formatDate(item.payed_at) : t("PENDING_PAYMENT"),
    item.payment_type ? t(item?.payment_type?.toUpperCase()) : "",
    formatCurrency(getOrderTotalAmount(item) || 0),
  ]);

  const generatePDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [headersPdf],
      body: tableDataPdf,
      styles: { fontSize: 10 },
      theme: "grid",
      margin: { top: 30 },
    });
    doc.save("orders.pdf");
  };

  return {
    generatePDF,
    tableHeadersCsv,
    tableDataCsv,
    isSuccess,
  };
};

export default useDownloadOrders;
