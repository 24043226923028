import { GridView, ViewList } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Button, Grid, IconButton, Pagination, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import CustomDialog from "../../components/shared/CustomDialog";
import { Loading } from "../../components/shared/Loading";
import SelectPerPage from "../../components/shared/SelectPerPage";
import { toastMessageError, toastMessageSuccess } from "../../components/shared/toastMessage";
import TransportsFilters from "../../components/transports/TransportsFilters";
import TransportsGrid from "../../components/transports/TransportsGrid";
import TransportsTable from "../../components/transports/TransportsTable";
import { ROUTES } from "../../constants/routes";
import { MUI_SELECT_PRIM_COLOR, PAGINATION_STYLES } from "../../constants/styles";
import useQueryTransports from "../../hooks/queries/transports/useQueryTransports";
import useQueryTripsTransports from "../../hooks/queries/transports/useQueryTripsTransports";
import useDebounce from "../../hooks/useDebouncing";
import usePagination from "../../hooks/usePagination";
import { deleteTransport } from "../../services/transportServices";
import { ASSIGNED_TAB } from "../../utils/hotels";

const TransportsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const breadcrumbs = [{ name: t("TRANSPORTS"), link: ROUTES.TRANSPORTS }];
  const [showTable, setShowTable] = useState(true);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [selectedTab, setSelectedTab] = useState(0); // 0 - Assigned, 1 - Templates
  const [transports, setTransports] = useState([]);
  const [filters, setFilters] = useState({
    search: "",
    asc: [],
    desc: ["created_at"],
    startPoint: "all",
    endPoint: "all",
    transit: "all",
  });

  const { page, perPage, changePage, changePerPage } = usePagination();

  const handleSetTransports = (data) => {
    setTransports(data?.data);
  };

  const {
    data: dataAssigned,
    isLoading,
    error,
    refetch,
  } = useQueryTripsTransports(page, perPage, filters, selectedTab, handleSetTransports);

  const {
    data: dataStandard,
    isLoading: isLoadingStandard,
    error: errorStandard,
    refetch: refetchStandard,
  } = useQueryTransports(page, perPage, filters, selectedTab, handleSetTransports);

  const totalTransports = selectedTab === ASSIGNED_TAB ? dataAssigned?.total : dataStandard?.total;

  const updateFilter = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    changePage(null, 1);
  };

  const handleSearchChange = (event) => {
    updateFilter("search", event.target.value);
    changePage(null, 1);
  };

  const debounceSearch = useDebounce(handleSearchChange, 300);

  const handleCreate = () => {
    const route =
      selectedTab === ASSIGNED_TAB ? ROUTES.CREATE_TRIP_TRANSPORT : ROUTES.CREATE_TRANSPORT;
    navigate(route);
  };

  const handleEdit = (id) => {
    const route =
      selectedTab === ASSIGNED_TAB ? "/transports/edit_trip_transport" : "/transports/edit";
    navigate(`${route}/${id}`);
  };

  const handleOpenDelete = (id) => {
    setSelectedId(id);
    setOpenConfirm(true);
  };

  const handleSee = (id) => {};

  const handleConfirmDelete = async () => {
    try {
      await deleteTransport(selectedId, selectedTab);
      toastMessageSuccess(t("DELETED_SUCCESSFULLY"));
    } catch (error) {
      toastMessageError(error?.response?.data?.message || t("ERROR"));
    } finally {
      setSelectedId("");
      if (selectedTab === ASSIGNED_TAB) {
        refetch();
      } else {
        refetchStandard();
      }

      setOpenConfirm(false);
    }
  };

  const handleChangeTab = (_, newValue) => {
    setSelectedTab(newValue);
    changePage(null, 1);
  };

  const numberPages = Math.ceil(parseFloat(totalTransports) / parseFloat(perPage));

  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t("TRANSPORTS_PAGE_DESCRIPTION")} />
      <Grid container justifyContent="flex-start" alignItems="center" mb={2} mt={2}>
        <Tabs
          value={selectedTab}
          onChange={handleChangeTab}
          TabIndicatorProps={{
            style: { backgroundColor: "var(--secondary-color)" },
          }}
          sx={{
            mb: 2,
            color: "var(--secondary-color)",
          }}
        >
          <Tab
            label={t("ASSIGNED_TRANSPORTS")}
            sx={[MUI_SELECT_PRIM_COLOR, { textTransform: "initial" }]}
          />
          <Tab
            label={t("STANDARD_TRANSPORTS")}
            sx={[MUI_SELECT_PRIM_COLOR, { textTransform: "initial" }]}
          />
        </Tabs>
      </Grid>
      <TransportsFilters
        onSearchChange={debounceSearch}
        filters={filters}
        updateFilter={updateFilter}
        selectedTab={selectedTab}
      />
      <Grid container justifyContent="flex-end" alignItems="center" mb={2} mt={2}>
        <Button
          variant="contained"
          className="oniria-btn"
          startIcon={<AddIcon />}
          onClick={handleCreate}
        >
          {t("CREATE_TRANSPORT")} {selectedTab === ASSIGNED_TAB ? t("FOR_TRIPS") : t("STANDARD")}
        </Button>
      </Grid>
      {isLoading || isLoadingStandard ? (
        <Loading />
      ) : error || errorStandard ? (
        <p>{t("ERROR_LOADING_DATA")}</p>
      ) : totalTransports > 0 ? (
        <div style={{ marginBottom: "20px" }}>
          <Grid
            item
            xs={11}
            sm={12}
            container
            justifyContent={{ xs: "center", sm: "space-between" }}
            alignItems={"center"}
            pb={2}
            gap={{ xs: 2, sm: 0 }}
          >
            <Typography>
              {t("TOTAL_TRANSPORTS")}: {totalTransports}
            </Typography>
            <Grid container item xs={12} md={8} justifyContent="flex-end">
              {numberPages >= 1 && (
                <Grid item xs={12} md={8} container justifyContent="flex-end">
                  <Grid item>
                    <SelectPerPage text={t("TRANSPORTS")} change={changePerPage} value={perPage} />
                  </Grid>
                  <Pagination
                    count={numberPages}
                    mr={3}
                    sx={PAGINATION_STYLES}
                    page={page}
                    onChange={changePage}
                  />
                </Grid>
              )}
              <Grid>
                <IconButton onClick={() => setShowTable(true)}>
                  <ViewList />
                </IconButton>
                <IconButton onClick={() => setShowTable(false)}>
                  <GridView />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          {showTable ? (
            <TransportsTable
              data={transports}
              handleEdit={handleEdit}
              handleSee={handleSee}
              handleOpenDelete={handleOpenDelete}
            />
          ) : (
            <TransportsGrid
              data={transports}
              handleEdit={handleEdit}
              handleSee={handleSee}
              handleOpenDelete={handleOpenDelete}
            />
          )}
          {numberPages >= 1 && (
            <Grid container justifyContent="center">
              <Pagination
                count={numberPages}
                mr={3}
                sx={PAGINATION_STYLES}
                page={page}
                onChange={changePage}
              />
            </Grid>
          )}
        </div>
      ) : (
        <p>{t("NO_TRANSPORTS_FOUND")}</p>
      )}
      <CustomDialog
        isOpen={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title={t("DELETE_TRANSPORT")}
        content={t("CONFIRM_DELETE_TRANSPORT")}
        onAccept={handleConfirmDelete}
      />
    </>
  );
};

export default TransportsPage;
