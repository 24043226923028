import CloseIcon from "@mui/icons-material/Close";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getAssistantDetail } from "../../../services/eventsServices";
import Scanner from "../../shared/QrReader";
import { customToastMessage } from "../../shared/toastMessage";
import ToastTicketInfo from "./ToastTicketInfo";

const ScannerAssistant = ({
  eventId,
  goBack,
  eventName,
  totalTickets,
  consumedTickets,
  isMobileButton,
  setQrResponse,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openCheck, setOpenCheck] = useState(false);
  const [assistant, setAssistant] = useState([]);

  const handleGoTicket = (ticketId) => {
    setOpenCheck(false);
    navigate(`/event/${eventId}?newValueTab=1&ticket=${ticketId}`);
  };

  const scanValues = {};

  const handleQrValue = useCallback(
    (value) => {
      // Process the QR value
      if (value.includes(";")) {
        const qrValue = value.split(";");
        for (const segment of qrValue) {
          const [key, segmentValue] = segment.split("=");
          if (key && segmentValue) {
            scanValues[key] = segmentValue;
          }
        }
        //Ticket not belongs to the event
        if (scanValues.event_id !== eventId) {
          customToastMessage(
            <ToastTicketInfo
              icon={<CloseIcon />}
              title={t("INVALID_TICKET")}
              message={t("ALREADY_VALIDATED")}
            />,
            "var(--red-error)"
          );
        } else {
          getAssitantData(scanValues.order_ticket_id);
        }
      } else {
        getAssitantData(value);
      }
    },
    [eventId]
  );

  async function getAssitantData(ticketId) {
    try {
      const response = await getAssistantDetail(ticketId, eventId);
      if (response[0].extras.every((extra) => extra.consumed_at) && response[0].consumed_at) {
        // Ticket already validated
        customToastMessage(
          <ToastTicketInfo
            icon={<CloseIcon />}
            title={t("INVALID_TICKET")}
            message={t("ALREADY_VALIDATED")}
            clickButton={() => handleGoTicket(ticketId)}
          />,
          "var(--red-error)"
        );
      } else {
        setAssistant(response[0]);
        setOpenCheck(true);
      }
      setQrResponse("");
    } catch (error) {
      const errorMessage = error?.response?.data?.error || t("TICKET_ERROR");
      customToastMessage(
        <ToastTicketInfo icon={<CloseIcon />} title={t("INVALID_TICKET")} message={errorMessage} />,
        "var(--red-error)"
      );
    }
  }

  return (
    <Scanner
      onResult={handleQrValue}
      setQrResponse={setQrResponse}
      goBack={goBack}
      eventName={eventName}
      totalTickets={totalTickets}
      consumedTickets={consumedTickets}
      isMobileButton={isMobileButton}
      openCheck={openCheck}
      assistant={assistant}
      setOpenCheck={setOpenCheck}
      eventId={eventId}
    />
  );
};

export default ScannerAssistant;
