import { Grid } from "@mui/material";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import EmailEditor from "react-email-editor";
import { Loading } from "../shared/Loading";

const EmailsEditor = forwardRef(({ initialDesign, onExportHtml, onDesignChange }, ref) => {
  const [isLoading, setIsLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);
  const editorRef = useRef(null);

  const exportDesign = () => {
    if (editorRef.current) {
      // Export the design
      editorRef.current.editor.saveDesign((design) => {
        if (onDesignChange) onDesignChange(design);
      });
      // Export the HTML
      editorRef.current.editor.exportHtml((data) => {
        if (onExportHtml) {
          onExportHtml(data.html || "");
        }
      });
    }
  };

  const onLoad = () => {
    if (initialDesign && editorRef.current && editorRef.current.editor) {
      editorRef.current.editor.loadDesign(initialDesign);
    }
  };

  const onReady = () => {
    setIsLoading(false);
    if (editorRef.current) {
      editorRef.current.editor?.addEventListener("design:updated", exportDesign);
    }
    if (initialDesign && editorRef.current) {
      onLoad();
    }
  };

  useEffect(() => {
    if (editorRef.current && initialDesign && editorRef.current.editor && firstLoad) {
      editorRef.current.editor.loadDesign(initialDesign);
      setFirstLoad(false);
    }
  }, [initialDesign, firstLoad]);

  useEffect(() => {
    return () => {
      if (editorRef.current) {
        editorRef.current.editor?.removeEventListener("design:updated", exportDesign);
      }
    };
  }, []);

  return (
    <Grid item xs={12} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      {isLoading && <Loading />}
      <div style={{ flex: 1 }}>
        <EmailEditor ref={editorRef} onLoad={onLoad} onReady={onReady} />
      </div>
    </Grid>
  );
});

export default EmailsEditor;
