import React, { useState } from 'react';
import {
	Grid,
	TextField,
	Typography,
	Card,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';

import Header from '../components/Header';
import { ROUTES } from '../constants/routes';
import './styles.css';
import wikiBlissData from '../constants/wikibliss.json'; // Importa el JSON completo

const Wikibliss = () => {
	const { t } = useTranslation();
	const [searchTerm, setSearchTerm] = useState('');

	const breadcrumbs = [
		{
			name: t('WIKIBLISS'),
			link: ROUTES.WIKIBLISS,
		},
	];

	// Filtrar las tarjetas según el término de búsqueda
	const filteredWikiBliss = wikiBlissData.wikiBliss.filter(item =>
		item.titulo.toLowerCase().includes(searchTerm.toLowerCase())
	);

	return (
		<>
			<Header breadcrumbs={breadcrumbs} />

			<Grid container spacing={2} columns={16}>
				{/* Barra de búsqueda */}
				<Grid item xs={16} sx={{ marginBottom: 2 }}>
					<TextField
						fullWidth
						label={t('Search aqui')}
						variant="outlined"
						value={searchTerm}
						onChange={e => setSearchTerm(e.target.value)}
					/>
				</Grid>

				{/* Mapeo de las tarjetas */}
				{filteredWikiBliss.map((item, index) => (
					<Grid item xs={16} key={index}>
						{/* Acordeón para cada ítem */}
						<Accordion>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls={`panel-content-${index}`}
								id={`panel-header-${index}`}>
								<Typography variant="h6">{item.titulo}</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Typography
									variant="body2"
									dangerouslySetInnerHTML={{ __html: item.descripcion }}
								/>
							</AccordionDetails>
						</Accordion>
					</Grid>
				))}
			</Grid>
		</>
	);
};

export default Wikibliss;
