import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { getTourPoints } from "../../../services/tourPoints";

const useQueryTourPoints = (page, perPage, filters) => {
  return useQuery(
    [RQ_KEY.TOUR_POINTS, page, perPage, filters],
    () => getTourPoints(page, perPage, filters),
    {
      refetchOnWindowFocus: false,
    }
  );
};

export default useQueryTourPoints;
