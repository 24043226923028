import { CloseOutlined } from "@mui/icons-material";
import { Grid, IconButton, MenuItem, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import LanguageSelect from "../../shared/languages/LanguageSelect";
import LanguageTabs from "../../shared/languages/LanguageTabs";
import { focusColor } from "../../shared/textFieldStyle";
import { DEFAULT_LANGUAGE, PENSION_OPTIONS } from "../../../constants/variables";
import SelectHookForm from "../../shared/SelectHookForm";
import TextEditor from "../../textEditor/TextEditor";
import { useFormContext } from "react-hook-form";
import { useState } from "react";

const HotelRoomForm = ({
  room,
  index,
  handleDeleteRoom,
  handleChangeCheckbox,
  handleChangeDescription,
  handleChangeLanguageDescription,
  getRoomLanguageIndex,
}) => {
  const { t } = useTranslation();
  const {
    register,
    watch,
    formState: { errors },
    control,
  } = useFormContext();
  const [languageTab, setLanguageTab] = useState(DEFAULT_LANGUAGE);

  return (
    <Grid
      item
      xs={12}
      container
      key={index}
      sx={{ border: "1px solid var(--field-grey)", borderRadius: 3, p: 2, mb: 2 }}
    >
      <Grid item xs={12} container justifyContent="flex-end">
        <IconButton onClick={() => handleDeleteRoom(index)}>
          <CloseOutlined />
        </IconButton>
      </Grid>
      <Grid item xs={12} mb={2}>
        <LanguageSelect
          translatedFields={room.hotel_room_language_infos}
          handleChangeCheckbox={(isChecked, value) => handleChangeCheckbox(isChecked, value, index)}
        />
        {room.hotel_room_language_infos?.length > 0 && (
          <LanguageTabs
            languagesInfo={room.hotel_room_language_infos || []}
            selectedLanguage={languageTab}
            setSelectedLanguage={setLanguageTab}
          />
        )}
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12} sm={2}>
          <Typography>{t("AVAILABLE_QUANTITY")}</Typography>
          <TextField
            placeholder={t("0")}
            {...register(`rooms[${index}].stock`)}
            error={!!errors.rooms?.[index]?.stock}
            helperText={errors.rooms?.[index]?.stock?.message}
            fullWidth
            sx={focusColor}
            type="number"
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography>{t("ROOM_NAME")}</Typography>
          {languageTab === DEFAULT_LANGUAGE ? (
            <TextField
              placeholder={t("NAME")}
              {...register(`rooms[${index}].name`)}
              error={!!errors.rooms?.[index]?.name}
              helperText={errors.rooms?.[index]?.name?.message}
              fullWidth
              sx={focusColor}
            />
          ) : (
            <TextField
              placeholder={t("NAME")}
              {...register(
                `rooms[${index}].hotel_room_language_infos[${getRoomLanguageIndex(
                  index,
                  languageTab
                )}].name`
              )}
              error={
                !!errors.rooms?.[index]?.hotel_room_language_infos?.[
                  getRoomLanguageIndex(index, languageTab)
                ]?.name
              }
              helperText={
                errors.rooms?.[index]?.hotel_room_language_infos?.[
                  getRoomLanguageIndex(index, languageTab)
                ]?.name?.message
              }
              fullWidth
              sx={focusColor}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography>{t("PERSONS_LIMIT")}</Typography>
          <TextField
            placeholder={t("00")}
            {...register(`rooms[${index}].beds_amount`)}
            error={!!errors.rooms?.[index]?.beds_amount}
            helperText={errors.rooms?.[index]?.beds_amount?.message}
            fullWidth
            sx={focusColor}
            type="number"
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography>{t("PENSION")}</Typography>
          <SelectHookForm
            name={`rooms[${index}].pension`}
            control={control}
            label={t("PENSION")}
            error={errors.rooms?.[index]?.pension}
            options={PENSION_OPTIONS.map((opt) => (
              <MenuItem key={opt.id} value={opt.id}>
                {t(opt.name)}
              </MenuItem>
            ))}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography>{t("PRICE")}</Typography>
          <TextField
            placeholder={t("PRICE")}
            {...register(`rooms[${index}].price`)}
            error={!!errors.rooms?.[index]?.price}
            helperText={errors.rooms?.[index]?.price?.message}
            fullWidth
            sx={focusColor}
            type="number"
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>{t("DESCRIPTION")}</Typography>
          <>
            {languageTab === DEFAULT_LANGUAGE ? (
              <TextEditor
                data={watch(`rooms[${index}].description`)}
                id={`hotel_room_description_${index}`}
                setData={(data) => handleChangeDescription(data, index)}
              />
            ) : (
              <>
                <TextEditor
                  data={watch(
                    `rooms[${index}].hotel_room_language_infos[${getRoomLanguageIndex(
                      index,
                      languageTab
                    )}].description`
                  )}
                  id={`hotel_room_description_${index}_${languageTab}`}
                  setData={(data) => handleChangeLanguageDescription(data, index, languageTab)}
                />
              </>
            )}
          </>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HotelRoomForm;
