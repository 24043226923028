import { ArrowBack } from "@mui/icons-material";
import {
  Grid,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { PAGINATION_STYLES } from "../../constants/styles";
import { formatCurrency } from "../../constants/utils";
import useQueryExtrasStatistics from "../../hooks/queries/extras/useQueryExtraStatistics";
import { useQueryDomains } from "../../hooks/queries/useQueryDomains";
import useDebounce from "../../hooks/useDebouncing";
import usePagination from "../../hooks/usePagination";
import SearchFilter from "../events/filters/filter/SearchFilter";
import SelectFilter from "../events/filters/filter/SelectFilter";
import { Loading } from "../shared/Loading";
import OrderButtons from "../shared/OrderButtons";
import SelectPerPage from "../shared/SelectPerPage";

const ExtraStatistics = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    search: "",
    domainId: "all",
    asc: [],
    desc: [],
    date: "all",
  });
  const { page, perPage, changePage, changePerPage } = usePagination();

  const { isLoading, error, data = {} } = useQueryExtrasStatistics(id, page, perPage, filters);
  const { data: extras = [], amount } = data;
  const { data: domains = [] } = useQueryDomains();

  const domainsFormat = domains.map((domain) => ({
    id: domain.domain_name,
    name: domain.domain_name,
  }));

  const handleGoBack = () => {
    navigate(ROUTES.EXTRAS);
  };

  const onSearchChange = (e) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      search: e.target.value,
    }));
  };

  const debounceSearch = useDebounce(onSearchChange, 300);

  const updateDomainFilter = (value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      domainId: value,
    }));
  };

  const updateDateFilter = (value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      date: value,
    }));
  };

  const handleOrder = (id, type) => {
    const oppositeType = type === "asc" ? "desc" : "asc";
    const newFilters = { ...filters };
    if (newFilters[type]?.includes(id)) {
      newFilters[type] = newFilters[type].filter((item) => item !== id);
    } else {
      newFilters[type].push(id);
      newFilters[oppositeType] = newFilters[oppositeType]?.filter((item) => item !== id);
    }
    setFilters(newFilters);
  };

  const handleSeeEvent = (e, id) => {
    e.preventDefault();
    navigate(`/event/${id}`);
  };

  const dateOptions = [
    { id: "all", name: t("ALL_DATES") },
    { id: "pasts", name: t("PAST") },
    { id: "upcoming", name: t("UPCOMING") },
  ];

  const totalPages = Math.ceil(amount / perPage);

  return (
    <Grid mt={7} mb={5}>
      <IconButton onClick={handleGoBack}>
        <ArrowBack />
      </IconButton>
      <Grid container justifyContent="center" mb={5}>
        <Typography variant="h5">{t("EXTRA_SALES")}</Typography>
      </Grid>
      {/* FILTERS */}
      <Grid container justifyContent="space-between" mb={4}>
        <Grid item xs={12} md={6}>
          <SearchFilter onSearchChange={debounceSearch} />
        </Grid>
        <Grid item xs={12} md={6} container justifyContent="flex-end" gap={1}>
          <SelectFilter
            value={filters.date || ""}
            onChange={updateDateFilter}
            options={dateOptions}
          />
          <SelectFilter
            value={filters.domainId || ""}
            onChange={updateDomainFilter}
            options={[{ id: "all", name: t("ALL_DOMAINS") }, ...domainsFormat]}
          />
        </Grid>
      </Grid>
      {isLoading ? (
        <Loading />
      ) : error ? (
        <Typography>{t("ERROR_FETCHING_DATA")}</Typography>
      ) : (
        <Grid container>
          <Grid container justifyContent="space-between" alignItems="center" mb={2}>
            <Typography sx={{ mb: 2 }}>
              {t("TOTAL_EVENTS_ASSIGNED")} : {amount}
            </Typography>
            <Grid item container xs={6} justifyContent="flex-end" alignItems="center">
              <Grid item>
                <SelectPerPage
                  text={t("EVENTS")}
                  change={changePerPage}
                  value={perPage}
                  total={data?.length}
                />
              </Grid>
              <Pagination
                count={totalPages}
                page={page}
                onChange={changePage}
                sx={PAGINATION_STYLES}
              />
            </Grid>
          </Grid>
          <TableContainer component={Paper} sx={{ borderRadius: "20px", mb: 2 }}>
            <Table sx={{ whiteSpace: "nowrap" }}>
              <TableHead>
                <TableRow sx={{ borderBottom: "2px solid var(--secondary-color)" }}>
                  <TableCell>
                    <OrderButtons
                      columnName={t("EVENT")}
                      id="event_name"
                      order={filters}
                      handleClickAsc={handleOrder}
                      handleClickDesc={handleOrder}
                    />
                  </TableCell>
                  <TableCell>
                    <OrderButtons
                      columnName={t("TOTAL_SALES")}
                      id="count"
                      order={filters}
                      handleClickAsc={handleOrder}
                      handleClickDesc={handleOrder}
                    />
                  </TableCell>
                  <TableCell>
                    <OrderButtons
                      columnName={t("TOTAL_AMOUNT")}
                      id="amount"
                      order={filters}
                      handleClickAsc={handleOrder}
                      handleClickDesc={handleOrder}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {extras?.map((row, index) => (
                  <Fragment key={`${row.id}-${index}`}>
                    <TableRow>
                      <TableCell
                        sx={{ fontWeight: "bold", cursor: "pointer" }}
                        onClick={(e) => handleSeeEvent(e, row.event_id)}
                      >
                        {row.event_name}
                      </TableCell>
                      <TableCell>{row.count}</TableCell>
                      <TableCell>{formatCurrency(row.amount)}</TableCell>
                    </TableRow>
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container justifyContent="center" mt={2}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={changePage}
              sx={PAGINATION_STYLES}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ExtraStatistics;
