import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowBack } from "@mui/icons-material";
import {
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import useQueryTourLeaderDetails from "../../hooks/queries/tourLeaders/useQueryTourLeaderDetails";
import { useQueryDomains } from "../../hooks/queries/useQueryDomains";
import { useQueryPremisesList } from "../../hooks/queries/useQueryPremisesList";
import { tourLeaderSchema } from "../../schemas/tourLeaders";
import {
  assignDomainsToTourLeader,
  createTourLeader,
  editTourLeader,
} from "../../services/tourLeaders";
import { prepareTourLeaderToReceive, prepareTourLeaderToSend } from "../../utils/tourLeader";
import { handleSelectImageReactHook } from "../shared/FormsValidator";
import ImageUpload from "../shared/ImageUpload";
import { Loading } from "../shared/Loading";
import SelectHookForm from "../shared/SelectHookForm";
import { focusColor } from "../shared/textFieldStyle";
import { toastMessageError, toastMessageSuccess } from "../shared/toastMessage";
import TourLeaderAddLanguages from "./TourLeaderAddLanguages";

const TourLeaderForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const { data: premises = [] } = useQueryPremisesList();
  const { data: domains = [] } = useQueryDomains();

  const formMethods = useForm({
    resolver: zodResolver(tourLeaderSchema),
    mode: "onBlur",
  });

  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
    control,
    setValue,
    watch,
  } = formMethods;

  const handleSetFormData = (data) => {
    const newData = prepareTourLeaderToReceive(data);
    reset(newData);
  };

  const { isLoading, isRefetching } = useQueryTourLeaderDetails(id, handleSetFormData);

  const onSubmit = async (data) => {
    setLoadingSubmit(true);
    try {
      const bodyToSend = await prepareTourLeaderToSend(data);
      if (id) {
        // EDIT
        await editTourLeader(id, bodyToSend);
        if (bodyToSend.domains_ids && bodyToSend.domains_ids.length > 0) {
          await assignDomainsToTourLeader(bodyToSend.tour_leader_id, bodyToSend.domains_ids);
        }
      } else {
        // NEW
        const response = await createTourLeader(bodyToSend);
        if (response.id && bodyToSend.domains_ids && bodyToSend.domains_ids.length > 0) {
          await assignDomainsToTourLeader(response.id, bodyToSend.domains_ids);
        }
      }
      setTimeout(() => {
        handleGoBack();
      }, 1500);
      toastMessageSuccess(t("CREATED_SUCCESSFULLY"));
    } catch (e) {
      toastMessageError(e?.response?.data?.error || t("ERROR"));
    } finally {
      setLoadingSubmit(false);
    }
  };

  const onError = (errors) => {
    console.log("Errores formulario tour leader", errors);
  };

  const handleGoBack = () => {
    navigate(ROUTES.TOUR_LEADERS);
  };

  return (
    <Grid mt={7} mb={5}>
      <IconButton onClick={handleGoBack}>
        <ArrowBack />
      </IconButton>
      <Grid container justifyContent="center" mb={5}>
        <Typography variant="h5">
          {!!id ? t("EDIT") : t("CREATE")} {t("TOUR_LEADER")}
        </Typography>
      </Grid>
      {isLoading || isRefetching ? (
        <Loading />
      ) : (
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>{t("NAME")}</Typography>
                <TextField
                  {...register("name")}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  fullWidth
                  sx={focusColor}
                  placeholder={t("NAME")}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>{t("SURNAME")}</Typography>
                <TextField
                  placeholder={t("SURNAME")}
                  {...register("surname")}
                  error={!!errors?.surname}
                  helperText={errors.surname?.message}
                  fullWidth
                  sx={focusColor}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>{t("BORN_AT")}</Typography>
                <Controller
                  name="born_at"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="date"
                      {...field}
                      error={!!errors?.born_at}
                      helperText={errors.born_at?.message || ""}
                      fullWidth
                      inputProps={{
                        max: new Date().toISOString().split("T")[0],
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={focusColor}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>{t("GENDER")}</Typography>
                <Controller
                  name="genre"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="male"
                        control={<Radio className="radio-oniria" />}
                        label={t("MALE")}
                      />
                      <FormControlLabel
                        value="female"
                        control={<Radio className="radio-oniria" />}
                        label={t("FEMALE")}
                      />
                      <FormControlLabel
                        value="other"
                        control={<Radio className="radio-oniria" />}
                        label={t("OTHER")}
                      />
                    </RadioGroup>
                  )}
                />
                {errors?.gender && (
                  <Typography color="error" variant="caption">
                    {errors.gender?.message}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography>{t("EMAIL")}</Typography>
                <TextField
                  placeholder={t("EMAIL")}
                  {...register("email")}
                  error={!!errors?.email}
                  helperText={errors.email?.message}
                  fullWidth
                  sx={focusColor}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>{t("PHONE")}</Typography>
                <TextField
                  placeholder={t("PHONE")}
                  {...register("phone")}
                  error={!!errors?.phone}
                  helperText={errors.phone?.message}
                  fullWidth
                  sx={focusColor}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>{t("AVATAR_OPTIONAL_IMAGE")}</Typography>
                <ImageUpload
                  onImageSelect={(e) => handleSelectImageReactHook(e, "avatar_url", setValue)}
                  url={watch("avatar_url")}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectHookForm
                  name={"domains_ids"}
                  control={control}
                  label={t("DOMAINS")}
                  error={errors.domains_ids}
                  multiple
                  defaultValue={[]}
                  options={domains.map((domain) => (
                    <MenuItem key={domain.domain_id} value={domain.domain_id}>
                      {domain.domain_name}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectHookForm
                  name={"owner_premise_id"}
                  control={control}
                  label={t("PREMISE")}
                  error={errors.owner_premise_id}
                  options={premises.map((premise) => (
                    <MenuItem key={premise.id} value={premise.id}>
                      {premise.name}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <TourLeaderAddLanguages />
              <Grid item xs={12} container justifyContent="center" gap={2}>
                <Button
                  onClick={handleGoBack}
                  className="oniria-btn-cancel"
                  variant="contained"
                  size="medium"
                  sx={{ fontSize: "12px", borderRadius: 3 }}
                >
                  {t("CANCEL")}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className="oniria-btn"
                  disabled={loadingSubmit}
                  sx={{ fontSize: "12px", borderRadius: 3 }}
                >
                  {loadingSubmit ? t("SAVING") : t("SAVE")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      )}
    </Grid>
  );
};

export default TourLeaderForm;
