import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useQueryExtras from "../../../hooks/queries/extras/useQueryExtras";
import InfoToolTip from "../../shared/InfoToolTip";
import { focusColor } from "../../shared/textFieldStyle";
import { useQueryAssignDomains } from "../../../hooks/queries/useQueryAssignDomains";

function ExtraTemplateDialog({ isOpen, onClose, onAccept, event_categories }) {
  const { t } = useTranslation();
  const [selectedExtra, setSelectedExtra] = useState("");
  const [useTemplate, setUseTemplate] = useState(false);
  const { data: domainCategories } = useQueryAssignDomains();

  const isDomainChecked = (domain) => {
    return domain.categories.some(
      (category) =>
        event_categories?.some(
          (selectedCategory) => selectedCategory.category_id === category.id
        ) ||
        category?.subcategories.some((subcategory) =>
          event_categories?.some(
            (selectedCategory) => selectedCategory.subcategory_id === subcategory.id
          )
        )
    );
  };

  const domains_ids = domainCategories
    ?.filter((domain) => isDomainChecked(domain))
    .map((domain) => {
      return domain.id;
    });

  const { data: extras = [] } = useQueryExtras(
    "",
    "",
    {
      domains_ids: domains_ids,
    },
    isOpen
  );

  const handleClose = () => {
    setSelectedExtra("");
    setUseTemplate(false);
    onClose();
  };

  const handleAccept = () => {
    if (!selectedExtra) return;
    const extraInfo = extras.find((extra) => extra.id === selectedExtra);
    const newExtra = JSON.parse(JSON.stringify(extraInfo));
    newExtra.id = useTemplate ? newExtra.id : "";
    onAccept(newExtra);
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ fontWeight: "bolder", color: "var(--oniria-red)", textAlign: "center" }}>
        {t("ADD_EXTRA_FROM_TEMPLATE")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ color: "black", textAlign: "center" }}>
          {t("ADD_EXTRA_FROM_TEMPLATE_DESCRIPTION")}
        </DialogContentText>
        <FormControl sx={[focusColor, { marginTop: 2 }]} fullWidth>
          <InputLabel>{t("EXTRAS")}</InputLabel>
          <Select
            value={selectedExtra}
            onChange={(e) => setSelectedExtra(e.target.value)}
            label={t("EXTRAS")}
          >
            {extras?.map((extra) => (
              <MenuItem key={extra.id} value={extra.id}>
                {extra.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Grid sx={{ mt: 2, display: "flex", flexDirection: "row", alignItems: "center" }}>
          <FormControlLabel
            control={
              <Checkbox
                name="useTemplate"
                checked={useTemplate}
                value={useTemplate}
                onChange={(e) => setUseTemplate(e.target.checked)}
                className="checkbox-oniria"
              />
            }
            label={t("LINK_TEMPLATE")}
          />
          <InfoToolTip text={t("LINK_TEMPLATE_INFO")} />
        </Grid>
      </DialogContent>
      <DialogActions sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
        <Button
          type="submit"
          className="oniria-btn-cancel"
          variant="contained"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          onClick={onClose}
        >
          {t("CANCEL")}
        </Button>
        <Button
          type="submit"
          className="oniria-btn"
          variant="contained"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          onClick={handleAccept}
        >
          {t("ACCEPT")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ExtraTemplateDialog;
