import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { focusColor } from "../shared/textFieldStyle";
import { PAYMENT_OPTIONS } from "../../constants/variables";
import { finishOrderPayment } from "../../services/orderServices";
import { toastMessageError, toastMessageSuccess } from "../shared/toastMessage";

function CompletePaymentDialog({ isOpen, onClose, order, refetch }) {
  const { t } = useTranslation();
  const [paymentType, setPaymentType] = useState("");
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    try {
      setLoading(true);
      await finishOrderPayment(order.order_id, {
        paymentType: paymentType,
      });
      toastMessageSuccess(t("PAYMENT_SUCCESS"));
    } catch (error) {
      console.log("error", error);
      toastMessageError(error?.response?.data?.error || t("ERROR"));
    } finally {
      onClose();
      setLoading(false);
      refetch();
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ fontWeight: "bolder", color: "var(--oniria-red)", textAlign: "center" }}>
        {t("COMPLETE_PAYMENT")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ color: "black", textAlign: "center" }}>
          {t("COMPLETE_PAYMENT_DESCRIPTION")}
        </DialogContentText>
        <FormControl fullWidth variant="outlined" sx={[focusColor, { mt: 2 }]} required>
          <InputLabel>{t("TYPE_ASSISTANT")}</InputLabel>
          <Select
            label={t("TYPE_ASSISTANT")}
            value={paymentType}
            onChange={(e) => setPaymentType(e.target.value)}
          >
            <MenuItem value={PAYMENT_OPTIONS.OFFICE_CASH}>{t("OFFICE_CASH")}</MenuItem>
            <MenuItem value={PAYMENT_OPTIONS.OFFICE_CARD}>{t("OFFICE_CARD")}</MenuItem>
            <MenuItem value={PAYMENT_OPTIONS.OFFICE_TRANSFER_BANK}>
              {t("OFFICE_TRANSFER_BANK")}
            </MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
        <Button
          type="submit"
          className="oniria-btn-cancel"
          variant="contained"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          onClick={onClose}
        >
          {t("CANCEL")}
        </Button>
        <Button
          type="submit"
          className="oniria-btn"
          variant="contained"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          onClick={handleConfirm}
          disabled={!paymentType || loading}
        >
          {loading ? t("SAVING") : t("ACCEPT")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CompletePaymentDialog;
