import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GENRE_OPTIONS } from "../../constants/variables";
import { useQueryPremisesList } from "../../hooks/queries/useQueryPremisesList";
import SearchFilter from "../events/filters/filter/SearchFilter";
import SelectFilter from "../events/filters/filter/SelectFilter";

const TourLeadersFilters = ({ onSearchChange, filters, updateFilter }) => {
  const { t } = useTranslation();

  const { premiseId, genre } = filters;

  const { data: premises = [] } = useQueryPremisesList();

  const genreOptions = GENRE_OPTIONS.map((genre) => ({
    id: genre.id,
    name: t(genre.name),
  }));

  return (
    <Grid
      container
      spacing={1}
      mt={2}
      sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
    >
      <Grid item xs={12} md={3}>
        <SearchFilter onSearchChange={onSearchChange} />
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={11}
        md={9}
        columnGap={1}
        rowGap={1}
        justifyContent="flex-end"
      >
        <SelectFilter
          value={premiseId}
          onChange={(value) => updateFilter("premiseId", value)}
          options={[{ id: "all", name: t("PREMISES") }, ...premises]}
        />
        <SelectFilter
          value={genre}
          onChange={(value) => updateFilter("genre", value)}
          options={[{ id: "all", name: t("GENRE") }, ...genreOptions]}
        />
      </Grid>
    </Grid>
  );
};

export default TourLeadersFilters;
