import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Box,
  Collapse,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { soldTicketsPercent } from "../../../constants/eventsUtils";
import { PROGRESS_BAR_STYLES } from "../../../constants/styles";

const TicketsStock = ({ tickets }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(null);

  const handleAccordionToggle = (ticketId) => {
    setExpanded(expanded === ticketId ? null : ticketId);
  };

  const isDateValid = (startDate) => {
    if (!startDate) return true;

    const currentDate = new Date();
    const ticketDate = new Date(startDate);

    return (
      ticketDate >=
      new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
    );
  };

  return (
    <Paper elevation={3} sx={{ padding: 3, mb: 2, borderRadius: 3 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        {t("TICKETS_STOCK")}
      </Typography>
      {tickets.map((ticket, index) => {
        const isFutureOrToday = isDateValid(ticket.start_date);
        const ticketSoldPercent = soldTicketsPercent(ticket.sold_tickets, ticket.total_stock);

        return (
          <Box key={`${ticket.id}-${index}`} sx={{ mb: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
              <Grid item xs={8}>
                <Typography variant="body1" fontWeight="bold">
                  {ticket.ticket_name}
                </Typography>
              </Grid>
              <Grid item xs={4} container justifyContent="flex-end" alignItems="center">
                <Typography variant="body1">
                  {ticket.sold_tickets}/{ticket.total_stock}
                </Typography>
                {!isFutureOrToday && (
                  <IconButton
                    onClick={() => handleAccordionToggle(ticket.ticket_id)}
                    sx={{ padding: 0 }}
                  >
                    {expanded === ticket.ticket_id ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                )}
              </Grid>
            </Box>

            <Collapse in={isFutureOrToday || expanded === ticket.ticket_id}>
              <LinearProgress
                variant="determinate"
                value={ticketSoldPercent}
                sx={PROGRESS_BAR_STYLES}
              />

              {/** SOLD TICKETS BY DOMAIN **/}
              {ticket?.sold_tickets_by_domain.map((soldTicket, index) => (
                <Box key={`${soldTicket.id}-${index}`} sx={{ mb: 1, pl: 2, mt: 1 }}>
                  <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
                    <Typography variant="body2">{soldTicket.bought_in_domain}</Typography>
                    <Typography variant="body2">{soldTicket.sold_tickets}</Typography>
                  </Box>
                  <LinearProgress
                    variant="determinate"
                    value={soldTicketsPercent(soldTicket.sold_tickets, ticket.total_stock)}
                    sx={PROGRESS_BAR_STYLES}
                  />
                </Box>
              ))}
            </Collapse>

            {tickets.length > 1 && index < tickets.length - 1 && <Divider sx={{ mt: 2 }} />}
          </Box>
        );
      })}
    </Paper>
  );
};

export default TicketsStock;
