import AddIcon from "@mui/icons-material/Add";
import { Button, Grid, Pagination, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BlogFilters from "../../components/blog/BlogFilters";
import BlogTable from "../../components/blog/BlogTable";
import { Loading } from "../../components/shared/Loading";
import SelectPerPage from "../../components/shared/SelectPerPage";
import { ROUTES } from "../../constants/routes";
import { PAGINATION_STYLES } from "../../constants/styles";
import { useQueryBlogs } from "../../hooks/queries/blogs/useQueryBlogs";
import useDebounce from "../../hooks/useDebouncing";
import usePagination from "../../hooks/usePagination";
import Header from "../../components/Header";

let totalPosts = 0;

const Blog = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const breadcrumbs = [{ name: t("BLOGS"), link: ROUTES.BLOG }];

  const [filters, setFilters] = useState({
    search: "",
    tag: "",
    domain: "",
    visible: "",
    featured: "",
    status: "",
  });

  const [postsFiltered, setPostsFiltered] = useState([]);

  const { page, perPage, changePage, changePerPage } = usePagination();

  const handleCreate = () => {
    navigate(ROUTES.CREATE_BLOG_POST);
  };

  const handleQuerySuccessFiltered = (data) => {
    totalPosts = data.total || 0;
    setPostsFiltered(data.data);
  };

  const { isLoading, isRefetching } = useQueryBlogs(
    filters,
    page,
    perPage,
    handleQuerySuccessFiltered
  );

  const updateFilter = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    changePage(null, 1);
  };

  const handleSearchChange = (event) => {
    updateFilter("search", event.target.value);
  };

  const debounceSearch = useDebounce(handleSearchChange, 300);

  const numberPages = Math.ceil(parseFloat(totalPosts) / parseFloat(perPage));

  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t("BLOG_DESCRIPTION")} />
      <Grid
        item
        xs={11}
        sm={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "initial",
        }}
      >
        <BlogFilters
          onSearchChange={debounceSearch}
          filters={filters}
          updateFilter={updateFilter}
        />
      </Grid>
      <Grid container justifyContent="flex-end" alignItems="center" mb={2} mt={2}>
        <Button
          variant="contained"
          className="oniria-btn"
          startIcon={<AddIcon />}
          onClick={handleCreate}
        >
          {t("CREATE_POST")}
        </Button>
      </Grid>
      {totalPosts > 0 && (
        <Grid
          item
          xs={11}
          sm={12}
          container
          justifyContent={{ xs: "center", sm: "space-between" }}
          alignItems={"center"}
          pb={2}
          gap={{ xs: 2, sm: 0 }}
        >
          <Typography>
            {t("TOTAL_POSTS")}: {totalPosts}
          </Typography>
          {numberPages >= 1 && (
            <Grid item xs={12} md={8} container justifyContent="flex-end">
              <Grid item>
                <SelectPerPage text={t("PUBLICATIONS")} change={changePerPage} value={perPage} />
              </Grid>
              <Pagination
                count={numberPages}
                mr={3}
                sx={PAGINATION_STYLES}
                page={page}
                onChange={changePage}
              />
            </Grid>
          )}
        </Grid>
      )}
      {isRefetching || isLoading ? (
        <Loading />
      ) : postsFiltered?.length === 0 ? (
        <Grid ml={3}>{t("NO_POSTS_FOUND")}</Grid>
      ) : (
        <BlogTable data={postsFiltered} />
      )}
      {numberPages >= 1 && (
        <Grid mb={2}>
          <Pagination
            count={numberPages}
            sx={PAGINATION_STYLES}
            page={page}
            onChange={changePage}
          />
        </Grid>
      )}
    </>
  );
};

export default Blog;
