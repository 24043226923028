import { ArrowBack } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { defaultTag, defaultTagError } from "../../../classes/blogClass";
import { ROUTES } from "../../../constants/routes";
import { TAG_TYPES } from "../../../constants/variables";
import { useQueryTagDetail } from "../../../hooks/queries/tags/useQueryTagDetail";
import { useQueryTagsList } from "../../../hooks/queries/tags/useQueryTagsList";
import { useQueryDomains } from "../../../hooks/queries/useQueryDomains";
import { checkTagSlug, createTag, updateTag } from "../../../services/tagsServices";
import ColorInput from "../../shared/ColorInput";
import { handleSelectImage, transformObjectWithUrls } from "../../shared/FormsValidator";
import ImageUpload from "../../shared/ImageUpload";
import { Loading } from "../../shared/Loading";
import { focusColor } from "../../shared/textFieldStyle";
import { toastMessageError, toastMessageSuccess } from "../../shared/toastMessage";
import TagsTranslations from "./TagsTranslations";

const TagsForm = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(JSON.parse(JSON.stringify(defaultTag)));
  const [formErrors, setFormErrors] = useState(JSON.parse(JSON.stringify(defaultTagError)));
  const [loading, setLoading] = useState(false);

  const { data: domains } = useQueryDomains();
  const { data: tags } = useQueryTagsList({ isParent: true });

  const handleSetFormData = (data) => {
    const newData = JSON.parse(JSON.stringify(data));

    delete newData.created_at;
    delete newData.modified_at;
    setFormData(newData);
  };
  const { isLoading } = useQueryTagDetail(id, handleSetFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const errors = {};
    const requiredMessage = t("FIELD_REQUIRED");

    // Required fields
    const requiredFields = ["name", "slug", "description", "type", "domains"];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        errors[field] = requiredMessage;
      }
    });

    // Translations
    if (!formData.translations || formData.translations.length === 0) {
      errors.translations = requiredMessage;
    } else {
      formData.translations.forEach((translation, index) => {
        if (!translation.name) {
          if (!errors.translations) errors.translations = [];
          if (!errors.translations[index]) errors.translations[index] = {};
          errors.translations[index].name = requiredMessage;
        }
        if (!translation.description) {
          if (!errors.translations) errors.translations = [];
          if (!errors.translations[index]) errors.translations[index] = {};
          errors.translations[index].description = requiredMessage;
        }
      });
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const errors = validateForm();
      if (Object.keys(errors).length > 0) {
        setFormErrors(errors);
        return;
      }
      const formDataToSend = await transformObjectWithUrls(formData);

      formDataToSend.is_parent = formData.is_parent === true || formData.is_parent === 1;
      formDataToSend.parent_tags_ids = formData.parent_tags_ids || [];
      delete formDataToSend.created_at;
      delete formDataToSend.modified_at;
      if (id) {
        formDataToSend.domains = formData.domains.map((domain) => domain.domain_id || domain);
        await updateTag(id, formDataToSend);
        toastMessageSuccess(t("TAG_UPDATED_SUCCESS"));
      } else {
        const slugAvailable = await checkTagSlug(formDataToSend.slug);
        if (slugAvailable) {
          await createTag(formDataToSend);
          toastMessageSuccess(t("TAG_CREATED_SUCCESS"));
          setTimeout(() => {
            navigate(ROUTES.TAGS);
          }, 1500);
        } else {
          setFormErrors((prev) => ({ ...prev, slug: t("TAG_SLUG_ERROR") }));
          toastMessageError(t("TAG_SLUG_ERROR"));
        }
      }
    } catch (e) {
      toastMessageError(e.response.data.error || t("ERROR_UPDATE_STYLES"));
    } finally {
      setLoading(false);
    }
  };

  const handleGoBack = () => {
    navigate(ROUTES.TAGS);
  };

  return (
    <Grid mt={7} mb={5}>
      <IconButton onClick={handleGoBack}>
        <ArrowBack />
      </IconButton>
      <Grid container justifyContent="center" mb={5}>
        <Typography variant="h5">{!!id ? t("EDIT_TAG") : t("CREATE_TAG")}</Typography>
      </Grid>
      {id && isLoading ? (
        <Loading />
      ) : (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={t("NAME")}
                name="name"
                value={formData.name}
                onChange={handleChange}
                sx={focusColor}
                required
                helperText={formErrors?.name}
                error={formErrors?.name !== "" && formErrors?.name !== undefined}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={t("SLUG")}
                name="slug"
                value={formData.slug}
                onChange={handleChange}
                sx={focusColor}
                required
                helperText={formErrors?.slug}
                error={formErrors?.slug !== "" && formErrors?.slug !== undefined}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={t("DESCRIPTION")}
                name="description"
                value={formData.description}
                onChange={handleChange}
                sx={focusColor}
                required
                helperText={formErrors?.description}
                error={formErrors?.description !== "" && formErrors?.description !== undefined}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth sx={focusColor}>
                <InputLabel>{t("DOMAINS")}</InputLabel>
                <Select
                  value={formData?.domains?.map((domain) => domain?.domain_id || domain) || []}
                  name="domains"
                  onChange={handleChange}
                  label={t("DOMAINS")}
                  multiple
                  required
                  error={formErrors?.domains !== "" && formErrors?.domains !== undefined}
                  helperText={formErrors?.domains}
                >
                  <MenuItem value="">{t("ALL_DOMAINS")}</MenuItem>
                  {domains?.map((domain, index) => (
                    <MenuItem key={`${domain.domain_id}-${index}`} value={domain.domain_id}>
                      {domain.domain_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={t("FONT_WEIGHT")}
                name="font_weight"
                value={formData.font_weight}
                onChange={handleChange}
                sx={focusColor}
              />
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth sx={focusColor}>
                <InputLabel>{t("TYPE")}</InputLabel>
                <Select
                  label="Type"
                  name="type"
                  value={formData.type || ""}
                  onChange={handleChange}
                  required
                  error={formErrors?.type !== "" && formErrors?.type !== undefined}
                  helperText={formErrors?.type}
                >
                  {Object.keys(TAG_TYPES).map((type, index) => (
                    <MenuItem value={TAG_TYPES[type]} key={`${type}-${index}`}>
                      {t(TAG_TYPES[type].toUpperCase())}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} sm={4} container alignItems="center" gap={2}>
              <Typography>{t("BACKGROUND_COLOR")}: </Typography>
              <ColorInput
                value={formData.background_color}
                onChange={(e) => {
                  handleChange({ target: { name: "background_color", value: e.target.value } });
                }}
              />
            </Grid> 
            <Grid item xs={12} sm={4} container alignItems="center" gap={2}>
              <Typography>{t("COLOR_ICON")}: </Typography>
              <ColorInput
                value={formData.color_icon}
                onChange={(e) => {
                  handleChange({ target: { name: "color_icon", value: e.target.value } });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} container alignItems="center" gap={2}>
              <Typography>{t("COLOR_TEXT")}: </Typography>
              <ColorInput
                value={formData.color_text}
                onChange={(e) => {
                  handleChange({ target: { name: "color_text", value: e.target.value } });
                }}
              />
            </Grid>
            */}
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="is_parent"
                    checked={formData.is_parent === true || formData.is_parent === 1}
                    className="checkbox-oniria"
                    onChange={(e) => setFormData({ ...formData, is_parent: e.target.checked })}
                  />
                }
                label={t("IS_PARENT_TAG")}
              />
              <FormControl fullWidth sx={[focusColor, { mt: 2 }]}>
                <InputLabel>{t("PARENT_TAGS")}</InputLabel>
                <Select
                  value={formData?.parent_tags_ids || []}
                  label={t("PARENT_TAGS")}
                  onChange={(e) => setFormData({ ...formData, parent_tags_ids: e.target.value })}
                  multiple
                >
                  {tags?.map((tag) => (
                    <MenuItem key={tag.id} value={tag.id}>
                      {tag.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ImageUpload
                url={formData.icon}
                onImageSelect={(url) => handleSelectImage(url, setFormData, "icon")}
                multi={false}
                size="20x20px"
              />
            </Grid>
            <TagsTranslations
              formData={formData}
              setFormData={setFormData}
              formErrors={formErrors}
            />
            <Grid item xs={12} container justifyContent="center">
              <Button type="submit" variant="contained" className="oniria-btn" disabled={loading}>
                {loading ? t("SAVING") : !!id ? t("UPDATE_TAG") : t("CREATE_TAG")}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Grid>
  );
};

export default TagsForm;
