import { DeleteOutline, EditOutlined, RemoveRedEyeOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getDomainName, getPremiseName } from "../../constants/utils";

const TourPointsGrid = ({ data, handleEdit, handleSee, handleOpenDelete, premises, domains }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3} mb={2}>
        {data?.map((tourPoint) => (
          <Grid item xs={12} sm={6} md={4} key={tourPoint.id}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                borderRadius: "20px",
                backgroundColor: tourPoint.is_active === 0 ? "var(--grey-cancelled)" : "white",
              }}
            >
              <CardHeader
                avatar={<Avatar alt={tourPoint.name} src={tourPoint.image_url} />}
                title={
                  <Typography variant="h6" component="div">
                    {tourPoint.name}
                  </Typography>
                }
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="body2" color="textSecondary" mb={1}>
                  {tourPoint.domains_ids?.map((domain) => (
                    <p key={domain}>{getDomainName(domain, domains)}</p>
                  ))}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {getPremiseName(tourPoint.owner_premise_id, premises)}
                </Typography>
              </CardContent>
              <CardActions disableSpacing sx={{ justifyContent: "center", gap: 2 }}>
                <Tooltip title={t("SEE_EVENTS")}>
                  <IconButton onClick={() => handleSee(tourPoint.id)}>
                    <RemoveRedEyeOutlined sx={{ color: "var(--oniria-gold)" }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("EDIT_TOUR_POINT")}>
                  <IconButton onClick={() => handleEdit(tourPoint.id)}>
                    <EditOutlined sx={{ color: "var(--oniria-gold)" }} />
                  </IconButton>
                </Tooltip>
                {tourPoint.itineraries_using_it === 0 && (
                  <Tooltip title={t("DELETE_TOUR_POINT")}>
                    <IconButton onClick={() => handleOpenDelete(tourPoint.id)}>
                      <DeleteOutline sx={{ color: "var(--oniria-gold)" }} />
                    </IconButton>
                  </Tooltip>
                )}
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TourPointsGrid;
