import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BLOG_STATUS } from "../../constants/variables";
import { useQueryTagsList } from "../../hooks/queries/tags/useQueryTagsList";
import { useQueryDomains } from "../../hooks/queries/useQueryDomains";
import { focusColor } from "../shared/textFieldStyle";

const BlogFilters = ({ onSearchChange, filters, updateFilter }) => {
  const { t } = useTranslation();

  const { tag, domain, visible, featured, status } = filters;

  const { data: domains } = useQueryDomains();
  const { data: tags } = useQueryTagsList({ domainId: domain });

  return (
    <Grid
      container
      spacing={1}
      sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
    >
      <Grid item xs={12} md={3}>
        <TextField
          label={t("SEARCH")}
          onChange={onSearchChange}
          size="small"
          sx={[focusColor, { width: "75%" }]}
        />
      </Grid>
      <Grid item container xs={12} sm={11} md={9} columnGap={1} rowGap={1}>
        <FormControl size="small" sx={[focusColor, { minWidth: 120 }]}>
          <InputLabel>{t("TAGS")}</InputLabel>
          <Select
            value={tag}
            label={t("TAGS")}
            onChange={(e) => updateFilter("tag", e.target.value)}
          >
            <MenuItem value="">{t("ALL_TAGS")}</MenuItem>
            {tags?.map((tag) => (
              <MenuItem key={tag.id} value={tag.id}>
                {tag.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={[focusColor, { minWidth: 120 }]}>
          <InputLabel>{t("DOMAIN")}</InputLabel>
          <Select
            value={domain}
            label={t("DOMAIN")}
            onChange={(e) => updateFilter("domain", e.target.value)}
          >
            <MenuItem value="">{t("ALL_DOMAINS")}</MenuItem>
            {domains?.map((domain) => (
              <MenuItem key={domain.domain_name} value={domain.domain_id}>
                {domain.domain_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={[focusColor, { minWidth: 120 }]}>
          <InputLabel>{t("VISIBLE")}</InputLabel>
          <Select
            value={visible}
            label={t("VISIBLE")}
            onChange={(e) => updateFilter("visible", e.target.value)}
          >
            <MenuItem value="">{t("ALL_VISIBLE")}</MenuItem>
            <MenuItem value={true}>{t("VISIBLE_TRUE")}</MenuItem>
            <MenuItem value={false}>{t("VISIBLE_FALSE")}</MenuItem>
          </Select>
        </FormControl>
        <FormControl size="small" sx={[focusColor, { minWidth: 120 }]}>
          <InputLabel>{t("FEATURED")}</InputLabel>
          <Select
            value={featured}
            label={t("FEATURED")}
            onChange={(e) => updateFilter("featured", e.target.value)}
          >
            <MenuItem value="">{t("ALL_FEATURED")}</MenuItem>
            <MenuItem value={true}>{t("FEATURED_TRUE")}</MenuItem>
            <MenuItem value={false}>{t("FEATURED_FALSE")}</MenuItem>
          </Select>
        </FormControl>
        <FormControl size="small" sx={[focusColor, { minWidth: 120 }]}>
          <InputLabel>{t("STATUS")}</InputLabel>
          <Select
            value={status}
            label={t("STATUS")}
            onChange={(e) => updateFilter("status", e.target.value)}
          >
            <MenuItem value="">{t("ALL_STATUS")}</MenuItem>
            <MenuItem value={BLOG_STATUS.PUBLISHED}>{t("STATUS_PUBLISHED")}</MenuItem>
            <MenuItem value={BLOG_STATUS.DRAFT}>{t("STATUS_DRAFT")}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default BlogFilters;
