import AddIcon from "@mui/icons-material/Add";
import { Button, Grid, Pagination, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ExtrasFilters from "../../components/extras/ExtrasFilters";
import ExtrasTable from "../../components/extras/ExtrasTable";
import Header from "../../components/Header";
import { Loading } from "../../components/shared/Loading";
import SelectPerPage from "../../components/shared/SelectPerPage";
import { ROUTES } from "../../constants/routes";
import { PAGINATION_STYLES } from "../../constants/styles";
import useQueryExtras from "../../hooks/queries/extras/useQueryExtras";
import useDebounce from "../../hooks/useDebouncing";
import usePagination from "../../hooks/usePagination";

const ExtrasPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const breadcrumbs = [{ name: t("EXTRAS"), link: ROUTES.EXTRAS }];
  const [filters, setFilters] = useState({
    search: "",
    premiseId: "",
    asc: [],
    desc: ["created_at"],
  });
  const { page, perPage, changePage, changePerPage } = usePagination();

  const { data, isLoading, error, refetch } = useQueryExtras(page, perPage, filters);

  const { data: extras = [], amount: totalExtras } = data || {};

  const updateFilter = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    changePage(null, 1);
  };

  const handleSearchChange = (event) => {
    updateFilter("search", event.target.value);
    changePage(null, 1);
  };

  const debounceSearch = useDebounce(handleSearchChange, 300);

  const handleOrder = (id, type) => {
    const oppositeType = type === "asc" ? "desc" : "asc";
    const newFilters = { ...filters };
    if (newFilters[type]?.includes(id)) {
      newFilters[type] = newFilters[type].filter((item) => item !== id);
    } else {
      newFilters[type].push(id);
      newFilters[oppositeType] = newFilters[oppositeType]?.filter((item) => item !== id);
    }
    setFilters(newFilters);
  };

  const handleCreate = () => {
    navigate(ROUTES.CREATE_EXTRA);
  };

  const numberPages = Math.ceil(parseFloat(totalExtras) / parseFloat(perPage));

  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t("EXTRAS_PAGE_DESCRIPTION")} />
      <ExtrasFilters
        onSearchChange={debounceSearch}
        filters={filters}
        updateFilter={updateFilter}
      />
      <Grid container justifyContent="flex-end" alignItems="center" mb={2} mt={2}>
        <Button
          variant="contained"
          className="oniria-btn"
          startIcon={<AddIcon />}
          onClick={handleCreate}
        >
          {t("CREATE_EXTRA")}
        </Button>
      </Grid>
      {isLoading ? (
        <Loading />
      ) : error ? (
        <p>{t("ERROR_LOADING_DATA")}</p>
      ) : totalExtras > 0 ? (
        <div style={{ marginBottom: "20px" }}>
          <Grid
            item
            xs={11}
            sm={12}
            container
            justifyContent={{ xs: "center", sm: "space-between" }}
            alignItems={"center"}
            pb={2}
            gap={{ xs: 2, sm: 0 }}
          >
            <Typography>
              {t("TOTAL_EXTRAS")}: {totalExtras}
            </Typography>
            {numberPages >= 1 && (
              <Grid item xs={12} md={8} container justifyContent="flex-end">
                <Grid item>
                  <SelectPerPage text={t("EXTRAS")} change={changePerPage} value={perPage} />
                </Grid>
                <Pagination
                  count={numberPages}
                  mr={3}
                  sx={PAGINATION_STYLES}
                  page={page}
                  onChange={changePage}
                />
              </Grid>
            )}
          </Grid>
          <ExtrasTable
            data={extras}
            filters={filters}
            handleOrder={handleOrder}
            refetch={refetch}
          />
          {numberPages >= 1 && (
            <Grid container justifyContent="center">
              <Pagination
                count={numberPages}
                mr={3}
                sx={PAGINATION_STYLES}
                page={page}
                onChange={changePage}
              />
            </Grid>
          )}
        </div>
      ) : (
        <p>{t("NO_EXTRAS_FOUND")}</p>
      )}
    </>
  );
};

export default ExtrasPage;
