import { Add, Delete } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { focusColor } from "../shared/textFieldStyle";

const TourLeaderAddLanguages = () => {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const [newLanguage, setNewLanguage] = useState("");

  const { register, setValue, getValues, watch } = useFormContext();

  const languages = watch("languages") || [];

  const handleAddLanguage = () => {
    const previousLanguages = getValues("languages") || [];
    const newLanguages = [...previousLanguages, newLanguage];
    setValue("languages", newLanguages);
    setNewLanguage("");
    setOpenDialog(false);
  };

  const handleDeleteLanguage = (index) => {
    const updatedLanguages = languages?.filter((_, i) => i !== index);
    setValue("languages", updatedLanguages);
  };

  return (
    <Grid item xs={12}>
      <Typography>{t("LANGUAGES")}</Typography>

      {languages?.map((_, index) => (
        <Grid container key={index} spacing={2} alignItems="center" mt={2}>
          <Grid item xs={10}>
            <TextField
              className="oniria-input"
              variant="outlined"
              size="small"
              sx={[focusColor, { width: "100%" }]}
              label={t("LANGUAGE")}
              {...register(`languages.${index}`)}
            />
          </Grid>
          <Grid item xs={2}>
            <IconButton color="error" onClick={() => handleDeleteLanguage(index)}>
              <Delete />
            </IconButton>
          </Grid>
        </Grid>
      ))}

      <Button
        className="oniria-btn"
        variant="contained"
        size="medium"
        sx={{ fontSize: "12px", borderRadius: 3, mt: 2 }}
        startIcon={<Add />}
        onClick={() => setOpenDialog(true)}
      >
        {t("ADD_LANGUAGE")}
      </Button>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ fontWeight: "bolder", color: "var(--oniria-red)", textAlign: "center" }}>
          {t("ADD_LANGUAGE")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: "black", textAlign: "center" }}>
            {t("ADD_LANGUAGE_DIALOG_DESCRIPTION")}
          </DialogContentText>
          <Typography>{t("LANGUAGE")}</Typography>
          <TextField
            className="oniria-input"
            variant="outlined"
            size="small"
            sx={[focusColor, { width: "100%", mt: 2 }]}
            label={t("LANGUAGE")}
            value={newLanguage}
            onChange={(e) => setNewLanguage(e.target.value)}
          />
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}
        >
          <Button
            className="oniria-btn-cancel"
            variant="contained"
            size="medium"
            sx={{ fontSize: "12px", borderRadius: 3 }}
            onClick={() => setOpenDialog(false)}
          >
            {t("CANCEL")}
          </Button>
          <Button
            className="oniria-btn"
            variant="contained"
            size="medium"
            sx={{ fontSize: "12px", borderRadius: 3 }}
            onClick={handleAddLanguage}
          >
            {t("ACCEPT")}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default TourLeaderAddLanguages;
