import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { getHotelsAssigned } from "../../../services/hotelServices";

const useQueryHotelsAssigned = (page, perPage, filters, selectedTab, handleSetHotels) => {
  return useQuery(
    [RQ_KEY.HOTELS_ASSIGNED, page, perPage, filters],
    () => getHotelsAssigned(page, perPage, filters),
    {
      refetchOnWindowFocus: false,
      enabled: selectedTab === 0,
      onSuccess: (data) => {
        handleSetHotels(data.data);
      },
    }
  );
};

export default useQueryHotelsAssigned;
