import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { getExtraStatistics } from "../../../services/extrasServices";

const useQueryExtrasStatistics = (id, page, perPage, filters) => {
  return useQuery(
    [RQ_KEY.EXTRAS_TEMPLATES, page, perPage, filters, id],
    () => getExtraStatistics(id, page, perPage, filters),
    {
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  );
};

export default useQueryExtrasStatistics;
