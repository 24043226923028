import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { getHotelDetails } from "../../../services/hotelServices";

const useQueryHotelDetails = (id, handleSetFormData, isForTrip) => {
  console.log('PREVIEW HOTEL', id)
  return useQuery([RQ_KEY.HOTELS, id], () => getHotelDetails(id, isForTrip), {
    refetchOnWindowFocus: false,
    enabled: !!id,
    onSuccess: handleSetFormData,
  });
};

export default useQueryHotelDetails;
