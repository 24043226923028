import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQueryDomains } from "../../../hooks/queries/useQueryDomains";
import { focusColor } from "../../shared/textFieldStyle";

const TagsFilters = ({ onSearchChange, filters, updateFilter }) => {
  const { t } = useTranslation();

  const { domain } = filters;

  const { data: domains } = useQueryDomains();

  return (
    <Grid
      container
      spacing={1}
      sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
    >
      <Grid item xs={12} md={3}>
        <TextField
          label={t("SEARCH")}
          onChange={onSearchChange}
          size="small"
          sx={[focusColor, { width: "75%" }]}
        />
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={11}
        md={9}
        columnGap={1}
        rowGap={1}
        justifyContent="flex-end"
      >
        <FormControl size="small" sx={[focusColor, { minWidth: 120 }]}>
          <InputLabel>{t("DOMAIN")}</InputLabel>
          <Select
            value={domain}
            label={t("DOMAIN")}
            onChange={(e) => updateFilter("domain", e.target.value)}
          >
            <MenuItem value="">{t("ALL_DOMAINS")}</MenuItem>
            {domains?.map((domain) => (
              <MenuItem key={domain.domain_name} value={domain.domain_id}>
                {domain.domain_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default TagsFilters;
