import { AddOutlined, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import HotelExtraForm from "./HotelExtraForm";
import { defaultHotelExtra, defaultHotelExtraLanguageInfo } from "../../../schemas/hotels";

const HotelExtras = () => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext();
  const extras = watch("extra_options") || [];

  const handleChangeDescription = (data, index) => {
    setValue(`extra_options[${index}].description`, data);
  };

  const handleDeleteExtra = (index) => {
    const newExtras = extras.filter((_, i) => i !== index);
    setValue("extra_options", newExtras);
  };

  const handleAddExtra = () => {
    const newExtra = JSON.parse(JSON.stringify(defaultHotelExtra));
    setValue("extra_options", [...extras, newExtra]);
  };

  const handleChangeCheckbox = (isChecked, value, index) => {
    const newExtras = [...extras];
    const languageInfo = newExtras[index].hotel_extra_option_language_infos || [];

    if (isChecked) {
      newExtras[index].hotel_extra_option_language_infos = [
        ...languageInfo,
        { ...defaultHotelExtraLanguageInfo, language: value },
      ];
    } else {
      newExtras[index].hotel_extra_option_language_infos = languageInfo.filter(
        (language) => language.language !== value
      );
    }

    setValue("extra_options", newExtras);
  };

  const getExtraLanguageIndex = (index, language) => {
    return extras[index].hotel_extra_option_language_infos?.findIndex(
      (extraLanguage) => extraLanguage.language === language
    );
  };

  const handleChangeLanguageDescription = (data, index, language) => {
    const newExtras = JSON.parse(JSON.stringify(extras));
    newExtras[index].hotel_extra_option_language_infos[
      getExtraLanguageIndex(index, language)
    ].description = data;
    setValue("extra_options", newExtras);
  };

  useEffect(() => {
    if (extras.length === 0) {
      setValue("extra_options", [JSON.parse(JSON.stringify(defaultHotelExtra))]);
    }
  }, []);

  return (
    <Grid item xs={12} container>
      <Accordion
        sx={{ border: "1px solid var(--grey-cancelled)", boxShadow: "none", width: "100%" }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box display="flex" flexDirection="column">
            <Typography variant="h6">{t("ADDITIONAL_OPTIONS")}</Typography>
            <Typography variant="caption">{t("ADDITIONAL_OPTIONS_INFO")}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {extras?.map((extra, index) => (
            <HotelExtraForm
              key={index}
              index={index}
              extra={extra}
              handleDeleteExtra={handleDeleteExtra}
              handleChangeCheckbox={handleChangeCheckbox}
              handleChangeDescription={handleChangeDescription}
              handleChangeLanguageDescription={handleChangeLanguageDescription}
              getExtraLanguageIndex={getExtraLanguageIndex}
            />
          ))}
          <Button
            onClick={handleAddExtra}
            variant="contained"
            className="oniria-btn"
            sx={{ width: "fit-content" }}
            startIcon={<AddOutlined />}
          >
            {t("ADD_OTHER_EXTRA")}
          </Button>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default HotelExtras;
