import { Grid, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import ImageUpload from "../shared/ImageUpload";
import { useTranslation } from "react-i18next";
import InfoToolTip from "../../components/shared/InfoToolTip";

const TourPointImages = () => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext();

  const handleSelectImage = (url, type) => {
    if (Array.isArray(url)) {
      const imageArray = [];
      for (let i = 0; i < url.length; i++) {
        const urlParams = url[i].split(";");
        const imageObject = { image: urlParams[1], mime: urlParams[0].split(":")[1] };
        imageArray.push(imageObject);
      }
      if (imageArray.length === 0) {
        setValue(type, null);
      } else {
        setValue(type, imageArray);
      }
    } else {
      const urlParams = url.split(";");
      const imageObject = { image: urlParams[1], mime: urlParams[0].split(":")[1] };
      setValue(type, imageObject);
    }
  };

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Typography>{t("PRINCIPAL_IMAGE")}<InfoToolTip text={t("TOURPOINT_IMG_RATIO")} /></Typography>
        <ImageUpload
          onImageSelect={(e) => handleSelectImage(e, "image_url")}
          url={watch("image_url")}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography>
          {t("GALLERY_IMAGES")} - {t("MAX_5_IMAGES")}<InfoToolTip text={t("TOURPOINT_IMG_RATIO")} />
        </Typography>
        <ImageUpload
          onImageSelect={(e) => handleSelectImage(e, "image_gallery_urls")}
          multi
          selectedImagesInForm={watch("image_gallery_urls")}
        />
      </Grid>
    </>
  );
};

export default TourPointImages;
