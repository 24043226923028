import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { getTransportDetails } from "../../../services/transportServices";

const useQueryTransportDetails = (id, onSuccess, isTrip) => {
  return useQuery([RQ_KEY.TRANSPORTS_DETAILS, id, isTrip], () => getTransportDetails(id, isTrip), {
    refetchOnWindowFocus: false,
    enabled: !!id,
    onSuccess,
  });
};

export default useQueryTransportDetails;
