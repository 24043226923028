import {
  AirportShuttleOutlined,
  DirectionsBoatOutlined,
  DirectionsCarOutlined,
  FlightOutlined,
  SailingOutlined,
  TrainOutlined,
} from "@mui/icons-material";
import { BoatIcon } from "../components/icons/BoatIcon";
import CatamaranIcon from "../components/icons/CatamaranIcon";
import { cloneElement } from "react";

export const USER_STATUS = {
  PENDING_VALIDATE: "pending_validate",
  VALIDATE: "affiliated",
  NO_AFFILIATED: "no_affiliated",
  PENDING_PAYMENT: "pending_payment",
};

export const ADMIN_ROLE = {
  admin: "ead0b44b-4e96-4292-ae4d-ad68bc61f10b",
  delegate: "5abec0dd-3694-43fd-bfad-f9984e0fe0e1",
  affiliated: "e4a0c79d-b4f9-4948-b338-959e0abb2f88",
  scanner: "792dc753-c5b9-4abc-a036-2bfa7f68844c",
  office_admin: "8ca824aa-dab0-4de2-a69d-6cbd02516230",
  office_admin_can_edit: "37e2e8de-ef25-416c-bbb4-5dab65439fac",
};

export const LANGUAGES = [
  { label: "ENGLISH", value: "EN" },
  { label: "FRENCH", value: "FR" },
];

export const PAYMENT_OPTIONS_TYPES = [
  "app_redsys",
  "app_paypal",
  "app_free",
  "transaction",
  "app_redsys_timeout",
  "app_paypal_timeout",
  "app_free_timeout",
  "office_cash",
  "office_card",
  "office_guest",
  "office_bank_transfer",
  "",
];

export const CARD_PAYMENT_OPTIONS = [
  "app_redsys",
  "app_paypal",
  "office_guest",
  "office_cash",
  "office_card",
  "office_bank_transfer",
];

export const ORDER_STATUS_TYPES = {
  FAILED: "ORDER_FAILED", // rechazado por pasarela
  CANCEL: "ORDER_CANCEL", // timeout o rechazado por user
  PARCIAL: "ORDER_STATUS_PARCIAL",
  PAYED_SUCCESFULL: "ORDER_STATUS_PAYED", // todos los pagados exitosos
  PAYED: "ORDER_STATUS_PAYED", // pagado pero no terminado el evento
  FINISH: "finish", // pagado y terminado el evento
  REFUNDED: "ORDER_STATUS_REFUNDED",
  REFUND: "ORDER_STATUS_REFUND",
};

export const PAYMENT_OPTIONS = {
  // General
  APP: "app",
  OFFICE: "office",
  // Specific
  APP_REDSYS: "app_redsys",
  APP_PAYPAL: "app_paypal",
  APP_FREE: "app_free",
  OFFICE_CASH: "office_cash",
  OFFICE_CARD: "office_card",
  OFFICE_GUEST: "office_guest",
  OFFICE_TRANSFER_BANK: "office_bank_transfer",
  // Deprecated - We keep them for now
  TRANSACTION: "transaction",
  CASH: "cash",
  GUEST: "guest",
};

export const EVENTS_TYPE = {
  EVENT: "event",
  TRIP: "trip",
  ACTIVITY: "activity",
};

export const getEventConfigurations = (t) => ({
  activity: {
    breadcrumbs: [{ name: t("ACTIVITIES"), link: "/events" }],
    description: t("HEADER_TEXT_ACTIVITIES"),
  },
  event: {
    breadcrumbs: [{ name: t("EVENTS"), link: "/events" }],
    description: t("Descripción para eventos"),
  },
});

export const ROLES = {
  ADMIN: "admin",
  DELEGATE: "delegate",
  AFFILIATED: "affiliated",
  SUPER_ADMIN: "super-admin",
  MANAGER_ADMIN: "manager-admin",
  OFFICE_ADMIN: "office_admin",
  OFFICE_ADMIN_CAN_EDIT: "office_admin_can_edit",
  SCANNER: "scanner",
};

export const ONLY_READ_ROLES = [
  ROLES.SCANNER,
  ROLES.OFFICE_ADMIN,
  ROLES.OFFICE_ADMIN_CAN_EDIT,
];

export const ADMIN_ROLES_OPTIONS = [
  { value: ROLES.ADMIN, label: "ADMIN" },
  { value: ROLES.OFFICE_ADMIN, label: "OFFICE_ADMIN" },
  { value: ROLES.OFFICE_ADMIN_CAN_EDIT, label: "OFFICE_ADMIN_CAN_EDIT" },
  { value: ROLES.SCANNER, label: "SCANNER" },
];

export const ZGZ_CENTER = [41.647451005201354, -0.8874286183474662];

export const LOGOS = {
  PRINCIPAL_WHITE: "/media/logos/bliss2be_white.svg",
  PRINCIPAL_GREY: "/media/logos/bliss2be_gris.svg",
  NOT_FOUND: "/media/not_found.svg",
};

export const DEFAULT_EVENT_LANGUAGE = "ES";
export const DEFAULT_LANGUAGE = "ES";

export const WEEK_DAYS_MAP = ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"];
export const MONTHS_MAP = {
  0: "ene",
  1: "feb",
  2: "mar",
  3: "abr",
  4: "may",
  5: "jun",
  6: "jul",
  7: "ago",
  8: "sept",
  9: "oct",
  10: "nov",
  11: "dic",
};

export const IVA_OPTIONS = [0, 4, 10, 21];

export const EVENT_TABS = {
  MY_EVENTS: 0,
  B2B: 1,
};

export const BLOG_STATUS = {
  DRAFT: "draft",
  PUBLISHED: "published",
};

export const TAG_TYPES = {
  //EVENT: "event",
  BLOG: "blog",
};

export const PENSION_OPTIONS = [
  { id: "breakfast", name: "BREAKFAST" },
  { id: "middle", name: "MIDDLE_PENSION" },
  { id: "complete", name: "PENSION_COMPLETE" },
  { id: "", name: "NONE" },
];

export const GENRE_OPTIONS = [
  { id: "male", name: "MALE" },
  { id: "female", name: "FEMALE" },
  { id: "other", name: "OTHER" },
];

export const TRANSPORT_TYPES = [
  { id: "bus", name: "BUS", icon: <AirportShuttleOutlined /> },
  { id: "train", name: "TRAIN", icon: <TrainOutlined /> },
  { id: "plane", name: "PLANE", icon: <FlightOutlined /> },
  { id: "car", name: "CAR", icon: <DirectionsCarOutlined /> },
  { id: "ferry", name: "FERRY", icon: <DirectionsBoatOutlined /> },
  { id: "boat", name: "BOAT", icon: <BoatIcon /> },
  { id: "catamaran", name: "CATAMARAN", icon: <CatamaranIcon /> },
  { id: "sailBoat", name: "SAIL_BOAT", icon: <SailingOutlined /> },
];

export function getTransportIconById(id, size = 40) {
  const transport = TRANSPORT_TYPES.find((item) => item.id === id);
  return transport
    ? cloneElement(transport.icon, { sx: { fontSize: size } })
    : null;
}
