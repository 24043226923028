import { Button, Grid } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { createNewTicket } from "../../../../constants/eventsUtils";
import { toastMessageError, toastMessageSuccess } from "../../../shared/toastMessage";

const SaveTicketButton = ({ tickets, ticket, setFormData, ticketIndex }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    try {
      setLoading(true);
      const response = await createNewTicket(ticket);
      const updatedTickets = tickets.map((t, index) =>
        index === ticketIndex ? { ...t, id: response?.ticket?.id || "" } : t
      );
      //
      setFormData((prev) => ({
        ...prev,
        tickets: updatedTickets,
      }));
      toastMessageSuccess(t("TICKET_SAVED_SUCCESSFULLY"));
    } catch (error) {
      console.error(error);
      toastMessageError(error?.response?.data?.error || t("ERROR_SAVE_TICKET"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container justifyContent="center">
      {!ticket.id && ticket.event_id && (
        <Button
          className="oniria-btn"
          fullWidth
          variant="contained"
          sx={{ fontSize: "20px", borderRadius: 3, width: "fit-content" }}
          onClick={handleSave}
          disabled={loading}
        >
          {t("SAVE")}
        </Button>
      )}
    </Grid>
  );
};

export default SaveTicketButton;
