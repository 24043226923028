import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useQueryDomains } from "../../hooks/queries/useQueryDomains";
import { focusColor } from "../shared/textFieldStyle";
import { useQueryPremisesList } from "../../hooks/queries/useQueryPremisesList.jsx";

const TpvFilters = ({ onSearchChange, filters, updateFilter }) => {
  const { t } = useTranslation();
  const { domain, premise } = filters;

  // Obtener la lista de dominios
  const { data: domains } = useQueryDomains();
  const { data: premises } = useQueryPremisesList();

  // Asegurarse de que siempre haya un dominio seleccionado
  useEffect(() => {
    if (domains && domains.length > 0 && !domain) {
      updateFilter("domain", domains[0].domain_id); // Selecciona el primer dominio si no hay ninguno seleccionado
    }
    if (premises && premises.length > 0 && !premise) {
      updateFilter("premise_id", premises[0].id);
    }
  }, [domains, domain, premise, premises, updateFilter]);

  return (
    <Grid
      container
      spacing={1}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Grid
        item
        container
        xs={12}
        sm={11}
        md={9}
        columnGap={1}
        rowGap={1}
        justifyContent="flex-end"
      >
        <FormControl size="small" sx={[focusColor, { minWidth: 120 }]}>
          <InputLabel>{t("PREMISES")}</InputLabel>
          <Select
            value={premise}
            label={t("PREMISES")}
            onChange={(e) => updateFilter("premise_id", e.target.value)}
          >
            <MenuItem value="">{t("ALL_SITES")}</MenuItem>
            {premises?.map((premise) => (
              <MenuItem key={premise.id} value={premise.id}>
                {premise.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
     
        <FormControl size="small" sx={[focusColor, { minWidth: 120 }]}>
          <InputLabel>{t("DOMAIN")}</InputLabel>
          <Select
            value={domain}
            label={t("DOMAIN")}
            onChange={(e) => updateFilter("domain", e.target.value)}
          >
            <MenuItem value="">{t("ALL_DOMAINS")}</MenuItem>
            {domains?.map((domain) => (
              <MenuItem key={domain.domain_name} value={domain.domain_id}>
                {domain.domain_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default TpvFilters;
