import { Divider, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useQueryTourPointsList from "../../hooks/queries/tourPoints/useQueryTourPointsList";
import SelectHookForm from "../shared/SelectHookForm";
import { focusColor } from "../shared/textFieldStyle";

const TransportFormTrips = () => {
  const { t } = useTranslation();
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  const { data: tourPoints = [] } = useQueryTourPointsList();

  return (
    <>
      <Divider sx={{ width: "100%", borderColor: "var(--secondary-color)", mt: 2, mb: 2 }} />
      <Grid item xs={12} sm={6}>
        <Typography mb={1}>{t("START_POINT")}</Typography>
        <SelectHookForm
          name={"start_point_id"}
          control={control}
          label={t("START_POINT")}
          error={errors.start_point_id}
          options={[{ id: null, name: t("NONE") }, ...tourPoints].map((point) => (
            <MenuItem key={point.id} value={point.id}>
              {point.name}
            </MenuItem>
          ))}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography mb={1}>{t("END_POINT")}</Typography>
        <SelectHookForm
          name={"end_point_id"}
          control={control}
          label={t("END_POINT")}
          error={errors.end_point_id}
          options={[{ id: null, name: t("NONE") }, ...tourPoints].map((point) => (
            <MenuItem key={point.id} value={point.id}>
              {point.name}
            </MenuItem>
          ))}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography>{t("START_DAY")}</Typography>
        <Controller
          name="start_date"
          control={control}
          render={({ field }) => (
            <TextField
              type="date"
              {...field}
              error={!!errors?.start_date}
              helperText={errors.start_date?.message || ""}
              fullWidth
              inputProps={{
                min: new Date().toISOString().split("T")[0],
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={focusColor}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography>{t("END_DAY")}</Typography>
        <Controller
          name="end_date"
          control={control}
          render={({ field }) => (
            <TextField
              type="date"
              {...field}
              error={!!errors?.end_date}
              helperText={errors.end_date?.message || ""}
              fullWidth
              inputProps={{
                min: new Date().toISOString().split("T")[0],
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={focusColor}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography>{t("START_DAY_TIME")}</Typography>
        <Controller
          name="start_date_time"
          control={control}
          render={({ field }) => (
            <TextField
              type="time"
              {...field}
              error={!!errors?.start_date}
              helperText={errors.start_date?.message || ""}
              fullWidth
              inputProps={{
                min: new Date().toISOString().split("T")[0],
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={focusColor}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography>{t("END_DAY_TIME")}</Typography>
        <Controller
          name="end_date_time"
          control={control}
          render={({ field }) => (
            <TextField
              type="time"
              {...field}
              error={!!errors?.end_date}
              helperText={errors.end_date?.message || ""}
              fullWidth
              inputProps={{
                min: new Date().toISOString().split("T")[0],
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={focusColor}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>{t("OPTIONAL_PLATE")}</Typography>
        <TextField
          placeholder={t("PLATE")}
          {...register("plate")}
          error={!!errors.plate}
          helperText={errors.plate?.message}
          fullWidth
          sx={focusColor}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>{t("OPTIONAL_ASSIGNATION_LIMIT")}</Typography>
        <TextField
          placeholder={t("ASSIGNATION_LIMIT")}
          {...register("assignation_limit")}
          error={!!errors.assignation_limit}
          helperText={errors.assignation_limit?.message}
          fullWidth
          sx={focusColor}
          type="number"
          InputProps={{
            inputProps: {
              min: 0,
            },
          }}
        />
      </Grid>
    </>
  );
};

export default TransportFormTrips;
