import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  creaateAssistanUser,
  createAssistantUserErrors,
  extra,
  ticketErrors,
  ticketErrorsOk,
} from "../../classes/userClass";
import Header from "../../components/Header";
import { allFieldsOk } from "../../components/shared/FormsValidator";
import { Loading } from "../../components/shared/Loading";
import { focusColor } from "../../components/shared/textFieldStyle";
import { toastMessageError, toastMessageSuccess } from "../../components/shared/toastMessage";
import { RQ_KEY } from "../../constants/query";
import { EURO, formatDate, formatHours } from "../../constants/utils";
import { PAYMENT_OPTIONS } from "../../constants/variables";
import { useQueryAssignDomains } from "../../hooks/queries/useQueryAssignDomains";
import { useQueryUserCards } from "../../hooks/queries/useQueryUserCards";
import { createOrder, getEventById, getTicketsAndExtras } from "../../services/eventsServices";
import { getUsersAffiliate } from "../../services/usersServices";
import { getCountries } from "../../services/utilsServices";

const CreateAssistant = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { eventId } = useParams();
  const documents = ["NIE", "NIF", t("Passport")];

  const [eventData, setEventData] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [search, setSearch] = useState("");
  const [showGrid, setShowGrid] = useState(false);
  const [ticketsExtrasData, setTicketsExtrasData] = useState([]);
  const [formData, setFormData] = useState(creaateAssistanUser);
  const [formErrors, setFormErrors] = useState(createAssistantUserErrors);
  const [countries, setCountries] = useState([]);
  const [selectedCountryNationality, setSelectedCountryNationality] = useState("");
  const [selectedDocument, setSelectedDocument] = useState("");
  const [addClicked, setAddClicked] = useState(false);
  const [ticketError, setTicketError] = useState(false);
  const [ticketErrorMessage, setTicketErrorMessage] = useState("");
  const [doublePayment, setDoublePayment] = useState(false);
  const [loading, setLoading] = useState(false);

  // Get user cards to check if user is international
  const { data: userCards } = useQueryUserCards(formData.user_id);
  const userIsInternational = () => {
    if (!userCards || userCards.length === 0) return false;
    return userCards.some((card) => card.expires_at === null || card.expires_at > new Date());
  };

  // TODO: Esto igual mas limpio
  if (formData.event_id === "") {
    setFormData({
      ...formData,
      event_id: eventId,
    });
  }

  const handleQuerySuccess = (data) => {
    setEventData(data);
  };
  const { isLoading } = useQuery("event", () => getEventById(eventId), {
    onSuccess: handleQuerySuccess,
  });

  const handleCheckboxChange = (event) => {
    setShowGrid(event.target.checked); // Cambia el estado basado en si el checkbox está marcado o no
  };

  const getTotalPrice = () => {
    let totalPrice = 0;
    for (let i = 0; i < formData.tickets.length; i++) {
      totalPrice += parseFloat(formData.tickets[i].price);
      for (let j = 0; j < formData.tickets[i].extras.length; j++) {
        totalPrice += formData.tickets[i].extras[j].price * formData.tickets[i].extras[j].amount;
      }
      //International discount
      if (formData.tickets[i].internationalDiscount && userIsInternational()) {
        if (
          formData.tickets[i].internationalDiscount === ticketsExtrasData[0].international_discount
        ) {
          totalPrice -= ticketsExtrasData[i].international_discount;
        }
      }
      //Promotional code
      if (formData.tickets[i].promotional_code) {
        if (formData.tickets[i].promotional_code === ticketsExtrasData[0].promotional_code) {
          totalPrice -= ticketsExtrasData[i].promotional_code_discount;
        }
      }
    }
    const splitPayment = doublePayment
      ? (totalPrice * eventData?.first_payment_percentage) / 100
      : 0;
    return EURO.format(totalPrice - splitPayment);
  };

  // GET USERS
  useQuery([RQ_KEY.USERS_FOR_EVENT, search], () => getUsersAffiliate(search), {
    onSuccess: (data) => setUsersData(data),
    refetchOnWindowFocus: false,
  });

  // DOMAINS
  const { data: domainCategories } = useQueryAssignDomains();

  useEffect(() => {
    getTicketsAndExtras(eventData.id)
      .then((data) => {
        setTicketsExtrasData(data);
      })
      .catch((error) => {
        console.error("Error al obtener tickets:", error);
      });

    if (eventData.event_type === "trip") {
      getCountries().then((data) => {
        setCountries(data);
      });
    }
  }, [eventData]);

  // Breadcrumbs
  const breadcrumbs = [
    {
      name: eventData.event_type === "trip" ? t("TRAVELS") : t("EVENTS"),
      link: eventData.event_type === "trip" ? "/trips" : "/events",
    },
    {
      name: eventData.name ? eventData.name : "",
      link: `/event/${eventData.id}`,
    },
    {
      name: "Añadir asistente",
    },
  ];

  const handleEmailChange = (_, newValue) => {
    setFormData({ ...formData, user_id: newValue ? newValue.user_id : "" });
    if (newValue) {
      setFormErrors({ ...formErrors, user_id: "" });
    }
  };

  const handleInputChange = (_, newValue) => {
    setSearch(newValue);
  };

  const handleTypeAssistant = (event) => {
    const selectedAssistant = event.target.value;
    //Errors
    if (selectedAssistant !== "") {
      setFormErrors({ ...formErrors, payment_type: "" });
    }
    setFormData({
      ...formData,
      payment_type: selectedAssistant,
    });
  };

  const handleDomain = (event) => {
    const selectedDomain = event.target.value;
    setFormData({
      ...formData,
      domain_name: selectedDomain,
    });
  };

  const addTicket = (index) => {
    setAddClicked(false);
    //
    if (formData.tickets.length >= eventData.limit_tickets_order) {
      setTicketError(true);
      setTicketErrorMessage(t("MAX_TICKETS_ORDER"));
      return;
    } else {
      setTicketError(false);
    }
    // TODO: Chequear stock de tickets con suma de los que hay en este peidod
    const ticketToAddId = ticketsExtrasData[index].id;
    const count = formData.tickets.reduce((accumulator, currentItem) => {
      if (currentItem.ticket_id === ticketToAddId) {
        return accumulator + 1;
      }
      return accumulator;
    }, 0);

    if (count > ticketsExtrasData[index].stock) {
      setTicketError(true);
      setTicketErrorMessage(t("NO_MORE_TICKETS"));
      return;
    }

    //
    let updatedTickets = JSON.parse(JSON.stringify(formData.tickets));
    const extrasData = ticketsExtrasData[index].extras;

    const newExtras = extrasData.map((extra) => ({
      id: extra.id,
      name: extra.name,
      price: extra.price,
      amount: 0,
    }));

    updatedTickets = [
      ...updatedTickets,
      {
        ticket_id: ticketsExtrasData[index].id,
        name: ticketsExtrasData[index].name,
        price: ticketsExtrasData[index].price,
        extras: newExtras,
        internationalDiscount: ticketsExtrasData[index].international_discount,
      },
    ];

    setFormData((prevUser) => ({
      ...prevUser,
      tickets: updatedTickets,
    }));

    const ticketError = JSON.parse(JSON.stringify(ticketErrors));
    const ticketErrorOk = JSON.parse(JSON.stringify(ticketErrorsOk));
    if (newExtras.length > 0) {
      for (let i = 0; i < newExtras.length; i++) {
        ticketError.extras.push(extra);
        ticketErrorOk.extras.push(extra);
      }
    }
    if (eventData.event_type === "trip") {
      const newFormErrors = JSON.parse(JSON.stringify(formErrors));
      newFormErrors.tickets.push(ticketError);
      setFormErrors(newFormErrors);
    } else {
      const newFormErrors = JSON.parse(JSON.stringify(formErrors));
      newFormErrors.tickets.push(ticketErrorOk);
      setFormErrors(newFormErrors);
    }
  };

  const handleChangeCountryNationality = (event, ticketId) => {
    setSelectedCountryNationality(event.target.value);
    formData.tickets[ticketId]["client_nationality"] = event.target.value
      ?.split("-")[0]
      .toUpperCase();

    if (event.target.value !== "") {
      setFormErrors((prev) => ({
        ...prev,
        prev: (prev.tickets[ticketId].client_nationality = ""),
      }));
    }
  };
  const handleCheckboxUserGender = (event, ticketIndex) => {
    const genderValue = event.target.value;
    let updatedTickets = JSON.parse(JSON.stringify(formData.tickets));
    updatedTickets[ticketIndex]["client_gender"] = genderValue;

    setFormData({
      ...formData,
      tickets: updatedTickets,
    });
  };
  const handleChangeDocument = (event, ticketId) => {
    setSelectedDocument(event.target.value);
    formData.tickets[ticketId]["client_document_type"] = event.target.value;

    if (event.target.value !== "") {
      setFormErrors((prev) => ({
        ...prev,
        prev: (prev.tickets[ticketId].client_document_type = ""),
      }));
    }
  };

  const handleExtraAmountChange = (ticketIndex, extraIndex, extraId, amount, regex) => {
    const ticketTemplate = ticketsExtrasData.find(
      (item) => item.id === formData.tickets[ticketIndex].ticket_id
    );
    //Calculate stock of extra
    let orderStock = 0;
    for (let i = 0; i < formData.tickets.length; i++) {
      if (ticketIndex === i) {
        orderStock += parseInt(amount);
      } else {
        orderStock += parseInt(formData.tickets[i].extras[extraIndex].amount);
      }
    }
    let errorValue = "";
    // Check regex
    if (!regex.test(amount)) {
      errorValue = t("INVALID_AMOUNT");
      // Check de maximo por ticket
    } else if (amount > ticketTemplate.extras[extraIndex].stock_by_ticket_max) {
      errorValue = t("MAX_AMOUNT_BY_TICKET");
      // Check de stock
    } else if (orderStock > ticketTemplate.extras[extraIndex].stock) {
      errorValue = t("NO_MORE_EXTRA");
    } else {
      errorValue = "";
    }
    const newErrors = JSON.parse(JSON.stringify(formErrors));
    newErrors.tickets[ticketIndex].extras[extraIndex].amount = errorValue;
    setFormErrors(newErrors);

    // Procceed
    const updatedTickets = JSON.parse(JSON.stringify(formData.tickets));
    const updatedExtras = updatedTickets[ticketIndex].extras;
    updatedExtras[extraIndex].extra_id = extraId;
    updatedExtras[extraIndex].amount = amount;
    setFormData((prevUser) => ({
      ...prevUser,
      tickets: updatedTickets,
    }));
  };
  const handleFieldChange = (event, ticketIndex, regex, helperText) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    const updatedTickets = JSON.parse(JSON.stringify(formData.tickets));
    updatedTickets[ticketIndex][fieldName] = fieldValue;
    setFormData((prevUser) => ({
      ...prevUser,
      tickets: updatedTickets,
    }));
    let errorValue = "";
    if (regex) {
      if (regex.test(fieldValue)) {
        errorValue = "";
      } else {
        errorValue = helperText;
      }
    }

    setFormErrors((prevFormErrors) => {
      const newFormErrors = { ...prevFormErrors };
      newFormErrors.tickets = prevFormErrors.tickets.map((ticket, index) => {
        if (index !== ticketIndex) {
          return ticket;
        }
        return { ...ticket, [fieldName]: errorValue };
      });
      return newFormErrors;
    });
  };

  const removeTicket = (index) => {
    setTicketError(false);
    //
    const updatedTickets = formData.tickets.filter((_, ticketIndex) => ticketIndex !== index);
    setFormData((prev) => ({
      ...prev,
      tickets: updatedTickets,
    }));
    const updatedTicketsErrors = formErrors.tickets.filter(
      (_, ticketIndex) => ticketIndex !== index
    );
    setFormErrors({ ...formErrors, tickets: updatedTicketsErrors });
  };

  const ticketHasPromotionalCode = (index) => {
    const ticketTemplate = ticketsExtrasData.find(
      (item) => item.id === formData.tickets[index].ticket_id
    );
    return (
      ticketTemplate.promotional_code &&
      ticketTemplate.promotional_code_discount &&
      ticketTemplate.promotional_code_discount > 0
    );
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (formData.tickets.length > 0) {
      try {
        const bodyToSend = JSON.parse(JSON.stringify(formData));
        bodyToSend.two_time_payment = doublePayment;
        bodyToSend.cancellation_insurance = false; // Poner esto en el formulario
        for (let i = 0; i < bodyToSend.tickets.length; i++) {
          for (let j = 0; j < bodyToSend.tickets[i].extras.length; j++) {
            bodyToSend.tickets[i].extras[j].amount = parseInt(
              bodyToSend.tickets[i].extras[j].amount
            );
          }
        }
        await createOrder(bodyToSend);
        toastMessageSuccess(t("ASSISTANT_ADDED"));
        setTimeout(() => {
          navigate(`/event/${eventData.id}`);
        }, 1500);
      } catch (error) {
        toastMessageError(error.response?.data?.error || t("ERROR"));
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  // ADD assistant click
  const handleAddAssistant = (e) => {
    e.preventDefault();
    setAddClicked(true);
    // CHECK IF ALL FIELDS ARE FILLED & NO ERRORS
    const allFieldsFilled =
      formData.event_id && formData.user_id && formData.payment_type && formData.tickets.length > 0;
    const noErrors = allFieldsOk(formErrors);
    if (allFieldsFilled && noErrors) {
      handleSubmit(e);
    } else {
      console.log("Errors in form", formErrors);
    }
  };

  const renderTextField = (
    label,
    fieldName,
    value,
    ticketIndex,
    type,
    errorProperty,
    errorText,
    regex,
    _
  ) => (
    <>
      <TextField
        sx={focusColor}
        margin="normal"
        required
        fullWidth
        id={fieldName}
        name={fieldName}
        autoComplete={fieldName}
        label={label}
        value={value}
        type={type ? type : "text"}
        onChange={(e) => handleFieldChange(e, ticketIndex, regex, errorText)}
        inputProps={
          fieldName === "client_document_expiry_date"
            ? {
                min: new Date().toISOString().split("T")[0],
                max: "2100-12-31",
              }
            : fieldName === "client_birth_date"
            ? {
                min: "1900-01-01",
                max: new Date().toISOString().split("T")[0],
              }
            : undefined
        }
        error={
          (errorProperty !== "" && errorProperty !== "empty") ||
          (errorProperty === "empty" && addClicked)
        }
        helperText={errorProperty === "empty" || errorProperty === "" ? "" : errorText}
      />
    </>
  );
  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t("ADD_NEW_ASSISTANT")} />
      <Grid
        container
        spacing={1}
        sx={{
          px: 1,
          color: "black",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          maxWidth: "1200px",
          margin: "10px auto",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            py: 2,
            px: 3,
            width: "100%",
            maxWidth: "1200px",
          }}
        >
          <Box component="form" noValidate sx={{ mt: 2 }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={2}
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Grid item xs={12}>
                <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                  {t("GUEST_DATA")}
                </InputLabel>
                <FormControl
                  fullWidth
                  variant="outlined"
                  sx={focusColor}
                  required
                  error={formErrors.user_id !== "" && addClicked}
                >
                  <Autocomplete
                    id="combo-box-demo"
                    options={usersData} // Asigna las opciones del estado
                    getOptionLabel={(option) => option.user_email || ""} // Define la etiqueta de la opción
                    onChange={handleEmailChange} // Maneja el cambio de selección
                    onInputChange={handleInputChange} // Maneja el cambio en la entrada del usuario
                    inputValue={search} // Controla lo que el usuario escribe
                    value={usersData.find((user) => user.user_id === formData.user_id) || null} // Controla el valor seleccionado
                    isOptionEqualToValue={(option, value) => option.user_id === value.user_id} // Compara opciones
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("EMAIL")}
                        variant="outlined"
                        sx={focusColor}
                        required
                        error={formErrors.user_id !== "" && addClicked}
                      />
                    )}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  variant="outlined"
                  sx={[focusColor, { mt: 2 }]}
                  required
                  error={formErrors.payment_type !== "" && addClicked}
                >
                  <InputLabel id="demo-select-small-label">{t("TYPE_ASSISTANT")}</InputLabel>
                  <Select
                    label={t("TYPE_ASSISTANT")}
                    value={formData.payment_type}
                    onChange={handleTypeAssistant}
                  >
                    <MenuItem value={PAYMENT_OPTIONS.OFFICE_CASH}>{t("OFFICE_CASH")}</MenuItem>
                    <MenuItem value={PAYMENT_OPTIONS.OFFICE_GUEST}>{t("OFFICE_GUEST")}</MenuItem>
                    <MenuItem value={PAYMENT_OPTIONS.OFFICE_CARD}>{t("OFFICE_CARD")}</MenuItem>
                    <MenuItem value={PAYMENT_OPTIONS.OFFICE_TRANSFER_BANK}>
                      {t("OFFICE_TRANSFER_BANK")}
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  fullWidth
                  variant="outlined"
                  sx={[focusColor, { mt: 2 }]}
                  required
                  error={formErrors.domain_name !== "" && addClicked}
                >
                  <InputLabel id="demo-select-small-label">{t("DOMAIN")}</InputLabel>
                  <Select label={t("DOMAIN")} value={formData.domain_name} onChange={handleDomain}>
                    {domainCategories
                      ?.filter((domain) =>
                        domain.categories.some((category) =>
                          eventData.event_categories?.some(
                            (eventCategory) => eventCategory.category_id === category.id
                          )
                        )
                      )
                      .map((domain, index) => (
                        <MenuItem key={`domain-${index}`} value={domain.name}>
                          {domain.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                {eventData.has_double_payment === 1 && (
                  <FormControlLabel
                    sx={{ mt: 2 }}
                    control={
                      <Checkbox
                        checked={doublePayment}
                        onChange={(e) => setDoublePayment(e.target.checked)}
                        name="doublePayment"
                        className="checkbox-oniria"
                      />
                    }
                    label={t("SPLIT_PAYMENT")}
                  />
                )}
              </Grid>
            </Grid>
            <Divider />
            <Grid
              container
              rowSpacing={1}
              columnSpacing={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Grid item xs={12}>
                <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                  {t("ENTRY_DATA")}
                </InputLabel>
                {formData?.tickets?.map((ticket, index) => (
                  <div key={`${index}-${ticket.id}`}>
                    <Box
                      component={Paper}
                      elevation={0}
                      item={index}
                      sx={{
                        py: 2,
                        px: 3,
                        mb: 2,
                        mt: 2,
                        border: "1px solid #E4E4E4",
                        borderRadius: "15px",
                      }}
                    >
                      <Grid item xs={12} sx={{ display: "flex", flexDirection: "row-reverse" }}>
                        <IconButton onClick={() => removeTicket(index)}>
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </Grid>
                      <Grid
                        container
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                        }}
                      >
                        <Grid item xs={8} sx={{ mt: 2 }}>
                          <Typography sx={{ fontSize: "18px" }}>
                            {t("TICKET")}: {ticket.name}{" "}
                            {ticket?.start_date ? ticket.start_date : ""}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ mt: 2 }}>
                          <Typography>
                            {t("PRICE")} :{" "}
                            {ticket.internationalDiscount > 0 && userIsInternational() ? (
                              <>
                                <span style={{ textDecoration: "line-through", color: "red" }}>
                                  {EURO.format(ticket.price)}
                                </span>
                                <br />
                                <span>
                                  {EURO.format(ticket.price - ticket.internationalDiscount)} (
                                  {t("INTERNATIONAL_DISCOUNT")})
                                </span>
                              </>
                            ) : (
                              EURO.format(ticket.price)
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                      {ticket.extras.length > 0 && (
                        <Typography sx={{ fontSize: "18px", mt: 2 }}>{t("EXTRAS")}</Typography>
                      )}
                      {ticket.extras.length > 0 &&
                        ticket.extras?.map((extra, extraIndex) => (
                          <Box
                            key={`${extraIndex}-${extra.id}`}
                            component={Paper}
                            elevation={0}
                            sx={{
                              py: 2,
                              px: 3,
                              mb: 2,
                              border: "1px solid #E4E4E4",
                              borderRadius: "15px",
                            }}
                          >
                            <Grid
                              container
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                              }}
                            >
                              <Grid
                                item
                                xs={8}
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography>
                                  {extra.name + ":    " + extra.price * extra.amount}€
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  value={extra.amount}
                                  onChange={(event) =>
                                    handleExtraAmountChange(
                                      index,
                                      extraIndex,
                                      extra.id,
                                      event.target.value,
                                      /^(?:[0-9]+)?$/
                                    )
                                  }
                                  margin="normal"
                                  required
                                  type="number"
                                  fullWidth
                                  id="amount"
                                  name="amount"
                                  label={t("QUANTITY")}
                                  autoComplete="amount"
                                  sx={focusColor}
                                  inputProps={{
                                    min: 0,
                                  }}
                                  onWheel={(e) => e.target.blur()}
                                  error={
                                    (formErrors.tickets[index].extras[extraIndex].amount !== "" &&
                                      formErrors.tickets[index].extras[extraIndex].amount !==
                                        "empty") ||
                                    (formErrors.tickets[index].extras[extraIndex].amount ===
                                      "empty" &&
                                      addClicked)
                                  }
                                  helperText={
                                    formErrors.tickets[index].extras[extraIndex].amount === ""
                                      ? ""
                                      : formErrors.tickets[index].extras[extraIndex].amount
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        ))}
                      {ticketHasPromotionalCode(index) && (
                        <Grid item xs={12} md={6}>
                          {renderTextField(
                            t("PROMOTIONAL_CODE"),
                            "promotional_code",
                            ticket.promotional_code,
                            index,
                            "text",
                            formErrors.tickets[index].promotional_code,
                            t("MINIMUM_CHARACTERS_ERROR"),
                            /^[A-Za-z0-9]{4,}$|^$/
                          )}
                        </Grid>
                      )}
                      {eventData.event_type === "trip" && (
                        <>
                          <Typography>{t("ASSISTANT_DATA")}</Typography>
                          <FormControlLabel
                            control={
                              <Checkbox checked={showGrid} onChange={handleCheckboxChange} />
                            }
                            label={t("INTRODUCE_CLIENT_DATA")}
                          />
                          {showGrid && (
                            <Grid
                              container
                              rowSpacing={1}
                              columnSpacing={2}
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                mb: 2,
                              }}
                            >
                              <Grid item xs={12} md={6}>
                                {renderTextField(
                                  t("NAME"),
                                  "client_name",
                                  ticket.client_name,
                                  index,
                                  "text",
                                  formErrors.tickets[index].client_name,
                                  t("MAX_50"),
                                  /^(?!.*[|\\#·$~%&¬/()=^[\]+*{}_\d-]).{0,50}$/
                                )}
                              </Grid>
                              <Grid item xs={12} md={6}>
                                {renderTextField(
                                  t("SURNAME"),
                                  "client_surname",
                                  ticket.client_surname,
                                  index,
                                  "text",
                                  formErrors.tickets[index].client_surname,
                                  t("MAX_50"),
                                  /^(?!.*[|\\#·$~%&¬/()=^[\]+*{}_\d-]).{0,50}$/
                                )}
                              </Grid>
                              <Grid item xs={12}>
                                {renderTextField(
                                  t("EMAIL"),
                                  "client_email",
                                  ticket.client_email,
                                  index,
                                  "text",
                                  formErrors.tickets[index].client_email,
                                  t("INCORRECT"),
                                  /^[^\s@]+@[^\s@]+\.[^\s@]+$/
                                )}
                              </Grid>
                              <Grid item xs={12} mt={1}>
                                <InputLabel>{t("BORN_AT")}</InputLabel>
                                {renderTextField(
                                  "",
                                  "client_birth_date",
                                  ticket.client_birth_date,
                                  index,
                                  "date",
                                  formErrors.tickets[index].client_birth_date,
                                  t("INCORRECT"),
                                  null
                                )}
                              </Grid>
                              <Grid item xs={12}>
                                <InputLabel sx={{ mb: 2 }}>{t("NATIONALITY")}</InputLabel>
                                <FormControl
                                  fullWidth
                                  variant="outlined"
                                  sx={focusColor}
                                  error={
                                    formErrors.tickets[index].client_nationality === "empty" &&
                                    addClicked
                                  }
                                >
                                  <InputLabel id="demo-select-small-label">
                                    {t("NATIONALITY")}
                                  </InputLabel>
                                  <Select
                                    label={t("NATIONALITY")}
                                    value={
                                      selectedCountryNationality ? selectedCountryNationality : ""
                                    }
                                    onChange={(e) => handleChangeCountryNationality(e, index)}
                                    renderValue={(selected) => (
                                      <Grid
                                        container
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <img
                                          style={{
                                            height: "20px",
                                            width: "35px",
                                            objectFit: "cover",
                                            marginRight: 10,
                                          }}
                                          alt="Flag"
                                          src={`/media/flags/${selected.split("-")[0]}.svg`}
                                        />
                                        <Typography>{selected.split("-")[1]}</Typography>
                                      </Grid>
                                    )}
                                  >
                                    {countries?.map((country, index) => (
                                      <MenuItem
                                        key={`${index}-${country.country_code}`}
                                        value={
                                          country.country_code.toLowerCase() +
                                          "-" +
                                          country.official_name_en
                                        }
                                      >
                                        <img
                                          style={{
                                            height: "20px",
                                            width: "35px",
                                            objectFit: "cover",
                                            marginRight: 10,
                                          }}
                                          alt="Flag"
                                          src={`/media/flags/${country.country_code.toLowerCase()}.svg`}
                                        />
                                        {country.official_name_en}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} mt={1}>
                                <InputLabel>{t("IDENTIFY")}</InputLabel>
                                <Grid container sx={{ mt: 2, mb: 2 }}>
                                  <Grid item xs={4}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="genre"
                                          checked={
                                            formData.tickets[index].client_gender === "woman"
                                          }
                                          onChange={(e) => handleCheckboxUserGender(e, index)}
                                          value="woman"
                                          sx={{
                                            color: "var(--secondary-color)",
                                            "&.Mui-checked": {
                                              color: "var(--secondary-color)",
                                            },
                                          }}
                                        />
                                      }
                                      label={t("WOMAN")}
                                    />
                                  </Grid>
                                  <Grid item xs={5}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="genre"
                                          checked={formData.tickets[index].client_gender === "man"}
                                          onChange={(e) => handleCheckboxUserGender(e, index)}
                                          value="man"
                                          sx={{
                                            color: "var(--secondary-color)",
                                            "&.Mui-checked": {
                                              color: "var(--secondary-color)",
                                            },
                                          }}
                                        />
                                      }
                                      label={t("MAN")}
                                    />
                                  </Grid>
                                  <Grid item xs={3}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="genre"
                                          checked={
                                            formData.tickets[index].client_gender === "other"
                                          }
                                          onChange={(e) => handleCheckboxUserGender(e, index)}
                                          value="other"
                                          sx={{
                                            color: "var(--secondary-color)",
                                            "&.Mui-checked": {
                                              color: "var(--secondary-color)",
                                            },
                                          }}
                                        />
                                      }
                                      label={t("OTHER")}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <InputLabel>{t("DOCUMENT")}</InputLabel>
                              </Grid>
                              <Grid item xs={4}>
                                <FormControl
                                  fullWidth
                                  sx={focusColor}
                                  error={
                                    formErrors.tickets[index].client_document_type === "empty" &&
                                    addClicked
                                  }
                                >
                                  <InputLabel id="demo-select-small-label">{t("TYPE")}</InputLabel>
                                  <Select
                                    label={t("TYPE")}
                                    value={
                                      formData.tickets[index].client_document_type
                                        ? formData.tickets[index].client_document_type
                                        : selectedDocument
                                    }
                                    onChange={(e) => handleChangeDocument(e, index)}
                                  >
                                    {documents.map((document, index) => (
                                      <MenuItem key={`document-${index}`} value={document}>
                                        {document}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={8} sx={{ mt: -1 }}>
                                {renderTextField(
                                  t("IDENTIFICATION_NUMBER"),
                                  "client_document_id",
                                  formData.client_document_id,
                                  index,
                                  "text",
                                  formErrors.tickets[index].client_document_id,
                                  t("INCORRECT"),
                                  /^(?:[0-9XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]|[A-Z0-9]{3,20})$/i
                                )}
                              </Grid>
                              <Grid item xs={12}>
                                <InputLabel>{t("EXPIRY_DATE_CARD")}</InputLabel>
                                {renderTextField(
                                  t(""),
                                  "client_document_expiry_date",
                                  formData.client_document_expiry_date,
                                  index,
                                  "date",
                                  formErrors.tickets[index].client_document_expiry_date,
                                  t("INCORRECT"),
                                  null
                                )}
                              </Grid>
                            </Grid>
                          )}
                        </>
                      )}
                    </Box>
                  </div>
                ))}
                <InputLabel
                  sx={{
                    fontSize: "18px",
                    color: "black",
                    fontWeight: "bold",
                    mb: 3,
                    textAlign: "center",
                  }}
                >
                  {doublePayment ? t("TOTAL_PRICE_FIRST_PAYMENT") : t("TOTAL_PRICE")}{" "}
                  {getTotalPrice()}
                </InputLabel>
                {ticketError && (
                  <Typography sx={{ color: "#d32f2f", pt: 2 }}>*{ticketErrorMessage}</Typography>
                )}
                {ticketsExtrasData?.map((ticket, index) => (
                  <Button
                    fullWidth
                    variant="outlined"
                    size="large"
                    key={`ticketsExtrasData-${index}`}
                    sx={{
                      mb: 2,
                      color: "var(--secondary-color)",
                      borderColor: "var(--secondary-color)",
                      "&:hover": {
                        color: "var(--secondary-color)",
                        borderColor: "var(--secondary-color)",
                        backgroundColor: "white",
                      },
                    }}
                    onClick={() => addTicket(index)}
                  >
                    {t("ADD_NEW_TICKET") +
                      ": " +
                      ticket.name +
                      "  " +
                      (ticket.start_date
                        ? formatDate(ticket.start_date) + " - " + formatHours(ticket.start_date)
                        : "")}
                  </Button>
                ))}
              </Grid>
            </Grid>
            {formData.tickets.length === 0 && addClicked && (
              <Typography sx={{ color: "#d32f2f", pt: 2 }}>*{t("MINIMUM_TICKET")}</Typography>
            )}
            {!allFieldsOk(formErrors) && addClicked && (
              <Typography sx={{ color: "#d32f2f", pt: 2 }}>*{t("REQUIRED_FIELD")}</Typography>
            )}
            <Button
              type="submit"
              className="oniria-btn"
              fullWidth
              variant="contained"
              onClick={handleAddAssistant}
              disabled={loading}
              sx={{ mt: 3, mb: 2, fontSize: "20px", borderRadius: 3 }}
            >
              {loading ? t("SAVING") : t("ADD_ASSISTANT")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default CreateAssistant;
