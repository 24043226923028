import { z } from "zod";

export const tourLeaderSchema = z.object({
  user_id: z.string().nullable().optional(),
  tour_leader_id: z.string().nullable().optional(),
  name: z.string().nullable().optional(),
  surname: z.string().nullable().optional(),
  born_at: z.string().nullable().optional(),
  genre: z.string().nullable().optional(),
  email: z.string().nullable().optional(),
  phone: z.union([z.string(), z.number()]).nullable().optional(),
  avatar_url: z.any(),
  domains_ids: z.array(z.string().optional()).nullable().optional(),
  owner_premise_id: z.string().optional(),
  languages: z.array(z.string().optional()).nullable().optional(),
});
