import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { RQ_KEY } from "../../constants/query";
import { getAllEventsFiltered } from "../../services/eventsServices";
import { focusColor } from "../shared/textFieldStyle";

function AddEventDialog({ isOpen, onClose, extraId }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedEvent, setSelectedEvent] = useState("");

  const { data: events = [] } = useQuery(
    [RQ_KEY.ALL_EVENTS_FILTERED],
    () => getAllEventsFiltered("", "", "upcoming", "", "", "", "", "", ""),
    {
      refetchOnWindowFocus: false,
      enabled: !!extraId,
    }
  );

  const handleAccept = () => {
    navigate(`/event/${selectedEvent}/extra/${extraId}`);
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ fontWeight: "bolder", color: "var(--oniria-red)", textAlign: "center" }}>
        {t("ADD_EXTRA_TO_EVENT")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ color: "black", textAlign: "center" }}>
          {t("ADD_EXTRA_TO_EVENT_DESCRIPTION")}
        </DialogContentText>
        <FormControl fullWidth sx={[focusColor, { mt: 2 }]}>
          <InputLabel id="demo-select-small-label">{t("EVENTS")}</InputLabel>
          <Select
            label={t("EVENTS")}
            value={selectedEvent}
            onChange={(e) => setSelectedEvent(e.target.value)}
          >
            {events.map((event) => (
              <MenuItem key={event.id} value={event.id}>
                {event.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
        <Button
          type="submit"
          className="oniria-btn-cancel"
          variant="contained"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          onClick={onClose}
        >
          {t("CANCEL")}
        </Button>
        <Button
          type="submit"
          className="oniria-btn"
          variant="contained"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          onClick={handleAccept}
          disabled={!selectedEvent}
        >
          {t("ACCEPT")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddEventDialog;
