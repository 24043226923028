import { z } from "zod";
import i18next from "i18next";

const { t } = i18next;

const urlRegex =
  /^(|https?:\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[^\s]*)?|www\.([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[^\s]*)?)$/;

export const stylesSchema = z.object({
  id: z.string().default("").nullable(),
  grouping_id: z.string().default("").nullable().optional(),
  organization_id: z.string().default("").nullable().optional(),
  premise_id: z.string().default("").nullable().optional(),
  header_type: z.string().default("").nullable().optional(),
  logo_position: z.string().default("").nullable().optional(),
  principal_color: z.string().default("").nullable().optional(),
  text_color: z.string().default("").nullable().optional(),
  header_color: z.string().default("").nullable().optional(),
  header_icons: z.string().default("").nullable().optional(),
  header_icons_color: z.string().default("").nullable().optional(),
  search_bar: z.union([z.boolean().nullable().optional(), z.number().nullable().optional()]),
  footer_type: z.string().default("").nullable().optional(),
  footer_color: z.string().default("").nullable().optional(),
  footer_icons: z.string().default("").nullable().optional(),
  footer_icons_color: z.string().default("").nullable().optional(),
  footer_text_color: z.string().default("").nullable().optional(),
  footer_web_color: z.string().default("").nullable().optional(),
  footer_web: z.string().default("").nullable().optional(),
  font_text: z.string().default("").nullable().optional(),
  lopd: z.string().default("").nullable().optional(),
  footer_logo: z.any(),
  category_styles: z
    .array(
      z.object({
        id: z.string().default("").nullable().optional(),
        style_id: z.string().default("").nullable().optional(),
        category_id: z.string().default("").nullable().optional(),
        subcategory_id: z.string().default("").nullable().optional(),
        format: z.string().default("").nullable().optional(),
        content: z.string().default("").nullable().optional(),
        card_color: z.string().default("").nullable().optional(),
        text_color: z.string().default("").nullable().optional(),
      })
    )
    .default([])
    .nullable()
    .optional(),
  default_language: z.string().default("").nullable().optional(),
  logo_url: z.any(),
  icon_url: z.any(),
  image_url: z.any(),
  video_url: z.any(),
  reel_format: z.string().default("").nullable().optional(),
  reel_images_url: z.any(),
  reel_color: z.string().default("").nullable().optional(),
  border_header: z.string().default("").nullable().optional(),
  app_name: z
    .string()
    .max(100, { message: t("MAX_100") })
    .default("")
    .nullable(),
  web_name: z
    .string()
    .max(100, { message: t("MAX_100") })
    .default("")
    .nullable(),
  footer_phone: z
    .string()
    .max(100, { message: t("MAX_100") })
    .default("")
    .nullable(),
  footer_cif: z
    .string()
    .max(100, { message: t("MAX_100") })
    .default("")
    .nullable(),
  footer_email: z.string().email().or(z.literal("")).nullable().optional(),
  footer_address: z
    .string()
    .max(100, { message: t("MAX_100") })
    .default("")
    .nullable(),
  reel_title: z
    .string()
    .max(50, { message: t("MAX_50") })
    .default("")
    .nullable(),
  reel_description: z
    .string()
    .max(80, { message: t("MAX_80") })
    .default("")
    .nullable(),
  sponsors_background_color: z.string().default("").nullable().optional(),
  card_premise_id: z.string().default("").nullable().optional(),
  dark_logo_url: z.any(),
  social_instagram_url: z
    .string()
    .regex(urlRegex, { message: t("INVALID_URL") })
    .nullable()
    .optional(),
  social_facebook_url: z
    .string()
    .regex(urlRegex, { message: t("INVALID_URL") })
    .nullable()
    .optional(),
  social_whatsapp_url: z
    .string()
    .regex(urlRegex, { message: t("INVALID_URL") })
    .nullable()
    .optional(),
  social_twitter_url: z
    .string()
    .regex(urlRegex, { message: t("INVALID_URL") })
    .nullable()
    .optional(),
  social_youtube_url: z
    .string()
    .regex(urlRegex, { message: t("INVALID_URL") })
    .nullable()
    .optional(),
  social_tiktok_url: z
    .string()
    .regex(urlRegex, { message: t("INVALID_URL") })
    .nullable()
    .optional(),
  social_spotify_url: z
    .string()
    .regex(urlRegex, { message: t("INVALID_URL") })
    .nullable()
    .optional(),
  social_itune_url: z
    .string()
    .regex(urlRegex, { message: t("INVALID_URL") })
    .nullable()
    .optional(),
  social_soundcloud_url: z
    .string()
    .regex(urlRegex, { message: t("INVALID_URL") })
    .nullable()
    .optional(),
});

export const sweepSchema = z.object({
  id: z.string().default("").nullable().optional(),
  domain_id: z.string().default("").nullable().optional(),
  title: z.string().default("").nullable().optional(),
  subtitle: z.string().default("").nullable().optional(),
  link: z.string().default("").nullable().optional(),
  image_url: z.any(),
  position: z.any(),
});

export const domainSchema = z.object({
  grouping_id: z.string().default("").nullable().optional(),
  grouping_name: z.string().default("").nullable().optional(),
  name: z.string().default("").nullable().optional(),
  organization_id: z.string().default("").nullable().optional(),
  organization_name: z.string().default("").nullable().optional(),
  premise_id: z.string().default("").nullable().optional(),
  premise_name: z.string().default("").nullable().optional(),
  style: stylesSchema.default({}),
  android_url: z.string().default("").nullable().optional(),
  ios_url: z.string().default("").nullable().optional(),
  sweeps: z.array(sweepSchema).default([]),
});

export const sponsorSchema = z.object({
  name: z.string().min(1, { message: t("REQUIRED_FIELD") }),
  link: z.string().optional().nullable(),
  logo: z.object({
    image: z.string().min(1, { message: t("REQUIRED_FIELD") }),
    mime: z.string().min(1, { message: t("REQUIRED_FIELD") }),
  }),
});
