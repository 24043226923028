import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { getAllBlogPostsFiltered } from "../../../services/blogServices";

export function useQueryBlogs(filters, page, perPage, onSuccess) {
  return useQuery(
    [RQ_KEY.ALL_BLOGS, filters, page, perPage],
    () => getAllBlogPostsFiltered(filters, perPage, page),
    {
      onSuccess: onSuccess,
      refetchOnWindowFocus: false,
    }
  );
}
