import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import useQueryTourPointsList from "../../hooks/queries/tourPoints/useQueryTourPointsList";
import { ASSIGNED_TAB } from "../../utils/hotels";
import SearchFilter from "../events/filters/filter/SearchFilter";
import SelectFilter from "../events/filters/filter/SelectFilter";

const TransportsFilters = ({ onSearchChange, filters, updateFilter, selectedTab }) => {
  const { t } = useTranslation();

  const { startPoint, endPoint, transit } = filters;

  const { data: tourPoints = [] } = useQueryTourPointsList();

  const transitOptions = [
    { id: "all", name: t("ALL") },
    { id: "upcoming", name: t("UPCOMING") },
    { id: "present", name: t("IN_TRANSIT") },
    { id: "past", name: t("PAST") },
  ];

  return (
    <Grid
      container
      spacing={1}
      mt={2}
      sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
    >
      <Grid item xs={12} md={3}>
        <SearchFilter onSearchChange={onSearchChange} />
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={11}
        md={9}
        columnGap={1}
        rowGap={1}
        justifyContent="flex-end"
      >
        {selectedTab === ASSIGNED_TAB && (
          <>
            <SelectFilter
              value={transit}
              onChange={(value) => updateFilter("transit", value)}
              options={transitOptions}
            />
            <SelectFilter
              value={startPoint}
              onChange={(value) => updateFilter("startPoint", value)}
              options={[{ id: "all", name: t("START_POINT") }, ...tourPoints]}
            />
            <SelectFilter
              value={endPoint}
              onChange={(value) => updateFilter("endPoint", value)}
              options={[{ id: "all", name: t("END_POINT") }, ...tourPoints]}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default TransportsFilters;
