import { DeleteOutline, EditOutlined, RemoveRedEyeOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getDomainName, getPremiseName } from "../../constants/utils";
import OrderButtons from "../shared/OrderButtons";

const TourPointsTable = ({
  data,
  filters,
  handleOrder,
  handleEdit,
  handleSee,
  handleOpenDelete,
  premises,
  domains,
}) => {
  const { t } = useTranslation();

  return (
    <TableContainer component={Paper} sx={{ borderRadius: "20px", mb: 2 }}>
      <Table sx={{ whiteSpace: "nowrap" }}>
        <TableHead>
          <TableRow sx={{ borderBottom: "2px solid var(--secondary-color)" }}>
            <TableCell></TableCell>
            <TableCell>
              <OrderButtons
                columnName={t("NAME")}
                id="name"
                order={filters}
                handleClickAsc={handleOrder}
                handleClickDesc={handleOrder}
              />
            </TableCell>
            <TableCell>
              <OrderButtons
                columnName={t("DOMAIN")}
                id="d.name"
                order={filters}
                handleClickAsc={handleOrder}
                handleClickDesc={handleOrder}
              />
            </TableCell>
            <TableCell>
              <OrderButtons
                columnName={t("PREMISE")}
                id="owner_premise_id"
                order={filters}
                handleClickAsc={handleOrder}
                handleClickDesc={handleOrder}
              />
            </TableCell>
            <TableCell>
              <OrderButtons
                columnName={t("ASSIGNED")}
                id="assigned"
                order={filters}
                handleClickAsc={handleOrder}
                handleClickDesc={handleOrder}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row) => (
            <TableRow
              key={row.id}
              sx={{
                backgroundColor: row.is_active === 0 ? "var(--grey-cancelled)" : "",
                whiteSpace: "nowrap",
              }}
            >
              <TableCell>
                <Avatar alt={row.name} src={row.image_url} />
              </TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>
                {row.domains_ids && row.domains_ids.length > 0
                  ? row.domains_ids.map((domain, index) => {
                      return <p key={`domain-${index}`}>{getDomainName(domain, domains)}</p>;
                    })
                  : null}
              </TableCell>
              <TableCell>{getPremiseName(row.owner_premise_id, premises)}</TableCell>
              <TableCell>{row.itineraries_using_it}</TableCell>
              <TableCell>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  {/* <Tooltip title={t("SEE_EVENTS")}>
                    <IconButton onClick={() => handleSee(row.id)}>
                      <RemoveRedEyeOutlined sx={{ color: "var(--oniria-gold)" }} />
                    </IconButton>
                  </Tooltip> */}
                  <Tooltip title={t("EDIT_TOUR_POINT")}>
                    <IconButton onClick={() => handleEdit(row.id)}>
                      <EditOutlined sx={{ color: "var(--oniria-gold)" }} />
                    </IconButton>
                  </Tooltip>
                  {row.itineraries_using_it === 0 ? (
                    <Tooltip title={t("DELETE_TOUR_POINT")}>
                      <IconButton onClick={() => handleOpenDelete(row.id)}>
                        <DeleteOutline sx={{ color: "var(--oniria-gold)" }} />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TourPointsTable;
