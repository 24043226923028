import { z } from "zod";
import i18next from "i18next";

const { t } = i18next;

export const tourPointLanguageInfoSchema = z.object({
  id: z.string().nullable().optional(),
  name: z.string().nullable().optional(),
  language: z.string().nullable().optional(),
  short_description: z.string().nullable().optional(),
  long_description: z
    .union([z.string(), z.record(z.any())])
    .nullable()
    .optional(),
  pdf_info: z.string().nullable().optional(),
});

export const tourPointSchema = z.object({
  id: z.string().nullable().optional(),
  name: z.string().min(2, { message: t("REQUIRED_FIELD") }),
  sku: z.string().nullable().optional(),
  short_description: z.string().nullable().optional(),
  long_description: z
    .union([z.string(), z.record(z.any())])
    .nullable()
    .optional(),
  image_url: z
    .union([z.string(), z.record(z.any())])
    .nullable()
    .optional(),
  image_gallery_urls: z.any(),
  google_maps_url: z.string().nullable().optional(),
  latitude: z.union([z.string(), z.number()]).nullable().optional(),
  longitude: z.union([z.string(), z.number()]).nullable().optional(),
  video_url: z.string().nullable().optional(),
  pdf_info: z.string().nullable().optional(),
  domains_ids: z.array(z.string()).min(1, { message: "required" }),
  owner_premise_id: z.string().nonempty({ message: "required" }),
   country_code: z.string().nullable().optional(),
  region_code: z.string().nullable().optional(),
  city_id: z.string().nullable().optional(),
  default_language: z.string().default("ES"),

  tour_point_language_infos: z.array(tourPointLanguageInfoSchema).optional().nullable(),
});

export const defaultTourPointLanguage = {
  id: "",
  name: "",
  language: "",
  short_description: "",
  long_description: "",
  pdf_info: "",
};
