import { FormControl, InputLabel, NativeSelect, Select, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { focusColor } from "./textFieldStyle";
import { useTranslation } from "react-i18next";

const SelectHookForm = ({
  control,
  label = "",
  error,
  options = [],
  disabled = false,
  name,
  defaultValue = "",
  required = false,
  multiple = false,
}) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth sx={focusColor}>
      <InputLabel className="form-input-label">{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{ required: required }}
        render={({ field }) => (
          <Select
            {...field}
            label={label}
            className="input-select form-select-field"
            error={!!error}
            slotProps={{ input: { readOnly: disabled } }}
            multiple={multiple}
            required={required}
          >
            {options}
          </Select>
        )}
      />
      {!!error && <Typography className="form-input-error">{t(error.message)}</Typography>}
    </FormControl>
  );
};

export default SelectHookForm;
