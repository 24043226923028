import { AddOutlined } from "@mui/icons-material";
import { Button, Divider, Grid } from "@mui/material";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { defaultHotelRoomLanguageInfo, defaultRoom } from "../../../schemas/hotels";
import HotelRoomForm from "./HotelRoomForm";

const HotelRooms = () => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext();
  const rooms = watch("rooms") || [];

  const handleDeleteRoom = (index) => {
    const newRooms = rooms.filter((_, i) => i !== index);
    setValue("rooms", newRooms);
  };

  const handleAddRoom = () => {
    const newRoom = JSON.parse(JSON.stringify(defaultRoom));
    setValue("rooms", [...rooms, newRoom]);
  };

  const handleChangeCheckbox = (isChecked, value, index) => {
    const newRooms = [...rooms];
    const languageInfos = newRooms[index].hotel_room_language_infos || [];

    if (isChecked) {
      newRooms[index].hotel_room_language_infos = [
        ...languageInfos,
        { ...defaultHotelRoomLanguageInfo, language: value },
      ];
    } else {
      newRooms[index].hotel_room_language_infos = languageInfos.filter(
        (langInfo) => langInfo.language !== value
      );
    }

    setValue("rooms", newRooms);
  };

  const getRoomLanguageIndex = (index, language) => {
    return rooms[index].hotel_room_language_infos.findIndex((lang) => lang.language === language);
  };

  const handleChangeDescription = (data, index) => {
    setValue(`rooms[${index}].description`, data);
  };

  const handleChangeLanguageDescription = (data, index, language) => {
    const newRooms = JSON.parse(JSON.stringify(rooms));
    newRooms[index].hotel_room_language_infos[getRoomLanguageIndex(index, language)].description =
      data;
    setValue("rooms", newRooms);
  };

  useEffect(() => {
    if (rooms.length === 0) {
      setValue("rooms", [JSON.parse(JSON.stringify(defaultRoom))]);
    }
  }, []);

  return (
    <Grid item xs={12} container>
      <Divider sx={{ width: "100%", borderColor: "var(--secondary-color)", mt: 2, mb: 2 }} />
      {rooms.map((room, index) => (
        <HotelRoomForm
          key={index}
          room={room}
          index={index}
          handleDeleteRoom={handleDeleteRoom}
          handleChangeCheckbox={handleChangeCheckbox}
          handleChangeDescription={handleChangeDescription}
          handleChangeLanguageDescription={handleChangeLanguageDescription}
          getRoomLanguageIndex={getRoomLanguageIndex}
        />
      ))}
      <Button
        onClick={handleAddRoom}
        variant="contained"
        className="oniria-btn"
        sx={{ mt: 2 }}
        startIcon={<AddOutlined />}
      >
        {t("ADD_ROOM")}
      </Button>
    </Grid>
  );
};

export default HotelRooms;
