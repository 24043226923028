import { Close, CloudUpload } from "@mui/icons-material";
import { Box, Button, Grid, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const PdfUpload = ({ onChange, value, disabled = false }) => {
  const { t } = useTranslation();
  const [selectedPdf, setSelectedPdf] = useState(null);

  useEffect(() => {
    const pdfData = value?.startsWith("data:application/pdf;base64,");
    const newValue = pdfData ? value : value ? `data:application/pdf;base64,${value || ""}` : null;
    setSelectedPdf(newValue);
  }, [value]);

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handlePdfUpload(files);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
  };

  const handleFileInput = (event) => {
    const files = event.target.files;
    handlePdfUpload(files);
  };

  const handlePdfUpload = (files) => {
    if (!files || files.length === 0) return;

    const file = files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const pdfDataUrl = reader.result;
      setSelectedPdf(pdfDataUrl);
      onChange && onChange(pdfDataUrl);
    };
    reader.readAsDataURL(file);
  };

  const handleDelete = () => {
    setSelectedPdf(null);
    onChange && onChange("");
  };

  return (
    <Grid
      container
      sx={{
        flexGrow: 1,
        textAlign: "center",
        width: "100%",
        borderRadius: "20px",
        padding: "20px",
        cursor: "pointer",
        minHeight: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      className="dropZone"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
    >
      {!selectedPdf ? (
        <>
          {disabled ? (
            <Box
              component="img"
              alt="Upload"
              src={selectedPdf ? selectedPdf : "/media/upload-pdf.svg"}
              sx={{ height: 120, width: 170, mt: 2, paddingLeft: "10px", marginBottom: "10px" }}
            />
          ) : selectedPdf ? (
            <>
              <Box
                component="img"
                alt="Upload"
                src={selectedPdf}
                sx={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
              />
              <IconButton
                sx={{ mt: 1, width: 50, height: 50, textAlign: "center" }}
                onClick={handleDelete}
              >
                <Close fontSize="small" />
              </IconButton>
            </>
          ) : (
            <Grid>
              <CloudUpload sx={{ fontSize: 40 }} />
              <p>{t("TRAWL_UPLOAD")}</p>
            </Grid>
          )}
        </>
      ) : (
        <>
          <Box
            component="embed"
            src={selectedPdf}
            type="application/pdf"
            sx={{ width: "100%", height: "100%" }}
          />
          {!disabled && (
            <IconButton
              sx={{ mt: 1, width: 50, height: 50, textAlign: "center" }}
              onClick={handleDelete}
            >
              <Close fontSize="small" />
            </IconButton>
          )}
        </>
      )}
      <Grid item xs={12}>
        <Button
          variant="outlined"
          sx={{ mt: 3, mb: 2, px: 3, fontSize: "15px", borderRadius: 3 }}
          component="label"
          className="oniria-border-btn"
        >
          {t("UPLOAD_PDF")}
          <input type="file" accept="application/pdf" hidden onChange={handleFileInput} />
        </Button>
      </Grid>
    </Grid>
  );
};

export default PdfUpload;
