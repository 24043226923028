import { MONTHS_MAP, WEEK_DAYS_MAP } from "./variables";

export function formatDate(date) {
  if (!date) return "";
  const dateObj = new Date(date);
  const day = String(dateObj.getUTCDate()).padStart(2, "0");
  const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0");
  const year = dateObj.getUTCFullYear();
  return `${day}/${month}/${year}`;
}

export function formatHours(date) {
  if (!date) return "";
  const dateObj = new Date(date);
  const hours = String(dateObj.getUTCHours()).padStart(2, "0");
  const minutes = String(dateObj.getUTCMinutes()).padStart(2, "0");
  const formattedTime = `${hours}:${minutes}`;
  return formattedTime;
}

export function formatDateYMD(date) {
  const newDate = new Date(date);
  const yyyy = newDate.getUTCFullYear();
  const mm = String(newDate.getUTCMonth() + 1).padStart(2, "0");
  const dd = String(newDate.getUTCDate()).padStart(2, "0");
  return `${yyyy}-${mm}-${dd}`;
}

export function formatMuiDate(date) {
  if (!date) return "";
  var fecha = new Date(date);
  var año = fecha.getFullYear();
  var mes = fecha.getMonth() + 1;
  var dia = fecha.getDate();

  mes = mes < 10 ? "0" + mes : mes;
  dia = dia < 10 ? "0" + dia : dia;
  return `${año}-${mes}-${dia}`;
}

export function compareDates(date1, date2) {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  return d1.getTime() === d2.getTime();
}

export function getAgeByBirth(date) {
  const birthDate = new Date(date);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export const EURO = new Intl.NumberFormat("es", {
  style: "currency",
  currency: "EUR",
});

//check if a erasmus card is expired
export const isValidCardDate = (date) => {
  if (date === null) return true;
  const today = new Date();
  const cardDate = new Date(date);
  return today < cardDate;
};

export function changeEmptyStringNull(obj) {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] === "" || obj[key] === "ESNULO") {
        obj[key] = null;
      } else if (typeof obj[key] === "object") {
        changeEmptyStringNull(obj[key]);
      }
    }
  }
  return obj;
}
export function convertDate(isoDate) {
  const date = new Date(isoDate);
  // Obtener el día, mes y año
  const utcDay = date.getUTCDate();
  const utcMonth = date.getUTCMonth();
  const utcWeekday = date.getUTCDay();

  const formattedDate = `${WEEK_DAYS_MAP[utcWeekday]} ${utcDay} ${MONTHS_MAP[utcMonth]}`;

  return formattedDate;
}

export function formatCurrency(number = 0) {
  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  }).format(number);
}

export const getOneWeekFromToday = () => {
  const today = new Date();
  const oneWeekAgo = new Date(today);
  oneWeekAgo.setDate(today.getDate() - 7);
  return formatMuiDate(oneWeekAgo);
};

const keysToSkip = [
  "organization_id",
  "premise_id",
  "grouping_id",
  "card_premise_id",
  "category_styles",
  "event_categories",
  "publication_date",
  "publication_date_time",
  "end_of_publication_date",
  "end_of_publication_date_time",
  "start_sale_date",
  "start_sale_date_time",
  "end_sale_date",
  "end_sale_date_time",
  "second_payment_end_date",
  "second_payment_end_date_time",
  "second_payment_start_date",
  "second_payment_start_date_time",
  "event_language_infos",
  "start_date",
  "start_date_time",
  "end_date",
  "end_date_time",
];

export const changeNullToEsNul = (obj) => {
  // Verificar si obj no es un objeto o es null
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }
  for (let key in obj) {
    if (keysToSkip.includes(key)) {
      continue;
    }
    if (obj[key] === null || obj[key] === "") {
      obj[key] = "ESNULO";
    }
    if (typeof obj[key] === "object" && obj[key] !== "event_language_infos") {
      changeNullToEsNul(obj[key]);
    }
  }

  return obj;
};

export function normalizeVisibility(is_visible) {
  return is_visible === true || is_visible === 1 ? true : false;
}

export function getTimeFromNow() {
  // to set actual date and time when create event
  var offset = new Date().getTimezoneOffset() * 60000;
  var localISOTime = new Date(Date.now() - offset);

  const now = localISOTime;
  const [hours, minutes] = now.toISOString().split("T")[1].split(":");
  const time = `${hours}:${minutes}`;

  return { time, now };
}

//START EVENT URL FUNCTIONS
export const endsWithNumber = (str) => {
  return /\d+$/.test(str);
};

export const extractNumber = (str) => {
  const match = str.match(/-(\d+)$/);
  return match ? parseInt(match[1], 10) : null;
};

export const generateNextUrl = (str) => {
  const currentNumber = extractNumber(str);
  const nextNumber = currentNumber !== null ? currentNumber + 1 : 1;
  return `${str.replace(/-\d+$/, "")}-${nextNumber.toString().padStart(2, "0")}`;
};

export const transformNameToUrl = (name) => {
  return name
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[^a-zA-Z0-9 -]/g, "")
    .replace(/[\u0080-\uFFFF]/g, "")
    .replace(/ /g, "-")
    .toLowerCase();
};

//END EVENT URL FUNCTIONS

// Transform "2021-09-01T00:00:00.000Z" to "2021-09-01 00:00"
export const dateWithoutTZ = (date) => {
  if (date.includes("T") && date.includes("Z")) {
    const formattedDate = date.replace("Z", "").replace("T", " ").split(":").slice(0, 2).join(":");
    return formattedDate;
  } else {
    return date;
  }
};

export const getPremiseName = (premiseId, premises) => {
  if (!premiseId || !premises) return "";
  const premise = premises?.find((premise) => premise.id === premiseId);
  return premise?.name || "";
};

export const getDomainName = (domainId, domains) => {
  if (!domainId || !domains) return "";
  const domain = domains?.find((domain) => domain.domain_id === domainId);
  return domain?.domain_name || "";
};

export const getRoutePointName = (routePointId, routePoints) => {
  if (!routePointId || !routePoints) return "";
  const routePoint = routePoints?.find((routePoint) => routePoint.id === routePointId);
  return routePoint?.name || "";
};

export const formatDateMonthYear = (date) => {
  if (!date) return null;
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  return `${month}/${year}`;
};

export const getCurrentMonth = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  return `${year}-${month}`;
};
export const sanitizeForSending = (object) => {
  if (!object) return null;
  if (typeof object === "string") return object;
  const objectToSend = JSON.stringify(object);
  return objectToSend;
};

export const sanitizeBoolean = (value) => {
  return value === true || value === 1 ? true : false;
};

export const convertStringToObject = (string) => {
  if (!string) return null;
  if (typeof string === "string") return JSON.parse(string);
  return string;
};

export const sanitizeFloat = (string) => {
  if (!string || isNaN(string)) return null;
  return parseFloat(string);
};

export const sanitizeInt = (string) => {
  if (!string || isNaN(string)) return null;
  return parseInt(string);
};
