import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQueryPremisesList } from "../../hooks/queries/useQueryPremisesList";
import { focusColor } from "../shared/textFieldStyle";

const ExtrasFilters = ({ onSearchChange, filters, updateFilter }) => {
  const { t } = useTranslation();

  const { premiseId } = filters;

  const { data: premises } = useQueryPremisesList();

  return (
    <Grid
      container
      spacing={1}
      sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
    >
      <Grid item xs={12} md={3}>
        <TextField
          label={t("SEARCH")}
          onChange={onSearchChange}
          size="small"
          sx={[focusColor, { width: "75%" }]}
        />
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={11}
        md={9}
        columnGap={1}
        rowGap={1}
        justifyContent="flex-end"
      >
        <FormControl size="small" sx={[focusColor, { minWidth: 120 }]}>
          <InputLabel>{t("PREMISES")}</InputLabel>
          <Select
            value={premiseId}
            label={t("PREMISES")}
            onChange={(e) => updateFilter("premiseId", e.target.value)}
          >
            <MenuItem value="">{t("ALL_SITES")}</MenuItem>
            {premises?.map((premise) => (
              <MenuItem key={premise.id} value={premise.id}>
                {premise.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default ExtrasFilters;
