import { z } from "zod";

// ROOMS
const hotelRoomLanguages = z
  .object({
    id: z.string().nullable().optional(),
    hotel_room_id: z.string().nullable().optional(),
    language: z.string().nullable().optional(),
    name: z.string().nullable().optional(),
    description: z
      .union([z.string(), z.record(z.any())])
      .nullable()
      .optional(),
  })
  .nullable()
  .optional();

export const hotelRoomSchema = z
  .object({
    id: z.string().nullable().optional(),
    hotel_id: z.string().nullable().optional(),
    name: z.string().nullable().optional(),
    description: z
      .union([z.string(), z.record(z.any())])
      .nullable()
      .optional(),
    stock: z.union([z.string(), z.number()]).nullable().optional(),
    price: z.union([z.string(), z.number()]).nullable().optional(),
    beds_amount: z.union([z.string(), z.number()]).nullable().optional(),
    pension: z.string().nullable().optional(),
    default_language: z.string().default("ES"),
    hotel_room_language_infos: z.array(hotelRoomLanguages).optional().nullable(),
  })
  .nullable()
  .optional();

// EXTRA OPTIONS
export const hotelExtraLanguages = z
  .object({
    id: z.string().nullable().optional(),
    hotel_extra_option_id: z.string().nullable().optional(),
    language: z.string().nullable().optional(),
    name: z.string().nullable().optional(),
    description: z
      .union([z.string(), z.record(z.any())])
      .nullable()
      .optional(),
  })
  .nullable()
  .optional();

export const hotelExtraOptionsSchema = z
  .object({
    id: z.string().nullable().optional(),
    hotel_id: z.string().nullable().optional(),
    name: z.string().nullable().optional(),
    description: z
      .union([z.string(), z.record(z.any())])
      .nullable()
      .optional(),
    price: z.string().nullable().optional(),
    default_language: z.string().default("ES"),
    hotel_extra_option_language_infos: z.array(hotelExtraLanguages).nullable().optional(),
  })
  .nullable()
  .optional();

// HOTEL
export const hotelLanguageInfoSchema = z
  .object({
    id: z.string().nullable().optional(),
    hotel_id: z.string().nullable().optional(),
    language: z.string().nullable().optional(),
    name: z.string().nullable().optional(),
    description: z
      .union([z.string(), z.record(z.any())])
      .nullable()
      .optional(),
    pdf_info: z.string().nullable().optional(),
  })
  .optional()
  .nullable();

export const hotelSchema = z.object({
  id: z.string().nullable().optional(),
  sku: z.string().nullable().optional(),
  name: z.string().nullable().optional(),
  description: z
    .union([z.string(), z.record(z.any())])
    .nullable()
    .optional(),
  short_description: z.string().nullable().optional(),
  img_url: z.any(),
  address: z.string().nullable().optional(),
  stars: z.string().nullable().optional(),
  owner_premise_id: z.string().nullable().optional(),
  default_language: z.string().default("ES"),

  hotel_language_infos: z.array(hotelLanguageInfoSchema).optional().nullable(),

  tour_point_id: z.string().nullable().optional(),
  domains_ids: z.array(z.string().optional()).nullable().optional(),
  image_gallery_urls: z.any(),
  pdf_info: z.string().nullable().optional(),
  social_media_urls: z.array(
    z
      .object({
        name: z.string().nullable().optional(),
        url: z.string().nullable().optional(),
      })
      .nullable()
      .optional()
  ),

  rooms: z.array(hotelRoomSchema).nullable().optional(),
  extra_options: z.array(hotelExtraOptionsSchema).nullable().optional(),
});

// DEFAULTS
export const defaultHotelLanguageInfo = {
  id: "",
  hotel_id: "",
  language: "",
  name: "",
  description: "",
};

export const defaultHotelRoomLanguageInfo = {
  id: "",
  hotel_room_id: "",
  language: "",
  name: "",
  description: "",
};

export const defaultHotelExtraLanguageInfo = {
  id: "",
  hotel_extra_option_id: "",
  language: "",
  name: "",
  description: "",
};

export const defaultRoom = {
  name: "",
  description: "",
  stock: "",
  beds_amount: "",
};

export const defaultHotelExtra = {
  name: "",
  description: "",
  price: "",
  hotel_extra_option_language_infos: [],
};
