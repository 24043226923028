import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import useUserRoles from "../../hooks/useUserRoles";

const MobileNavBar = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isAffiliate, isSuperAdmin, isAdmin, isManager, canPublishTrips } = useUserRoles();

  return (
    <>
      <List sx={{ display: "flex", flexDirection: "column", color: "white" }}>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              navigate(ROUTES.HOME);
              props.setIsMobileOpen(!props.isMobileOpen);
            }}
          >
            <ListItemText primary={t("HOME")} />
          </ListItemButton>
        </ListItem>
        {/* EVENTOS */}
        <Accordion
          sx={{
            backgroundColor: "var(--secondary-color)",
            color: "#fff",
            border: 0,
            boxShadow: "none",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
            sx={{
              gap: "10px",
              display: "flex",
            }}
          >
            {t("PRODUCTS")}
          </AccordionSummary>
          <AccordionDetails>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(ROUTES.EVENTS);
                  props.setIsMobileOpen(!props.isMobileOpen);
                }}
              >
                <ListItemText primary={t("EVENT_MANAGER")} />
              </ListItemButton>
            </ListItem>
            {(canPublishTrips || isSuperAdmin) && (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate(ROUTES.TRIPS);
                    props.setIsMobileOpen(!props.isMobileOpen);
                  }}
                >
                  <ListItemText primary={t("TRAVEL_MANAGER")} />
                </ListItemButton>
              </ListItem>
            )}
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(ROUTES.CALENDAR);
                  props.setIsMobileOpen(!props.isMobileOpen);
                }}
              >
                <ListItemText primary={t("CALENDAR")} />
              </ListItemButton>
            </ListItem>
            <Accordion
              sx={{
                backgroundColor: "var(--secondary-color)",
                color: "#fff",
                border: 0,
                boxShadow: "none",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
                sx={{
                  gap: "10px",
                  display: "flex",
                }}
              >
                {t("RESOURCES")}
              </AccordionSummary>
              <AccordionDetails>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate(ROUTES.EXTRAS);
                      props.setIsMobileOpen(!props.isMobileOpen);
                    }}
                  >
                    <ListItemText primary={t("EXTRAS_MANAGER")} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate(ROUTES.TOUR_POINTS);
                      props.setIsMobileOpen(!props.isMobileOpen);
                    }}
                  >
                    <ListItemText primary={t("TOUR_POINTS")} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate(ROUTES.TRANSPORTS);
                      props.setIsMobileOpen(!props.isMobileOpen);
                    }}
                  >
                    <ListItemText primary={t("TRANSPORTS")} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate(ROUTES.HOTELS);
                      props.setIsMobileOpen(!props.isMobileOpen);
                    }}
                  >
                    <ListItemText primary={t("HOSTING")} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate(ROUTES.TOUR_LEADERS);
                      props.setIsMobileOpen(!props.isMobileOpen);
                    }}
                  >
                    <ListItemText primary={t("MANAGER_TOUR_LEADERS")} />
                  </ListItemButton>
                </ListItem>
              </AccordionDetails>
            </Accordion>
          </AccordionDetails>
        </Accordion>
        {/* SALES */}
        {(isSuperAdmin || isManager || isAdmin || isAffiliate) && (
          <Accordion
            sx={{
              backgroundColor: "var(--secondary-color)",
              color: "#fff",
              border: 0,
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
              sx={{
                gap: "10px",
                display: "flex",
              }}
            >
              {t("SALES")}
            </AccordionSummary>
            <AccordionDetails>
              {/* CLIENTS MANAGER */}
              {(isSuperAdmin || isAffiliate) && (
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate(ROUTES.USERS);
                      props.setIsMobileOpen(!props.isMobileOpen);
                    }}
                  >
                    <ListItemText primary={t("MANAGER_CLIENTS")} />
                  </ListItemButton>
                </ListItem>
              )}
              {/* ALL TYPES ORDERS */}
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate(ROUTES.ORDERS);
                    props.setIsMobileOpen(!props.isMobileOpen);
                  }}
                >
                  <ListItemText primary={t("ORDERS")} />
                </ListItemButton>
              </ListItem>
              {/* CARD SALES */}
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate(ROUTES.CARDS);
                    props.setIsMobileOpen(!props.isMobileOpen);
                  }}
                >
                  <ListItemText primary={t("MEMBER_CARDS")} />
                </ListItemButton>
              </ListItem>
              {/* STATISTICS */}
              {(isSuperAdmin || isManager || isAdmin || isAffiliate) && (
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate(ROUTES.STATISTICS);
                      props.setIsMobileOpen(!props.isMobileOpen);
                    }}
                  >
                    <ListItemText primary={t("STATISTICS")} />
                  </ListItemButton>
                </ListItem>
              )}
              {/* BILLING CLIENT-SELLER */}
              {(isSuperAdmin || isManager || isAdmin) && (
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate(ROUTES.SALES);
                      props.setIsMobileOpen(!props.isMobileOpen);
                    }}
                  >
                    <ListItemText primary={t("SALES")} />
                  </ListItemButton>
                </ListItem>
              )}
              {/* BILLING  WITH ONIRIA */}
              {(isSuperAdmin || isManager || isAdmin) && (
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate("/billing");
                      props.setIsMobileOpen(!props.isMobileOpen);
                    }}
                  >
                    <ListItemText primary={t("BILLING_PLUS")} />
                  </ListItemButton>
                </ListItem>
              )}
              {(isSuperAdmin || isManager || isAdmin) && (
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate("/refunds");
                      props.setIsMobileOpen(!props.isMobileOpen);
                    }}
                  >
                    <ListItemText primary={t("REFUNDS")} />
                  </ListItemButton>
                </ListItem>
              )}
              {(isSuperAdmin || isManager || isAdmin) && (
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate(ROUTES.EVENT_STATISTICS);
                      props.setIsMobileOpen(!props.isMobileOpen);
                    }}
                  >
                    <ListItemText primary={t("EVENT_INCOME")} />
                  </ListItemButton>
                </ListItem>
              )}
            </AccordionDetails>
          </Accordion>
        )}
        {/* MARKETING */}
        {(isSuperAdmin || isManager || isAdmin || isAffiliate) && (
          <Accordion
            sx={{
              backgroundColor: "var(--secondary-color)",
              color: "#fff",
              border: 0,
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
              sx={{
                gap: "10px",
                display: "flex",
              }}
            >
              {t("MARKETING")}
            </AccordionSummary>
            <AccordionDetails>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate(ROUTES.SALESSEO);
                    props.setIsMobileOpen(!props.isMobileOpen);
                  }}
                >
                  <ListItemText primary={t("SALESSEO")} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate(ROUTES.BLOG);
                    props.setIsMobileOpen(!props.isMobileOpen);
                  }}
                >
                  <ListItemText primary={t("BLOG")} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate(ROUTES.TAGS);
                    props.setIsMobileOpen(!props.isMobileOpen);
                  }}
                >
                  <ListItemText primary={t("TAGS")} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate(ROUTES.RRPPS);
                    props.setIsMobileOpen(!props.isMobileOpen);
                  }}
                >
                  <ListItemText primary={t("RRPP_MANAGER")} />
                </ListItemButton>
              </ListItem>
            </AccordionDetails>
          </Accordion>
        )}

        {/* SETTINGS */}
        {(isSuperAdmin || isManager || isAdmin || isAffiliate) && (
          <Accordion
            sx={{
              backgroundColor: "var(--secondary-color)",
              color: "#fff",
              border: 0,
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
              sx={{
                gap: "10px",
                display: "flex",
              }}
            >
              {t("SETTINGS")}
            </AccordionSummary>
            <AccordionDetails>
              {/* ORGANIZATION MANAGER */}
              {(isSuperAdmin || isAdmin || isManager) && (
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate(ROUTES.ORGANIZATIONS);
                      props.setIsMobileOpen(!props.isMobileOpen);
                    }}
                  >
                    <ListItemText primary={t("ENTITIES")} />
                  </ListItemButton>
                </ListItem>
              )}
              {/* PREMISES MANAGER */}
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate(ROUTES.PREMISES);
                    props.setIsMobileOpen(!props.isMobileOpen);
                  }}
                >
                  <ListItemText primary={t("VENUES")} />
                </ListItemButton>
              </ListItem>
              {/* ADMINS MANAGER */}
              {(isSuperAdmin || isManager || isAdmin) && (
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate(ROUTES.ADMINS);
                      props.setIsMobileOpen(!props.isMobileOpen);
                    }}
                  >
                    <ListItemText primary={t("ADMINISTRATOR_MANAGER")} />
                  </ListItemButton>
                </ListItem>
              )}
              {/* DOMAINS MANAGER */}
              {(isSuperAdmin || isAdmin || isManager) && (
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate(ROUTES.DOMAINS);
                      props.setIsMobileOpen(!props.isMobileOpen);
                    }}
                  >
                    <ListItemText primary={t("STYLES_MANAGER_APP")} />
                  </ListItemButton>
                </ListItem>
              )}
              {/* TPV */}
              {(isSuperAdmin || isAdmin || isManager) && (
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate(ROUTES.TPV);
                      props.setIsMobileOpen(!props.isMobileOpen);
                    }}
                  >
                    <ListItemText primary={t("TPV_MANAGER")} />
                  </ListItemButton>
                </ListItem>
              )}
            </AccordionDetails>
          </Accordion>
        )}
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              navigate(ROUTES.WIKIBLISS);
              props.setIsMobileOpen(!props.isMobileOpen);
            }}
          >
            <ListItemText primary={t("WIKIBLISS")} />
          </ListItemButton>
        </ListItem>
      </List>
    </>
  );
};

export default MobileNavBar;
