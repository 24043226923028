import React from "react";
import { FormControl, InputLabel, Select, MenuItem, Typography } from "@mui/material";
import { focusColor } from "./textFieldStyle";

export const FormSelect = ({
  label,
  value,
  onChange,
  error,
  helperText,
  options = [],
  ...props
}) => (
  <FormControl fullWidth error={!!error} sx={focusColor}>
    <InputLabel>{label}</InputLabel>
    <Select value={value || ""} onChange={onChange} label={label} {...props}>
      {options?.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      ))}
    </Select>
    {error && <Typography color="error">{helperText}</Typography>}
  </FormControl>
);
