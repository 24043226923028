import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { getExtraById } from "../../../services/extrasServices";

const useQueryExtraDetail = (id, onSuccess) => {
  return useQuery([RQ_KEY.EXTRA_DETAIL, id], () => getExtraById(id), {
    refetchOnWindowFocus: false,
    enabled: !!id,
    onSuccess: onSuccess,
  });
};

export default useQueryExtraDetail;
