import { Button, Grid, InputLabel } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { itinerary, itineraryErrors } from "../../../../classes/tripClass";
import { DEFAULT_EVENT_LANGUAGE } from "../../../../constants/variables";
import { deleteEventItinerary } from "../../../../services/eventsServices";
import InfoToolTip from "../../../shared/InfoToolTip";
import { Loading } from "../../../shared/Loading";
import LanguageTabs from "../../languages/LanguageTabs";
import Itinerary from "./Itinerary";

const Itineraries = ({ formController, buttonClicked, event_language_infos }) => {
  const { t } = useTranslation();
  const { formData, setFormErrors, setFormParamsValue, validation } = formController;
  const { moreInformation: trip = {} } = formData;
  const { moreInformation: moreInformationErrors = {} } = validation;
  const [selectedLanguage, setSelectedLanguage] = useState(DEFAULT_EVENT_LANGUAGE);
  const [forceRender, setForceRender] = useState(false);

  const forceUpdate = () => {
    setForceRender((prev) => !prev);
    setTimeout(() => {
      setForceRender((prev) => !prev);
    }, 100);
  };

  //Add a block to the state
  const handleAddItinerary = () => {
    const newItinerary = JSON.parse(JSON.stringify(itinerary));
    if (event_language_infos?.length > 0) {
      newItinerary.language = selectedLanguage;
    }
    newItinerary.position = trip.itineraries.length;
    const newItineraryErrors = JSON.parse(JSON.stringify(itineraryErrors));
    const itinerariesNewState = [...(trip.itineraries || []), newItinerary];
    setFormParamsValue("moreInformation", "itineraries", itinerariesNewState);

    setFormErrors("moreInformation", "itineraries", [
      ...(moreInformationErrors.itineraries || []),
      newItineraryErrors,
    ]);
  };

  //Remove a block from the state
  const handleRemoveItinerary = async (index) => {
    const parseIndex = parseInt(index);
    if (formData.moreInformation.itineraries[index]?.id) {
      await deleteEventItinerary(formData.moreInformation.itineraries[index].id);
    }
    const updatedItineraries = trip.itineraries
      .filter((_, itineraryIndex) => itineraryIndex !== parseIndex)
      .map((iti, index) => {
        iti.position = index;
        return iti;
      });
    setFormParamsValue("moreInformation", "itineraries", updatedItineraries);

    const updatedItinerariesErrors = moreInformationErrors.itineraries.filter(
      (_, itineraryIndex) => itineraryIndex !== index
    );
    setFormErrors("moreInformation", "itineraries", updatedItinerariesErrors);
  };

  //For the drag and drop
  const handleSetItineraryPositions = ({ destination, source }) => {
    if (!destination) return;
    const newItineraries = [...(trip.itineraries || [])];
    const [removed] = newItineraries.splice(source.index, 1);
    newItineraries.splice(destination.index, 0, removed);

    const updatedItineraries = newItineraries.map((itinerary, index) => ({
      ...itinerary,
      position: index,
    }));

    setFormParamsValue("moreInformation", "itineraries", updatedItineraries);
    forceUpdate();
  };

  const handleItineraryChange = (index, key, e) => {
    const blocksUpdated = formData.moreInformation.itineraries;
    blocksUpdated[index][key] = e.target.value;
    setFormParamsValue("moreInformation", "itineraries", blocksUpdated);
  };

  const setItineraryErrors = (updatedBlocksErrors) => {
    setFormErrors("moreInformation", "itineraries", updatedBlocksErrors);
  };

  useEffect(() => {
    forceUpdate();
  }, [selectedLanguage]);

  return (
    <Grid item xs={11} sx={{ mt: 2, mb: 2 }}>
      <Grid container alignItems="center">
        <InputLabel sx={{ fontSize: "18px", color: "black" }}>{t("ITINERARIES")}</InputLabel>
        <InfoToolTip text={t("ITINERARIES_TOOLTIP")} />
      </Grid>
      {event_language_infos?.length > 0 && trip?.itineraries?.length > 0 ? (
        <LanguageTabs
          event_languages_info={event_language_infos}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
        />
      ) : null}
      {forceRender ? (
        <Loading height={"100px"} />
      ) : (
        <DragDropContext onDragEnd={handleSetItineraryPositions}>
          <Droppable droppableId="droppable-list">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{ display: "flex", flexDirection: "column", gap: "20px", width: "100%" }}
              >
                {(trip.itineraries || [])
                  .filter((itinerary) => {
                    if (event_language_infos.length > 0) {
                      return (
                        itinerary.language === selectedLanguage ||
                        (selectedLanguage === DEFAULT_EVENT_LANGUAGE && !itinerary.language)
                      );
                    }
                    return true;
                  })
                  .map((itinerary, index) => (
                    <Fragment key={index}>
                      <Itinerary
                        itineraries={trip.itineraries}
                        index={itinerary.position}
                        itinerary={itinerary}
                        handleRemoveItinerary={handleRemoveItinerary}
                        buttonClicked={buttonClicked}
                        handleItineraryChange={handleItineraryChange}
                        itinerariesErrors={moreInformationErrors.itineraries}
                        setItinerariesErrors={setItineraryErrors}
                        formData={formData}
                      />
                    </Fragment>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      <Button
        fullWidth
        variant="outlined"
        size="large"
        sx={{
          color: "var(--secondary-color)",
          borderColor: "var(--secondary-color)",
          "&:hover": {
            color: "var(--secondary-color)",
            borderColor: "var(--secondary-color)",
            backgroundColor: "white",
          },
          marginTop: "20px",
        }}
        onClick={handleAddItinerary}
      >
        {t("ADD_ITINERARY")}
      </Button>
    </Grid>
  );
};

export default Itineraries;
