import EditIcon from "@mui/icons-material/Edit";
import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { formatDate, getDomainName } from "../../../constants/utils";
import { useQueryTagsList } from "../../../hooks/queries/tags/useQueryTagsList";
import { useQueryDomains } from "../../../hooks/queries/useQueryDomains";

const TagsTable = ({ data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: domains } = useQueryDomains();

  const { data: tags } = useQueryTagsList();

  const handleEdit = (id) => {
    navigate(`/tags/edit/${id}`);
  };

  const getTagName = (id) => {
    const tag = tags?.find((tag) => tag.id === id);
    return tag?.name || "";
  };

  const hasChildren = (tag) => {
    return tag?.child_tags_ids?.length > 0;
  };

  return (
    <TableContainer component={Paper} sx={{ borderRadius: "20px", mb: 2 }}>
      <Table sx={{ whiteSpace: "nowrap" }}>
        <TableHead>
          <TableRow sx={{ borderBottom: "2px solid var(--secondary-color)" }}>
            <TableCell>{t("TITLE")}</TableCell>
            <TableCell>{t("SLUG")}</TableCell>
            <TableCell>{t("DOMAINS")}</TableCell>
            <TableCell>{t("CREATION_DATE")}</TableCell>
            <TableCell>{t("TYPE")}</TableCell>
            <TableCell>{t("ACTIONS")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row) => (
            <Fragment key={row.id}>
              <TableRow>
                <TableCell sx={{ borderBottom: hasChildren(row) ? "none" : "" }}>
                  <Typography>{row.name}</Typography>
                </TableCell>
                <TableCell sx={{ borderBottom: hasChildren(row) ? "none" : "" }}>
                  {row.slug ? `/${row.slug}` : ""}
                </TableCell>
                <TableCell sx={{ borderBottom: hasChildren(row) ? "none" : "" }}>
                  {row.domains?.map((domain) => (
                    <div key={domain.domain_id}>{getDomainName(domain.domain_id, domains)}</div>
                  )) || ""}
                </TableCell>
                <TableCell sx={{ borderBottom: hasChildren(row) ? "none" : "" }}>
                  {formatDate(row.created_at)}
                </TableCell>
                <TableCell sx={{ borderBottom: hasChildren(row) ? "none" : "" }}>
                  {t(row.type?.toUpperCase())}
                </TableCell>
                <TableCell sx={{ borderBottom: hasChildren(row) ? "none" : "" }}>
                  <Tooltip title={t("EDIT_TAG")}>
                    <IconButton onClick={() => handleEdit(row.id)}>
                      <EditIcon sx={{ color: "var(--secondary-color)" }} />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
              {/* CHILDREN TAGS */}
              {row.child_tags_ids && row?.child_tags_ids?.length > 0 && (
                <TableRow key={row.id} sx={{ borderTop: 0 }}>
                  <TableCell colSpan={6} padding="none">
                    <Grid container direction="column" ml={3}>
                      {row.child_tags_ids?.map((childTagId) => (
                        <Grid
                          item
                          key={childTagId}
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          width="95%"
                        >
                          <Typography variant="subtitle2" sx={{ fontWeight: "bolder" }}>
                            {getTagName(childTagId)}
                          </Typography>
                          <Tooltip title={t("EDIT_TAG")}>
                            <IconButton onClick={() => handleEdit(childTagId)}>
                              <EditIcon sx={{ color: "var(--secondary-color)" }} />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      ))}
                    </Grid>
                  </TableCell>
                </TableRow>
              )}
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TagsTable;
