import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { blockErrorsAllOk } from "../../../../classes/tripClass";
import { deleteBlockEvent } from "../../../../services/eventsServices";
import CustomDialog from "../../../shared/CustomDialog";
import ImageUpload from "../../../shared/ImageUpload";
import { focusColor } from "../../../shared/textFieldStyle";
import TextEditor from "../../../textEditor/TextEditor";
import { checkRenderEditorJs } from "../../../textEditor/tools";
import { toastMessageError } from "../../../shared/toastMessage";

const Block = ({
  index,
  handleBlockChange,
  handleBlockImageChange,
  formController,
  blocksErrors,
  setBlocksErrors,
  buttonClicked,
  editEvent,
  removeBlock,
}) => {
  const { t } = useTranslation();
  const formData = formController.formData.moreInformation;
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(formData.blocks[index]?.description);

  const { pathname } = useLocation();

  const handleChangeDescription = (data) => {
    const e = { target: { value: JSON.stringify(data) } };

    setData(data);
    handleChangeFormParamsAndErrors("description", e, index, /^[\s\S]{0,10000000}$/, t("MAX_5000"));
  };

  // For edition - In case of not exisiting the block errors we create it
  if (!blocksErrors[index]) blocksErrors[index] = blockErrorsAllOk;

  //Validation NOK
  const handleChangeFormParamsAndErrors = (key, event, index, regex, errorMessage) => {
    const inputValue = event.target.value;

    if (regex.test(inputValue)) {
      blocksErrors[index][key] = "";
    } else {
      blocksErrors[index][key] = errorMessage;
    }
    setBlocksErrors(blocksErrors);
    handleBlockChange(index, key, event);
    formData.blocks[index][key] = inputValue;
  };

  // Remove block from the database and the state
  const handleRemoveBlock = async () => {
    try {
      if (formData.blocks[index].id) {
        await deleteBlockEvent(formData.blocks[index].id);
      }
      removeBlock(index);
    } catch (error) {
      toastMessageError(t("ERROR_REMOVING_BLOCK"));
    } finally {
      setOpen(false);
    }
  };

  const handleClickOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  return (
    <Draggable draggableId={`draggable-${index}`} index={parseInt(index, 10)}>
      {(provided, snapshot) => (
        <Accordion
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{
            backgroundColor: snapshot.isDragging ? "var(--grey-cancelled)" : "",
            display: "flex",
            flexDirection: "column",
            border: "1px solid var(--grey-cancelled)",
            boxShadow: "none",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container flexDirection="row" justifyContent="space-between">
              <Grid item xs={6} container alignItems="center">
                <DragIndicatorIcon sx={{ marginRight: 2 }} />
                <Typography>
                  {formData.blocks[index]?.title ? formData.blocks[index]?.title : t("TITLE")}
                </Typography>
              </Grid>
              <Grid item marginRight={2}>
                <IconButton onClick={handleClickOpen} className="oniria-icon-btn-delete">
                  <DeleteOutlineIcon />
                </IconButton>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  onChange={(e) =>
                    handleChangeFormParamsAndErrors(
                      "title",
                      e,
                      index,
                      /^(?!.*[|\\#·$~%¬^[\]*{}_]).{0,50}$/,
                      t("MAX_50")
                    )
                  }
                  margin="normal"
                  fullWidth
                  value={formData.blocks[index]?.title || ""}
                  id="title"
                  name="title"
                  autoComplete="title"
                  label={t("TITLE")}
                  error={
                    (blocksErrors[index].title !== "" && blocksErrors[index].title !== "empty") ||
                    (blocksErrors[index].title === "empty" && buttonClicked)
                  }
                  helperText={
                    blocksErrors[index].title !== "empty" ? blocksErrors[index].title : ""
                  }
                  sx={focusColor}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  onChange={(e) =>
                    handleChangeFormParamsAndErrors(
                      "subtitle",
                      e,
                      index,
                      /^(?!.*[|\\#·$~%¬^[\]*{}_]).{0,50}$/,
                      t("MAX_50")
                    )
                  }
                  margin="normal"
                  fullWidth
                  value={formData.blocks[index]?.subtitle || ""}
                  id="subtitle"
                  name="subtitle"
                  autoComplete="subtitle"
                  label={t("SUBTITLE")}
                  error={blocksErrors[index].subtitle !== ""}
                  helperText={blocksErrors[index].subtitle}
                  sx={focusColor}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel sx={{ fontSize: "18px", color: "black", mt: 1 }}>
                  {t("TRIP_IMAGES")}
                </InputLabel>
                <ImageUpload
                  url={formData.blocks[index]?.images_urls}
                  selectedImagesInForm={formData.blocks[index]?.images_urls}
                  onImageSelect={(url) => handleBlockImageChange(index, "images_urls", url)}
                  multi={true}
                  editEvent={editEvent}
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                  {t("DESCRIPTION")}
                </InputLabel>
                {checkRenderEditorJs(pathname, data) ? (
                  <TextEditor
                    data={data}
                    setData={handleChangeDescription}
                    id={`block-${index}-description_`}
                  />
                ) : (
                  <TextField
                    onChange={(e) =>
                      handleChangeFormParamsAndErrors(
                        "description",
                        e,
                        index,
                        /^(?!.*[|\\#·$~%¬^[\]*{}_])[\s\S]{0,1000}$/s,
                        t("MAX_1000")
                      )
                    }
                    value={formData.blocks[index]?.description || ""}
                    margin="normal"
                    fullWidth
                    id="editorjs"
                    name="description"
                    label={t("DESCRIPTION")}
                    autoComplete="description"
                    multiline
                    rows={4}
                    error={blocksErrors[index].description !== ""}
                    helperText={blocksErrors[index].description}
                    sx={focusColor}
                  />
                )}
                {blocksErrors[index].description !== "" && (
                  <Typography variant="caption" sx={{ color: "red" }}>
                    {blocksErrors[index].description}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </AccordionDetails>
          <CustomDialog
            isOpen={open}
            onClose={handleClose}
            title={t("CANCEL_BLOCK")}
            content={t("CONFIRM_CANCEL_BLOCK")}
            onAccept={handleRemoveBlock}
          />
        </Accordion>
      )}
    </Draggable>
  );
};

export default Block;
