import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { getTagDetail } from "../../../services/tagsServices";

export function useQueryTagDetail(id, onSuccess) {
  return useQuery([RQ_KEY.TAG_DETAIL, id], () => getTagDetail(id), {
    refetchOnWindowFocus: false,
    enabled: !!id,
    onSuccess: onSuccess,
  });
}
