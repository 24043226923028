import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, TableSortLabel } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EURO } from "../../constants/utils";

const TpvsTable = ({ data }) => {
	const { t } = useTranslation();

	// Estado para las columnas de ordenación acumulativa
	const [orderPriority, setOrderPriority] = useState([]);

	// Función para manejar la ordenación acumulativa
	const handleRequestSort = (property) => {
		setOrderPriority((prevOrder) => {
			const existingIndex = prevOrder.findIndex((item) => item.property === property);
			// Si la propiedad ya está en el array, cambia su orden o elimínala si ya está descendente
			if (existingIndex > -1) {
				const newOrder = [...prevOrder];
				const currentOrder = newOrder[existingIndex].order;
				if (currentOrder === 'asc') {
					newOrder[existingIndex].order = 'desc';
				} else {
					newOrder.splice(existingIndex, 1); // Elimina si está descendente
				}
				return newOrder;
			}
			// Si la propiedad no está en el array, agrégala con orden ascendente
			return [...prevOrder, { property, order: 'asc' }];
		});
	};

	// Función para ordenar los datos con prioridad acumulativa
	const sortedData = data.slice().sort((a, b) => {
		for (const { property, order } of orderPriority) {
			const isAsc = order === 'asc';
			if (typeof a[property] === 'string') {
				const comparison = isAsc ? a[property].localeCompare(b[property]) : b[property].localeCompare(a[property]);
				if (comparison !== 0) return comparison;
			} else {
				const comparison = isAsc ? a[property] - b[property] : b[property] - a[property];
				if (comparison !== 0) return comparison;
			}
		}
		return 0;
	});

	return (
		<TableContainer component={Paper} sx={{ borderRadius: '20px', mb: 2 }}>
			<Table sx={{ whiteSpace: 'nowrap' }}>
				<TableHead>
					<TableRow sx={{ borderBottom: '2px solid var(--secondary-color)' }}>
						{['min_price', 'max_price', 'fixed_price', 'percentage', 'payment_method', 'commission_type'].map((col) => (
							<TableCell key={col}>
								<TableSortLabel
									active={orderPriority.some((item) => item.property === col)}
									direction={
										orderPriority.find((item) => item.property === col)?.order || 'asc'
									}
									onClick={() => handleRequestSort(col)}
									hideSortIcon={false} // Hace que el icono de ordenación esté siempre visible
									iconComponent={() => (
										<span
											style={{
												fontSize: '1rem',
												opacity: 1,
											}}
										>
											{orderPriority.find((item) => item.property === col)?.order === 'asc'
												? '▲'
												: '▼'}
										</span>
									)}
								>
									{t(col.toUpperCase())}
								</TableSortLabel>
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{sortedData.map((row) => (
						<Fragment key={row.id}>
							<TableRow>
								<TableCell>
									<Typography>{EURO.format(row.min_price)}</Typography>
								</TableCell>
								<TableCell>
									<Typography>{EURO.format(row.max_price)}</Typography>
								</TableCell>
								<TableCell>
									<Typography>{EURO.format(row.fixed_price)}</Typography>
								</TableCell>
								<TableCell>
									<Typography>{row.percentage}%</Typography>
								</TableCell>
								<TableCell>
									<Typography>{row.payment_method}</Typography>
								</TableCell>
								<TableCell>
									<Typography>{row.commission_type}</Typography>
								</TableCell>
							</TableRow>
						</Fragment>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default TpvsTable;
