import { SERVICE } from "../constants/api";
import http from "../constants/axios";

export const getTagsList = async (filters) => {
  try {
    const params = new URLSearchParams();
    if (filters?.isParent) params.append("is_parent", filters.isParent);
    if (filters?.domainId) params.append("domain_id", filters.domainId);

    const response = await http.get(`${SERVICE.TAGS}/list?${params.toString()}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching tags:", error);
    throw error;
  }
};

export const getTagDetail = async (id) => {
  try {
    const response = await http.get(`${SERVICE.TAGS}/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching tags:", error);
    throw error;
  }
};

export const getTags = async (page, perPage, filters) => {
  try {
    const params = new URLSearchParams();
    if (page) params.append("page", page);
    if (perPage) params.append("perPage", perPage);
    if (filters?.search) params.append("search", filters.search);
    if (filters?.domain) params.append("domain", filters.domain);

    const response = await http.get(`${SERVICE.TAGS}?${params.toString()}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching tags:", error);
    throw error;
  }
};

export const createTag = async (formData) => {
  try {
    const response = await http.post(`${SERVICE.TAGS}`, formData);
    return response.data;
  } catch (error) {
    console.error("Error fetching tags:", error);
    throw error;
  }
};

export const updateTag = async (id, formData) => {
  try {
    const response = await http.put(`${SERVICE.TAGS}/${id}`, formData);
    return response?.data || "success";
  } catch (error) {
    console.error("Error fetching tags:", error);
    throw error;
  }
};

export const checkTagSlug = async (slug) => {
  try {
    const response = await http.get(`${SERVICE.TAGS}/slug-available/${slug}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching tags:", error);
    throw error;
  }
};
