import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Grid,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { LANGUAGES } from "../../constants/variables";

const ExtraLanguageSelect = ({ formData, setFormData }) => {
  const { t } = useTranslation();
  const steps = LANGUAGES;

  const checkLanguage = (idiom) => {
    return (
      formData?.extra_template_language_infos?.some((info) => info.language === idiom) || false
    );
  };

  const handleChangeCheckBox = (e, value) => {
    const isChecked = e.target.checked;
    const newLanguage = {
      language: value,
      name: "",
      description: "",
      short_description: "",
      description_design: "",
    };
    if (isChecked) {
      setFormData((prev) => ({
        ...prev,
        extra_template_language_infos: [...prev?.extra_template_language_infos, newLanguage],
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        extra_template_language_infos: prev?.extra_template_language_infos?.filter(
          (language) => language?.language !== value
        ),
      }));
    }
  };

  return (
    <Grid item xs={12}>
      <Accordion
        sx={{
          display: "flex",
          flexDirection: "column",
          border: "1px solid var(--grey-cancelled)",
          boxShadow: "none",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container alignItems="center">
            <Typography>{t("IDIOMS")}</Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {steps.map((step, index) => (
              <Grid item xs={12} key={index} container alignItems="center">
                <Checkbox
                  className="checkbox-oniria"
                  checked={checkLanguage(step.value)}
                  onChange={(e) => handleChangeCheckBox(e, step.value)}
                />
                <Typography>{t(step.label)}</Typography>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default ExtraLanguageSelect;
