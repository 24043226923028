import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { getTags } from "../../../services/tagsServices";

export function useQueryTags(page, perPage, filters) {
  return useQuery(
    [RQ_KEY.BLOGS_TAGS, page, perPage, filters],
    () => getTags(page, perPage, filters),
    {
      refetchOnWindowFocus: false,
    }
  );
}
