import { Grid, Typography } from "@mui/material";
import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TpvTable from "../../components/tpv/TpvTable";
import Header from "../../components/Header";
import { Loading } from "../../components/shared/Loading";
import { ROUTES } from "../../constants/routes";
import { useQueryTpvList } from "../../hooks/queries/useQueryTpvList";
import useDebounce from "../../hooks/useDebouncing";
import TpvFilters from "../../components/tpv/TpvFilters";

const Tpv = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const breadcrumbs = [{ name: t("TPV comisiones"), link: ROUTES.TPV }];
  const [filters, setFilters] = useState({
    search: "",
    domain: "",
    premise_id: "",
  });

  // Ejecuta la consulta solo una vez con el valor inicial de `premise_id`
  const { data = { data: [], total: 0 }, isLoading } = useQueryTpvList(
    filters.premise_id
  );

  const updateFilter = (name, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const debounceSearch = useDebounce(
    (event) => updateFilter("search", event.target.value),
    300
  );


  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t("COMISIONS_DESCRIPTION")} />

      <Grid
        item
        xs={11}
        sm={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "initial",
        }}
      >
        <TpvFilters
          onSearchChange={debounceSearch}
          filters={filters}
          updateFilter={updateFilter}
        />
      </Grid>

      {isLoading ? (
        <Loading />
      ) : data.length > 0 ? (
        <div style={{ marginBottom: "20px" }}>
          <Grid
            item
            xs={11}
            sm={12}
            container
            justifyContent={{ xs: "center", sm: "space-between" }}
            alignItems="center"
            pb={2}
            gap={{ xs: 2, sm: 0 }}
          >
            <Typography>
              {t("TOTAL_COMISIONS")}: {data.length}
            </Typography>
          </Grid>

          <TpvTable data={data} />
        </div>
      ) : (
        <Typography>{t("NO_TPV_FOUND")}</Typography>
      )}
    </>
  );
};

export default Tpv;
