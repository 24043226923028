import { SERVICE } from "../constants/api";
import http from "../constants/axios";

//me trae las comisiones de cada sede
export const getTpvList = async (id) => {
  try {
    const response = await http.get(`${SERVICE.TPV}/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching tags:", error);
    throw error;
  }
};
